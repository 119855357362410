/*!
 * Typo3 12 0.1.0
 * https://www.ifabrik.de/
 *
 * Copyright 2023 i-fabrik GmbH
 *
 * Released on January 21, 2025
 * 
 * Author: i-fabrik GmbH
 * Contributor: Heiko Pfefferkorn <heiko.pfefferkorn@ifabrik.de>
 */
@charset "UTF-8";
/* @docs
label: Core Remedies
version: 0.1.0-beta.2

note: |
  These remedies are recommended
  as a starter for any project.

category: file
*/
/* @docs
label: Box Sizing

note: |
  Use border-box by default, globally.

category: global
*/
*, ::before, ::after {
  box-sizing: border-box;
}

/* @docs
label: Line Sizing

note: |
  Consistent line-spacing,
  even when inline elements have different line-heights.

links:
  - https://drafts.csswg.org/css-inline-3/#line-sizing-property

category: global
*/
html {
  line-sizing: normal;
}

/* @docs
label: Body Margins

note: |
  Remove the tiny space around the edge of the page.

category: global
*/
body {
  margin: 0;
}

/* @docs
label: Heading Sizes

note: |
  Switch to rem units for headings

category: typography
*/
h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.17rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.83rem;
}

h6 {
  font-size: 0.67rem;
}

/* @docs
label: H1 Margins

note: |
  Keep h1 margins consistent, even when nested.

category: typography
*/
h1 {
  margin: 0.67em 0;
}

/* @docs
label: Pre Wrapping

note: |
  Overflow by default is bad...

category: typography
*/
pre {
  white-space: pre-wrap;
}

/* @docs
label: Horizontal Rule

note: |
  1. Solid, thin horizontal rules
  2. Remove Firefox `color: gray`
  3. Remove default `1px` height, and common `overflow: hidden`

category: typography
*/
hr {
  border-style: solid;
  border-width: 1px 0 0;
  color: inherit;
  height: 0;
  overflow: visible;
}

/* @docs
label: Responsive Embeds

note: |
  1. Block display is usually what we want
  2. Remove strange space-below when inline
  3. Responsive by default

category: embedded elements
*/
img, svg, video, canvas, audio, iframe, embed, object {
  display: block;
  vertical-align: middle;
  max-width: 100%;
}

/* @docs
label: Aspect Ratios

note: |
  Maintain intrinsic aspect ratios when `max-width` is applied.
  `iframe`, `embed`, and `object` are also embedded,
  but have no intrinsic ratio,
  so their `height` needs to be set explicitly.

category: embedded elements
*/
img, svg, video, canvas {
  height: auto;
}

/* @docs
label: Audio Width

note: |
  There is no good reason elements default to 300px,
  and audio files are unlikely to come with a width attribute.

category: embedded elements
*/
audio {
  width: 100%;
}

/* @docs
label: Image Borders

note: |
  Remove the border on images inside links in IE 10 and earlier.

category: legacy browsers
*/
img {
  border-style: none;
}

/* @docs
label: SVG Overflow

note: |
  Hide the overflow in IE 10 and earlier.

category: legacy browsers
*/
svg {
  overflow: hidden;
}

/* @docs
label: HTML5 Elements

note: |
  Default block display on HTML5 elements

category: legacy browsers
*/
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

/* @docs
label: Checkbox & Radio Inputs

note: |
  1. Add the correct box sizing in IE 10
  2. Remove the padding in IE 10

category: legacy browsers
*/
[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

/* stylelint-disable-line scss/dollar-variable-pattern */
/**
 * Inhalte visuell ausblenden aber für unterstützende Technologien zugänglich
 * halten.
 */
/**
 * Zeige Inhalt nur wenn er fokussiert wird/wurde.
 */
[data-ratio]:not([data-ratio=""]) {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}
[data-ratio]:not([data-ratio=""])::before {
  content: ""; /* stylelint-disable-line string-quotes */
  display: block;
  padding-top: var(--ratio, 100%);
}
[data-ratio]:not([data-ratio=""]) > * {
  border: 0;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
[data-ratio]:not([data-ratio=""]) > img {
  height: 100%;
  max-width: none;
  object-fit: cover;
  width: 100%;
}
[data-ratio]:not([data-ratio=""]) > video,
[data-ratio]:not([data-ratio=""]) > iframe,
[data-ratio]:not([data-ratio=""]) > div {
  height: 100%;
}

[data-ratio="16x9"] {
  --ratio: var(--wp-ratio-16x9, calc(9 / 16 * 100%));
}

[data-ratio="4x3"] {
  --ratio: var(--wp-ratio-4x3, calc(3 / 4 * 100%));
}

[data-ratio="3x4"] {
  --ratio: var(--wp-ratio-3x4, calc(4 / 3 * 100%));
}

[data-ratio="3x2"] {
  --ratio: var(--wp-ratio-3x2, calc(2 / 3 * 100%));
}

[data-ratio="2x3"] {
  --ratio: var(--wp-ratio-2x3, calc(3 / 2 * 100%));
}

[data-ratio="1x1"] {
  --ratio: var(--wp-ratio-1x1, calc(1 / 1 * 100%));
}

._hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

._scroll-lock {
  overflow: hidden !important; /* stylelint-disable-line declaration-no-important */
}

._selectable-all {
  user-select: all;
}

._selectable-none {
  user-select: none;
}

._stretched-link::after {
  content: ""; /* stylelint-disable-line string-quotes */
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

._visually-hidden,
._visually-hidden-focusable:not(:focus):not(:focus-within) { /* stylelint-disable-line selector-not-notation */
  /* stylelint-disable declaration-no-important */
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
  /* stylelint-enable declaration-no-important */
}

.row {
  --bs-gutter-x: var(--wp-grid-gap-x);
  --bs-gutter-y: var(--wp-grid-gap-y);
}
.row:not(:last-child) {
  margin-bottom: var(--bs-gutter-y);
}

.modal-backdrop {
  --bs-backdrop-bg: var(--wp-backdrop-color);
  --bs-backdrop-opacity: var(--wp-backdrop-opacity);
  backdrop-filter: var(--wp-backdrop-filter);
}

.sc-cookie {
  --sccookie-border-radius: var(--wp-border-radius);
  --sccookie-color-border: var(--ifabsh-color-border);
  --sccookie-color-ui-h: var(--wp-color-primary-h);
  --sccookie-color-ui-s: var(--wp-color-primary-s);
  --sccookie-color-ui-l: var(--wp-color-primary-l);
  --sccookie-color-error-h: var(--wp-color-danger-h);
  --sccookie-color-error-s: var(--wp-color-danger-s);
  --sccookie-color-error-l: var(--wp-color-danger-l);
  --sccookie-color-success-h: var(--wp-color-success-h);
  --sccookie-color-success-s: var(--wp-color-success-s);
  --sccookie-color-success-l: var(--wp-color-success-l);
}

button.sc-cookie-trigger {
  --sccookie-position-offset: var(--wp-spacing-s);
  --sccookie-trigger-color: var(--wp-color-primary);
}
.page-footer-in-view button.sc-cookie-trigger {
  --sccookie-trigger-color: var(--wp-color-color);
  --sccookie-trigger-color-event: var(--wp-color-white);
}

.fancybox-custom {
  --wp-fancybox-backdrop-color: var(--wp-color-primary-lighten);
  --wp-fancybox-backdrop-opacity: var(--wp-backdrop-opacity, 0.5);
  --wp-fancybox-toolbar-button-size: 1.5em;
  --wp-fancybox-toolbar-padding-x: var(--wp-spacing-s);
  --wp-fancybox-toolbar-padding-y: var(--wp-spacing-s);
  --wp-fancybox-caption-background: var(--wp-color-primary);
  --wp-fancybox-caption-color: var(--wp-color-secondary);
  --wp-fancybox-caption-max-width: var(--wp-page-max-width);
  --wp-fancybox-caption-padding: var(--wp-spacing);
  backdrop-filter: var(--wp-backdrop-filter);
  color: var(--wp-color-secondary);
}

.fancybox-custom.fancybox__container {
  --fancybox-color: var(--wp-color-secondary);
  --carousel-button-height: var(--wp-fancybox-toolbar-button-size);
  --carousel-button-width: var(--wp-fancybox-toolbar-button-size);
  --carousel-button-svg-width: 80%;
  --carousel-button-svg-height: 80%;
  --carousel-button-svg-stroke-width: 2;
  --carousel-button-svg-filter: none;
}
body:not(.is-using-mouse) .fancybox-custom.fancybox__container :focus {
  box-shadow: none;
}

.fancybox-custom .fancybox__backdrop {
  animation-name: none !important; /* stylelint-disable-line declaration-no-important */
  background-color: var(--wp-fancybox-backdrop-color);
  opacity: 0.9;
  transition: opacity 0.35s ease-in-out;
}
.fancybox-custom.is-animated .fancybox__backdrop {
  opacity: var(--wp-fancybox-backdrop-opacity);
}

.fancybox-custom .fancybox__caption {
  background: var(--wp-fancybox-caption-background);
  max-width: var(--wp-fancybox-caption-max-width);
  padding: var(--wp-fancybox-caption-padding);
  transition: opacity 0.35s ease-in-out;
}
.fancybox-custom .fancybox__caption > * {
  display: inline;
  margin: 0;
}
.fancybox-custom .fancybox__caption > * + *::before {
  content: " "; /* stylelint-disable-line string-quotes */
}

.fancybox-custom .fancybox__nav {
  --f-button-bg: var(--wp-color-primary);
  --f-button-hover-bg: var(--wp-color-secondary);
  --f-button-color: var(--wp-color-secondary);
  --f-button-hover-color: var(--wp-color-white);
}
.fancybox-custom .fancybox__nav:focus {
  outline: var(--wp-focus-outline-width) var(--wp-focus-outline-style) var(--wp-focus-outline-color);
  outline-offset: var(--wp-focus-outline-offset);
}

.fancybox-custom .fancybox__toolbar {
  --f-button-bg: var(--wp-color-primary);
  --f-button-hover-bg: var(--wp-color-secondary);
  --f-button-color: var(--wp-color-secondary);
  --f-button-hover-color: var(--wp-color-white);
  --carousel-button-svg-width: 80%;
  --carousel-button-svg-height: 80%;
  background: none;
  padding: var(--wp-fancybox-toolbar-padding-y) var(--wp-fancybox-toolbar-padding-x);
  pointer-events: auto;
  text-shadow: none;
}
.fancybox-custom .fancybox__toolbar .fancybox__button--zoom, .fancybox-custom .fancybox__toolbar .fancybox__button--fullscreen, .fancybox-custom .fancybox__toolbar .fancybox__button--slideshow {
  display: none;
}
.fancybox-custom .fancybox__toolbar [data-fancybox-close] {
  align-items: center;
  display: flex;
}
.fancybox-custom .fancybox__toolbar [data-panzoom-action] {
  display: none;
}
.fancybox-custom .fancybox__toolbar__column {
  gap: var(--wp-spacing-s);
}

.fancybox-custom .fancybox__infobar {
  font-weight: var(--wp-font-weight-semibold);
}

.fancybox-custom .fancybox__slide {
  padding: calc(var(--wp-fancybox-toolbar-padding-x) * 2 + var(--wp-fancybox-toolbar-button-size)) var(--wp-spacing) var(--wp-spacing);
}

.fb-custom-modal-iframe-wrapper .section > .section-body {
  --wp-page-max-width-absolute: 98% !important;
}

.flatpickr-months {
  background: var(--wp-color-primary-contrast);
  color: var(--wp-color-primary);
  padding-bottom: var(--wp-spacing-s);
  position: relative;
}
.flatpickr-months .flatpickr-month,
.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
  color: var(--wp-color-primary);
  fill: var(--wp-color-primary);
}
.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
  top: 50%;
  transform: translateY(-50%);
}
.flatpickr-months .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: var(--wp-color-primary-contrast);
}
.flatpickr-months .numInput::-webkit-inner-spin-button, .flatpickr-months .numInput::-webkit-outer-spin-button {
  color: var(--wp-color-primary);
}
.flatpickr-day.today {
  border-color: var(--wp-color-primary-contrast);
}
.flatpickr-day.today:focus, .flatpickr-day.today:hover {
  background: var(--wp-color-secondary);
  border-color: var(--wp-color-secondary);
  color: inherit;
}

span.flatpickr-day.nextMonthDay.today:not(.selected):hover, span.flatpickr-day.prevMonthDay.today:not(.selected):hover, span.flatpickr-day.today:not(.selected):hover {
  border: 1px solid var(--wp-color-primary-contrast);
}

span.flatpickr-day.nextMonthDay.today:not(.selected), span.flatpickr-day.prevMonthDay.today:not(.selected), span.flatpickr-day.today:not(.selected) {
  border-color: transparent transparent var(--wp-color-primary-contrast) #e9e9e9;
}

.flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.selected, .flatpickr-day.selected.inRange, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover {
  background: var(--wp-color-primary-contrast);
  border-color: var(--wp-color-primary-contrast);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: inherit;
}

.form-control.flatpickr-input + .form-control {
  --_foco-background-color: var(--wp-color-white) !important;
  cursor: pointer;
}

div.flatpickr {
  position: relative;
}
div.flatpickr::after {
  bottom: var(--ifabsh-form-control-height);
  content: "\f133";
  display: block;
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
  position: absolute;
  right: var(--ifabsh-form-control-padding);
  transform: translateY(calc(var(--ifabsh-form-control-height) - 50%));
}

/**
 * Überschriftselektoren zusammenstellen<br/>
 *
 * @example scss
 *   $var : heading-selectors();
 *   // = h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6
 *
 *   $var : heading-selectors(1, 6, false);
 *   // = h1, h2, h3, h4, h5, h6
 *
 *   $var : heading-selectors(3, 4);
 *   // = h3, .h3, h4, .h4
 *
 *   // Abfangen von nicht korrekten Angaben (`< 1`, `> 6`).
 *   $var : heading-selectors(0, 0);
 *   $var : heading-selectors(-1, 0);
 *   $var : heading-selectors(7, -15);
 *   // = h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6
 *
 *   $var : heading-selectors(0, 2);
 *   // = h1, .h1, h2, .h2
 *
 *   // Vertauschte Werte.
 *   $var : heading-selectors(3, 1);
 *   // = h3, .h3, h4, .h4, h5, .h5, h6, .h6
 */
/**
 * Replace `$search` with `$replace` in `$string`
 */
/**
 * SVG-XML-String kodieren
 */
/**
 * Einheit eines Wertes entfernen.
 *
 * @example
 *   $var : strip-unit(16px);
 *   // = 16
 */
/**
 * Einheit eines Wertes entfernen.
 *
 * @example
 *   $var : get-unit(16px);
 *   // = px
 */
/**
 * Prüft ob `$val` null ist.
 *
 * @example scss
 *   $var : is-null(null);
 *   // = true
 *
 *   $var : is-null(0);
 *   // = false
 */
/**
 * Prüft ob `$val` leer ist.
 *
 * @example scss
 *   $var : is-empty();
 *   // = true
 *
 *   $var : is-empty('');
 *   // = true
 *
 *   $var : is-empty('foo');
 *   // = false
 */
/**
 * Prüft ob `$val` numerisch ist.
 *
 * @example scss
 *   $var : is-numeric(1);
 *   // = true
 *
 *   $var : is-numeric('2');
 *   // = false
 *
 *   $var : is-numeric(3rem);
 *   // = false
 */
/**
 * Prüft ob `$val` boolean ist.
 *
 * @example scss
 *   $var : is-bool(true);
 *   // = true
 *
 *   $var : is-bool(false);
 *   // = false
 *
 *   $var : is-bool(1);
 *   // = true
 *
 *   $var : is-bool(0);
 *   // = false
 *
 *   $var : is-bool(null);
 *   // = false
 */
/**
 * Prüft ob `$val` ein String ist.
 *
 * @example scss
 *   $var : is-string(foo);
 *   // = true
 *
 *   $var : is-string('bar');
 *   // = true
 *
 *   $var : is-string(0);
 *   // = false
 */
/**
 * Prüft ob `$val` eine Liste ist.
 *
 * @example scss
 *   $var : is-list(a b c d);
 *   // = true
 *
 *   $var : is-list(lorem, ipsum);
 *   // = true
 *
 *   $var : is-list(foo);
 *   // = false
 */
/**
 * Prüft ob `$val` eine Map ist.
 *
 * @example scss
 *   $var : is-map((a: b));
 *   // = true
 *
 *   $var : is-list(foo);
 *   // = false
 */
/**
 * Farbton (Hue) eines Hexwertes zurückgeben.
 *
 * @example
 *   get-hue(#6176ff);
 *   // = 232
 */
/**
 * Sättigung (Saturation) eines Hexwertes zurückgeben.
 *
 * @example
 *   get-saturation(#6176ff);
 *   // = 100%
 */
/**
 * Helligkeit (Lightness) eines Hexwertes zurückgeben.
 *
 * @example
 *   get-lightness(#6176ff);
 *   // = 69%
 */
/**
 * Überschreiben der SCSS-Konvertierungsfunktion `hsl()` zu einer realen
 * `hsl()`-Angabe.
 *
 * @example
 *   hsl(232, 100%, 69%);
 *   // = hsl(232, 100%, 69%)
 *
 *   hsl(232, 100, 69);
 *   // = hsl(232, 100%, 69%)
 *
 *   hsl(#6176ff);
 *   // = hsl(232, 100%, 69%)
 *
 *   hsl(var(--color-hue), 100%, 69%);
 *   // = hsl(var(--color-hue), 100%, 69%)
 *
 *   hsl(var(--color-hue), var(--color-saturation), var(--color-lightness));
 *   // = hsl(var(--color-hue), var(--color-saturation), var(--color-lightness))
 */
/**
 * Überschreiben der SCSS-Konvertierungsfunktion `hsla()` zu einer realen
 * `hsla()`-Angabe.
 *
 * @example
 *   hsla(232, 100%, 69%, 0.75);
 *   // = hsla(232, 100%, 69%, 0.75)
 *
 *   hsla(232, 100, 69, 0.75);
 *   // = hsla(232, 100%, 69%, 0.75)
 *
 *   hsla(#6176ff, 0.75);
 *   // = hsla(232, 100%, 69%, 0.75)
 *
 *   hsla(#6176ff);
 *   // = hsla(232, 100%, 69%, 1)
 *
 *   hsla(var(--color-hue), 100%, 69%);
 *   // = hsla(var(--color-hue), 100%, 69%, 1)
 *
 *   hsla(var(--color-hue), var(--color-saturation), var(--color-lightness));
 *   // = hsla(var(--color-hue), var(--color-saturation), var(--color-lightness), 1)
 *
 *   hsla(#6176ff, var(--opacity));
 *   // = hsla(232, 100%, 69%, var(--opacity))
 */
/**
 * Überschreiben der SCSS-Konvertierungsfunktion `rgb()` zu einer realen
 * `rgb()`-Angabe.
 *
 * @example
 *   rgb(97, 118, 255);
 *   // = rgb(97, 118, 255)
 *
 *   rgb(#6176ff);
 *   // = rgb(97, 118, 255)
 *
 *   // `--color : 97, 118, 255;`
 *   rgb(var(--color));
 *   // = rgb(var(--color))
 */
/**
 * Überschreiben der SCSS-Konvertierungsfunktion `rgba()` zu einer realen
 * `rgba()`-Angabe.
 *
 * @example
 *   rgba(97, 118, 255, 0.75);
 *   // = rgba(97, 118, 255, 0.75)
 *
 *   rgba(97, 118, 255);
 *   // = rgba(97, 118, 255, 1)
 *
 *   rgba(#6176ff, 0.75);
 *   // = rgba(97, 118, 255, 0.75)
 *
 *   rgba(#6176ff);
 *   // = rgba(97, 118, 255, 1)
 *
 *   // `--color : 97, 118, 255;`
 *   rgba(var(--color));
 *   // = rgba(var(--color), 1)
 *
 *   // `--color : 97, 118, 255;`
 *   // `--opcity : 0.75;`
 *   rgba(var(--color), var(--opacity));
 *   // = rgba(var(--color), var(--opacity))
 */
/**
 * Minimale Breakpointweite.<br/>
 * <small>_Null für den kleinsten (ersten) Breakpoint._</small>
 *
 * @example scss
 *   $breakpoints : (
 *     'xxs' : 0,
 *     'xs'  : 420px,
 *     's'   : 576px,
 *     'm'   : 768px,
 *     'l'   : 992px
 *   );
 *
 *   $var : breakpoint-min('xs');
 *   // = 420px
 */
/**
 * Maximale Breakpointweite.<br/>
 * <small>_Null für den größten (letzten) Breakpoint. Der Maximalwert wird als Minimum des nächsten Breakpointwertes minus 1px berechnet._</small>
 *
 * @example scss
 *   $breakpoints : (
 *     'xxs' : 0,
 *     'xs'  : 420px,
 *     's'   : 576px,
 *     'm'   : 768px,
 *     'l'   : 992px
 *   );
 *
 *   $var : breakpoint-max('xs');
 *   // = 575px
 */
/**
 * Name des nächsten Breakpoints.<br/>
 * <small>_Null für den letzten Breakpoint._</small>
 *
 * @example scss
 *   $breakpoints : (
 *     'xxs' : 0,
 *     'xs'  : 420px,
 *     's'   : 576px,
 *     'm'   : 768px,
 *     'l'   : 992px
 *   );
 *
 *   $var : breakpoint-next('xs');
 *   // = s
 *
 *   $var : breakpoint-next('s');
 *   // = m
 */
/**
 * Anwendung von Definitionen (`@content`) ab dem Breakpoint `$name` und höher
 * (mobile first).
 *
 * @example scss
 *   $breakpoints : (
 *     'xxs' : 0,
 *     'xs'  : 420px,
 *     's'   : 576px,
 *     'm'   : 768px,
 *     'l'   : 992px
 *   );
 *
 *   .container {
 *     background : #fff;
 *
 *     @include media-breakpoint-up('s') {
 *       background : #ccc;
 *     }
 *   }
 *
 * @example css - Result
 *   .container {
 *     background : #fff;
 *   }
 *
 *   @media (min-width: 576px) {
 *     .container {
 *       background : #ccc;
 *     }
 *   }
 */
/**
 * Anwendung von Definitionen (`@content`) ab der Maximalbreite des Breakpoint
 * `$name` und kleiner (desktop first).
 *
 * @example scss
 *   $breakpoints : (
 *     'xxs' : 0,
 *     'xs'  : 420px,
 *     's'   : 576px,
 *     'm'   : 768px,
 *     'l'   : 992px
 *   );
 *
 *   .container {
 *     background : #fff;
 *
 *     @include media-breakpoint-down('s') {
 *       background : #ccc;
 *     }
 *   }
 *
 * @example css - Result
 *   .container {
 *     background : #fff;
 *   }
 *
 *   @media (max-width: 767px) {
 *     .container {
 *       background : #ccc;
 *     }
 *   }
 */
/**
 * Shortcut-Funktion für `media-breakpoint-up()` und `media-breakpoint-down()`.
 */
/**
 * Anwendung von Definitionen (`@content`) zwischen Minimumbreite `$lower` und
 * Maximumbreite `$upper`.
 *
 * @example scss
 *   $breakpoints : (
 *     'xxs' : 0,
 *     'xs'  : 420px,
 *     's'   : 576px,
 *     'm'   : 768px,
 *     'l'   : 992px
 *   );
 *
 *   .container {
 *     background : #fff;
 *
 *     @include media-breakpoint-between('xs', 'm') {
 *       background : #ccc;
 *     }
 *   }
 *
 * @example css - Result
 *   .container {
 *     background : #fff;
 *   }
 *
 *   @media (min-width : 420px) and (max-width : 991px) {
 *     .container {
 *       background : #ccc;
 *     }
 *   }
 */
/**
 * Anwendung von Definitionen (`@content`) nur innerhalb der Minimum- und
 * Maxiamlbreite des Breakpoints `$name`.
 *
 * @example scss
 *   $breakpoints : (
 *     'xxs' : 0,
 *     'xs'  : 420px,
 *     's'   : 576px,
 *     'm'   : 768px,
 *     'l'   : 992px
 *   );
 *
 *   .container {
 *     background : #fff;
 *
 *     @include media-breakpoint-only('s') {
 *       background : #ccc;
 *     }
 *   }
 *
 * @example css - Result
 *   .container {
 *     background : #fff;
 *   }
 *
 *   @media (min-width: 576px) and (max-width: 767px) {
 *     .container {
 *       background : #ccc;
 *     }
 *   }
 */
/**
 * Webfonts mit `@font-face` integrieren.
 *
 * Bzgl. moderner Browser (Stand Anfang 2019) wird normalerweise nur `woff2` und
 * `woff` benötigt. Eine Prüfung auf das Schriftformat bzgl. einer Erweiterung
 * der URL wird nur für `eot` und `ttf` durchgeführt!
 *
 * `woff2` moderne Browser
 * `woff` IE 11
 * `eot` IE6-IE8
 * `ttf` Safari, Android, iOS
 *
 * @example scss
 *   @include font-face(
 *     'Example-Regular',
 *     'example/',
 *     (
 *       woff2 : 'example-regular.woff2',
 *       woff  : 'example-regular.woff',
 *       eot   : 'example-regular.eot',
 *       ttf   : 'example-regular.ttf'
 *     )
 *   );
 *
 * @example css - Result
 *   @font-face {
 *     font-family : 'Example-Regular';
 *     font-display: swap;
 *     src         : url('../example/example-regular.woff2') format('woff2'),
 *                   url('../example/example-regular.woff') format('woff'),
 *                   url('../example/example-regular.eot?#iefix') format('embedded-opentype'),
 *                   url('../example/example-regular.ttf') format('truetype');
 *   }
 */
/**
 * Generieren von Selektoren bzgl. Validierungsstatus
 */
/* stylelint-disable-line scss/dollar-variable-pattern */
@font-face {
  font-weight: 400;
  font-display: swap;
  font-family: Open Sans; /* stylelint-disable-line string-quotes */
  src: url('../default/media/fonts/OpenSans/Open-Sans-Regular.woff2') format('woff2'), url('../default/media/fonts/OpenSans/Open-Sans-Regular.woff') format('woff');
}
@font-face {
  font-weight: 600;
  font-display: swap;
  font-family: Open Sans; /* stylelint-disable-line string-quotes */
  src: url('../default/media/fonts/OpenSans/Open-Sans-600.woff2') format('woff2'), url('../default/media/fonts/OpenSans/Open-Sans-600.woff') format('woff');
}
@font-face {
  font-weight: 700;
  font-display: swap;
  font-family: Open Sans; /* stylelint-disable-line string-quotes */
  src: url('../default/media/fonts/OpenSans/Open-Sans-700.woff2') format('woff2'), url('../default/media/fonts/OpenSans/Open-Sans-700.woff') format('woff');
}
:root {
  --global-rootvar-prefix: wp-;
  --global-duration-multiplier: 1;
  --global-scroll-top-offset: 0; /* stylelint-disable-line length-zero-no-unit */
  --wp-color-black-h: 0;
  --wp-color-black-s: 0%;
  --wp-color-black-l: 0%;
  --wp-color-black: hsl(var(--wp-color-black-h), var(--wp-color-black-s), var(--wp-color-black-l));
  --wp-color-black-lighten-h: var(--wp-color-black-h);
  --wp-color-black-lighten-s: var(--wp-color-black-s);
  --wp-color-black-lighten-l: 90%;
  --wp-color-black-lighten: hsl(var(--wp-color-black-lighten-h), var(--wp-color-black-lighten-s), var(--wp-color-black-lighten-l));
  --wp-color-white-h: 0;
  --wp-color-white-s: 0%;
  --wp-color-white-l: 100%;
  --wp-color-white: hsl(var(--wp-color-white-h), var(--wp-color-white-s), var(--wp-color-white-l));
  --wp-color-text-h: 0;
  --wp-color-text-s: 0%;
  --wp-color-text-l: 0%;
  --wp-color-text: hsl(var(--wp-color-text-h), var(--wp-color-text-s), var(--wp-color-text-l));
  --wp-color-primary-h: 0;
  --wp-color-primary-s: 0%;
  --wp-color-primary-l: 20%;
  --wp-color-primary: hsl(var(--wp-color-primary-h), var(--wp-color-primary-s), var(--wp-color-primary-l));
  --wp-color-primary-lighten-h: var(--wp-color-primary-h);
  --wp-color-primary-lighten-s: var(--wp-color-primary-s);
  --wp-color-primary-lighten-l: 96%;
  --wp-color-primary-lighten: hsl(var(--wp-color-primary-lighten-h), var(--wp-color-primary-lighten-s), var(--wp-color-primary-lighten-l));
  --wp-color-primary-darken-h: var(--wp-color-primary-h);
  --wp-color-primary-darken-s: var(--wp-color-primary-s);
  --wp-color-primary-darken-l: 60%;
  --wp-color-primary-darken: hsl(var(--wp-color-primary-darken-h), var(--wp-color-primary-darken-s), var(--wp-color-primary-darken-l));
  --wp-color-primary-contrast-h: 0;
  --wp-color-primary-contrast-s: 0%;
  --wp-color-primary-contrast-l: 100%;
  --wp-color-primary-contrast: hsl(var(--wp-color-primary-contrast-h), var(--wp-color-primary-contrast-s), var(--wp-color-primary-contrast-l));
  --wp-color-secondary-h: 83;
  --wp-color-secondary-s: 67%;
  --wp-color-secondary-l: 44%;
  --wp-color-secondary: hsl(var(--wp-color-secondary-h), var(--wp-color-secondary-s), var(--wp-color-secondary-l));
  --wp-color-secondary-lighten-h: var(--wp-color-secondary-h);
  --wp-color-secondary-lighten-s: var(--wp-color-secondary-s);
  --wp-color-secondary-lighten-l: 92%;
  --wp-color-secondary-lighten: hsl(var(--wp-color-secondary-lighten-h), var(--wp-color-secondary-lighten-s), var(--wp-color-secondary-lighten-l));
  --wp-color-secondary-darken-h: var(--wp-color-secondary-h);
  --wp-color-secondary-darken-s: var(--wp-color-secondary-s);
  --wp-color-secondary-darken-l: 44%;
  --wp-color-secondary-darken: hsl(var(--wp-color-secondary-darken-h), var(--wp-color-secondary-darken-s), var(--wp-color-secondary-darken-l));
  --wp-color-secondary-contrast-h: 0;
  --wp-color-secondary-contrast-s: 0%;
  --wp-color-secondary-contrast-l: 0%;
  --wp-color-secondary-contrast: hsl(var(--wp-color-secondary-contrast-h), var(--wp-color-secondary-contrast-s), var(--wp-color-secondary-contrast-l));
  --wp-color-grey-h: 260;
  --wp-color-grey-s: 2%;
  --wp-color-grey-l: 28%;
  --wp-color-grey: hsl(var(--wp-color-grey-h), var(--wp-color-grey-s), var(--wp-color-grey-l));
  --wp-color-grey-lighten-h: var(--wp-color-grey-h);
  --wp-color-grey-lighten-s: var(--wp-color-grey-s);
  --wp-color-grey-lighten-l: 90%;
  --wp-color-grey-lighten: hsl(var(--wp-color-grey-lighten-h), var(--wp-color-grey-lighten-s), var(--wp-color-grey-lighten-l));
  --wp-color-grey-darken-h: var(--wp-color-grey-h);
  --wp-color-grey-darken-s: var(--wp-color-grey-s);
  --wp-color-grey-darken-l: 23%;
  --wp-color-grey-darken: hsl(var(--wp-color-grey-darken-h), var(--wp-color-grey-darken-s), var(--wp-color-grey-darken-l));
  --wp-color-grey-contrast-h: 0;
  --wp-color-grey-contrast-s: 0%;
  --wp-color-grey-contrast-l: 100%;
  --wp-color-grey-contrast: hsl(var(--wp-color-grey-contrast-h), var(--wp-color-grey-contrast-s), var(--wp-color-grey-contrast-l));
  --wp-color-grey-light-h: 0;
  --wp-color-grey-light-s: 0%;
  --wp-color-grey-light-l: 76%;
  --wp-color-grey-light: hsl(var(--wp-color-grey-light-h), var(--wp-color-grey-light-s), var(--wp-color-grey-light-l));
  --wp-color-grey-light-contrast-h: 0;
  --wp-color-grey-light-contrast-s: 0%;
  --wp-color-grey-light-contrast-l: 0%;
  --wp-color-grey-light-contrast: hsl(var(--wp-color-grey-light-contrast-h), var(--wp-color-grey-light-contrast-s), var(--wp-color-grey-light-contrast-l));
  --wp-color-danger-h: 355;
  --wp-color-danger-s: 96%;
  --wp-color-danger-l: 61%;
  --wp-color-danger: hsl(var(--wp-color-danger-h), var(--wp-color-danger-s), var(--wp-color-danger-l));
  --wp-color-danger-contrast-h: 0;
  --wp-color-danger-contrast-s: 0%;
  --wp-color-danger-contrast-l: 100%;
  --wp-color-danger-contrast: hsl(var(--wp-color-danger-contrast-h), var(--wp-color-danger-contrast-s), var(--wp-color-danger-contrast-l));
  --wp-color-success-h: 83;
  --wp-color-success-s: 67%;
  --wp-color-success-l: 44%;
  --wp-color-success: hsl(var(--wp-color-success-h), var(--wp-color-success-s), var(--wp-color-success-l));
  --wp-color-success-contrast-h: 0;
  --wp-color-success-contrast-s: 0%;
  --wp-color-success-contrast-l: 0%;
  --wp-color-success-contrast: hsl(var(--wp-color-success-contrast-h), var(--wp-color-success-contrast-s), var(--wp-color-success-contrast-l));
  --wp-color-warning-h: 37;
  --wp-color-warning-s: 98%;
  --wp-color-warning-l: 53%;
  --wp-color-warning: hsl(var(--wp-color-warning-h), var(--wp-color-warning-s), var(--wp-color-warning-l));
  --wp-color-warning-contrast-h: 0;
  --wp-color-warning-contrast-s: 0%;
  --wp-color-warning-contrast-l: 0%;
  --wp-color-warning-contrast: hsl(var(--wp-color-warning-contrast-h), var(--wp-color-warning-contrast-s), var(--wp-color-warning-contrast-l));
  --wp-color-info-h: 232;
  --wp-color-info-s: 100%;
  --wp-color-info-l: 69%;
  --wp-color-info: hsl(var(--wp-color-info-h), var(--wp-color-info-s), var(--wp-color-info-l));
  --wp-color-info-contrast-h: 0;
  --wp-color-info-contrast-s: 0%;
  --wp-color-info-contrast-l: 0%;
  --wp-color-info-contrast: hsl(var(--wp-color-info-contrast-h), var(--wp-color-info-contrast-s), var(--wp-color-info-contrast-l));
  --wp-font-family-sans: 'Open Sans', Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --wp-font-family-heading: 'Open Sans Roman', 'Open Sans', Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --wp-font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  --wp-root-font-size: 16px;
  --wp-font-size-xxs: calc(var(--wp-root-font-size) - 3px);
  --wp-font-size-xs: calc(var(--wp-root-font-size) - 2px);
  --wp-font-size-s: calc(var(--wp-root-font-size) - 1px);
  --wp-font-size-m: calc(var(--wp-root-font-size) + 3px);
  --wp-font-size-l: calc(var(--wp-root-font-size) + 7px);
  --wp-font-size-xl: calc(var(--wp-root-font-size) + 10px);
  --wp-h1-font-size: calc(var(--wp-root-font-size) + 29px);
  --wp-h2-font-size: calc(var(--wp-root-font-size) + 21px);
  --wp-h3-font-size: calc(var(--wp-root-font-size) + 13px);
  --wp-h4-font-size: calc(var(--wp-root-font-size) + 7px);
  --wp-h5-font-size: calc(var(--wp-root-font-size) + 1px);
  --wp-h1-font-size-mobile: calc(var(--wp-root-font-size) + 14px);
  --wp-h2-font-size-mobile: calc(var(--wp-root-font-size) + 11px);
  --wp-h3-font-size-mobile: calc(var(--wp-root-font-size) + 7px);
  --wp-h4-font-size-mobile: calc(var(--wp-root-font-size) + 5px);
  --wp-h5-font-size-mobile: calc(var(--wp-root-font-size) - 1px);
  --wp-font-weight-regular: 400;
  --wp-font-weight-semibold: 600;
  --wp-font-weight-bold: 700;
  --wp-spacing: 16px;
  --wp-spacing-xxs: calc(16px / 8);
  --wp-spacing-xs: calc(16px / 4);
  --wp-spacing-s: calc(16px / 2);
  --wp-spacing-l: calc(16px * 2);
  --wp-spacing-xl: calc(16px * 4);
  --wp-spacing-xxl: calc(16px * 8);
  --wp-grid-gap-x: var(--wp-spacing);
  --wp-grid-gap-y: var(--wp-spacing);
  --wp-breakpoint-xs: 0;
  --wp-breakpoint-s: 576px;
  --wp-breakpoint-m: 768px;
  --wp-breakpoint-l: 992px;
  --wp-breakpoint-xl: 1200px;
  --wp-breakpoint-xxl: 1400px;
  --wp-breakpoint-xxxl: 1600px;
  --wp-breakpoint-xxxxl: 1900px;
  --wp-zindex-page-header: 20;
  --wp-zindex-page-search: 15;
  --wp-zindex-page-scroll-top: 50;
  --wp-zindex-page-menu: 100;
  --wp-zindex-page-footer: 10;
  --wp-zindex-page-loader: 9000;
  --wp-zindex-scroll-progress: 90;
  --wp-body-background-color: var(--wp-color-white);
  --wp-body-color: var(--wp-color-text);
  --wp-body-font-family: var(--wp-font-family-sans);
  --wp-body-font-size: 1rem;
  --wp-body-font-weight: 400;
  --wp-body-line-height: 1.53;
  --wp-page-max-width: 1400px;
  --wp-page-offset: 1rem;
  --wp-content-element-margin: var(--wp-spacing-xl);
  --wp-block-element-margin: calc(var(--wp-spacing-s) * 3);
  --wp-paragraph-margin: var(--wp-spacing);
  --wp-link-color: currentColor;
  --wp-link-color-hover: var(--wp-color-primary);
  --wp-selection-background-color: var(--wp-color-secondary);
  --wp-selection-color: var(--wp-color-secondary-contrast);
  --wp-backdrop-color-h: var(--wp-color-sandstone-h);
  --wp-backdrop-color-s: var(--wp-color-sandstone-s);
  --wp-backdrop-color-l: var(--wp-color-sandstone-l);
  --wp-backdrop-color: hsl(var(--wp-color-sandstone-h), var(--wp-color-sandstone-s), var(--wp-color-sandstone-l));
  --wp-backdrop-filter: none;
  --wp-backdrop-opacity: 0.75;
  --wp-focus-ring-h: var(--wp-color-secondary-h);
  --wp-focus-ring-s: var(--wp-color-secondary-s);
  --wp-focus-ring-l: var(--wp-color-secondary-l);
  --wp-focus-ring-alpha: 0.25;
  --wp-focus-ring-width: 3px;
  --wp-focus-ring-box-shadow: 0 0 0 var(--wp-focus-ring-width) hsla(var(--wp-focus-ring-h), var(--wp-focus-ring-s), var(--wp-focus-ring-l), var(--wp-focus-ring-alpha));
  --wp-focus-ring-box-shadow-inset: inset 0 0 0 var(--wp-focus-ring-width) hsla(var(--wp-focus-ring-h), var(--wp-focus-ring-s), var(--wp-focus-ring-l), var(--wp-focus-ring-alpha));
  --wp-focus-outline-color: currentColor;
  --wp-focus-outline-offset: 2px;
  --wp-focus-outline-style: solid;
  --wp-focus-outline-width: 2px;
  --wp-ratio-16x9: calc(9 / 16 * 100%);
  --wp-ratio-4x3: calc(3 / 4 * 100%);
  --wp-ratio-3x4: calc(4 / 3 * 100%);
  --wp-ratio-3x2: calc(2 / 3 * 100%);
  --wp-ratio-2x3: calc(3 / 2 * 100%);
  --wp-ratio-1x1: calc(1 / 1 * 100%);
  --wp-border-width: 1px;
  --wp-border-radius-xs: 2px;
  --wp-border-radius-s: 4px;
  --wp-border-radius-m: 8px;
  --wp-border-radius-l: 16px;
  --wp-border-radius: 0;
  --wp-section-border-radius: 0 0 0 0;
  --wp-transition-average: calc(var(--global-duration-multiplier, 1) * 0.25s);
  --wp-transition-complex: calc(var(--global-duration-multiplier, 1) * 0.5s);
  --wp-transition-check: calc(var(--global-duration-multiplier, 1) * 0.15s);
  --wp-transition-expand: calc(var(--global-duration-multiplier, 1) * 0.3s);
  --wp-transition-collape: calc(var(--global-duration-multiplier, 1) * 0.25s);
  --wp-transition-open: calc(var(--global-duration-multiplier, 1) * 0.25s);
  --wp-transition-close: calc(var(--global-duration-multiplier, 1) * 0.2s);
  --wp-transition: 0.2s;
  --wp-scrollbar-size: 6px;
  --wp-scrollbar-color: var(--wp-color-black);
  --wp-scrollbar-track-color: var(--wp-color-white);
}

@media (min-width: 992px) {
  :root {
    --wp-root-font-size: 16px;
    --wp-grid-gap-x: var(--wp-spacing-l);
    --wp-grid-gap-y: var(--wp-spacing-l);
    --wp-page-offset: calc(1rem * 2);
    --wp-content-element-margin: calc(var(--wp-spacing-l) * 3);
    --wp-block-element-margin: calc(var(--wp-spacing) * 3);
    --wp-paragraph-margin: var(--wp-spacing);
  }
}
@media (min-width: 1400px) {
  :root {
    --wp-section-border-radius: 0 0 0 0;
  }
}
@media (prefers-reduced-motion: reduce) {
  :root {
    --global-duration-multiplier: 0.0; /* stylelint-disable-line number-no-trailing-zeros */
  }
}
:root {
  font-size: var(--wp-root-font-size);
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

html {
  background-color: var(--wp-body-background-color);
  box-sizing: border-box;
  min-height: 100%;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}
html.browser-not-supported {
  background-color: #fff !important; /* stylelint-disable-line declaration-no-important */
}
html.browser-not-supported .browser-not-supported__info {
  background-color: #000;
  color: #fff;
  display: block;
  left: 50%;
  max-width: 32rem;
  padding: 16px 32px;
  position: fixed;
  text-align: center;
  top: 1rem;
  transform: translateX(-50%);
  width: 90%;
}

body {
  background-color: transparent;
  color: var(--wp-body-color);
  font-family: var(--wp-body-font-family);
  font-size: var(--wp-body-font-size);
  font-style: normal;
  font-variant: normal;
  font-weight: var(--wp-body-font-weight);
  line-height: var(--wp-body-line-height);
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
body::-webkit-scrollbar {
  height: var(--wp-scrollbar-size, 4px);
  width: var(--wp-scrollbar-size, 4px);
}
body::-webkit-scrollbar-thumb {
  background: var(--wp-scrollbar-color, #000);
}
body::-webkit-scrollbar-track {
  background: var(--wp-scrollbar-track-color, transparent);
}
body {
  scrollbar-face-color: var(--wp-scrollbar-color, #000);
  scrollbar-track-color: var(--wp-scrollbar-track-color, transparent);
}
.page-complete body {
  overflow-y: auto;
  pointer-events: all;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

::selection {
  background-color: var(--wp-selection-background-color);
  color: var(--wp-selection-color);
}

svg:not(:root) {
  overflow: hidden;
}

:focus-visible {
  outline: var(--wp-focus-outline-width) var(--wp-focus-outline-style) var(--focus-outline-color, var(--wp-focus-outline-color));
  outline-offset: var(--wp-focus-outline-offset);
}

/* stylelint-disable string-quotes */
a:focus,
button:focus,
[type=submit]:focus,
[type=button]:focus {
  box-shadow: none;
  outline: none;
}
a._focus-visible:focus, a.focus-visible:focus, a:focus-visible:focus,
button._focus-visible:focus,
button.focus-visible:focus,
button:focus-visible:focus,
[type=submit]._focus-visible:focus,
[type=submit].focus-visible:focus,
[type=submit]:focus-visible:focus,
[type=button]._focus-visible:focus,
[type=button].focus-visible:focus,
[type=button]:focus-visible:focus {
  outline: var(--wp-focus-outline-width) var(--wp-focus-outline-style) var(--focus-outline-color, var(--wp-focus-outline-color));
  outline-offset: var(--wp-focus-outline-offset);
}

/* stylelint-enable string-quotes */
:any-link {
  --wp-link-color: currentColor;
  --wp-link-color-hover: var(--wp-link-color);
  --wp-link-icon-color: var(--wp-link-color);
  --wp-link-icon-color-hover: var(--wp-link-color-hover);
  --wp-link-text-decoration-line: underline;
  --wp-link-text-decoration-style: solid;
  --wp-link-text-decoration-hover: var(--wp-link-text-decoration);
  --wp-focus-outline-color: currentColor;
  --_link-icon-color: var(--wp-link-icon-color);
  --_link-duration: calc(var(--global-duration-multiplier, 1) * var(--wp-transition));
  color: var(--wp-link-color);
  font-weight: var(--wp-link-font-weight, inherit);
  text-decoration: var(--wp-link-text-decoration-line) var(--wp-link-text-decoration-style) var(--wp-link-text-decoration-color);
  text-decoration-thickness: max(1px, 0.0625em);
  text-underline-offset: 0.125em;
  transition: color var(--_link-duration), background-color var(--wp-transition), border-color var(--wp-transition);
  /* stylelint-disable string-quotes */
  /* stylelint-enable string-quotes */
}
:any-link > i {
  color: var(--_link-icon-color);
  margin-right: var(--wp-spacing-s);
}
:any-link[href*="tel:"], :any-link[href*="fax:"] {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
:any-link:hover, :any-link:active, :any-link:focus, :any-link:focus-visible {
  --_link-icon-color: var(--wp-link-icon-color-hover);
  color: var(--wp-link-color-hover);
}
:any-link:hover, :any-link:focus {
  cursor: pointer;
}
:any-link._disabled {
  --wp-link-text-decoration-line: line-through;
  color: var(--wp-link-color);
  pointer-events: none;
}
:any-link._disabled * {
  pointer-events: none;
}

hr {
  background-color: currentColor;
  border: 0;
  color: var(--wp-color-black);
  margin: var(--wp-spacing) 0;
}
hr:not([size]) {
  height: 1px;
}

p {
  margin: 0 0 var(--wp-paragraph-margin, var(--wp-block-element-margin));
}

blockquote {
  margin: 0 0 var(--wp-blockquote-margin, var(--wp-block-element-margin));
}

table {
  width: 100%;
}

iframe {
  border: 0 none;
  min-width: 100%;
}

figure,
.figure,
picture,
.picture {
  background-color: transparent;
  border: 0 none;
  border-radius: 0;
  display: block;
  padding: 0;
  position: relative;
  width: 100%;
}

img {
  border: 0;
  display: block;
  height: auto;
  max-width: 100%;
}

.time__time {
  display: none;
}
.time__time::before {
  content: ', ';
}

.application-context-bar {
  background-color: var(--wp-color-warning);
  bottom: 0;
  color: var(--wp-color-warning-contrast);
  font-size: 12px;
  left: 50%;
  padding: 0.125rem 0.25rem;
  pointer-events: none;
  position: fixed;
  text-align: center;
  transform: translateX(-50%);
  z-index: 9999;
}
.application-context-bar > span {
  text-transform: uppercase;
}
.application-context-bar.-staging {
  width: 100%;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: var(--wp-heading-color, var(--wp-color-text));
  display: block;
  font-family: var(--wp-heading-font-family, var(--wp-font-family-sans));
  font-size: var(--wp-heading-font-size, 1rem);
  font-weight: var(--wp-heading-font-weight, var(--wp-font-weight-bold));
  -webkit-hyphens: manual;
  -ms-hyphens: manual;
  hyphens: manual;
  line-height: var(--wp-heading-line-height, 1.125);
  margin: 0 0 var(--wp-heading-margin, 1.125em);
  overflow-wrap: break-word;
  width: 100%;
  word-wrap: break-word;
}
h1 > a, .h1 > a, h2 > a, .h2 > a, h3 > a, .h3 > a, h4 > a, .h4 > a, h5 > a, .h5 > a, h6 > a, .h6 > a {
  color: inherit;
}
h1:first-child, .h1:first-child, h2:first-child, .h2:first-child, h3:first-child, .h3:first-child, h4:first-child, .h4:first-child, h5:first-child, .h5:first-child, h6:first-child, .h6:first-child {
  margin-top: 0;
}
h1:last-child, .h1:last-child, h2:last-child, .h2:last-child, h3:last-child, .h3:last-child, h4:last-child, .h4:last-child, h5:last-child, .h5:last-child, h6:last-child, .h6:last-child {
  margin-bottom: 0;
}

h1,
.h1 {
  --wp-heading-font-size: var(--wp-h1-font-size-mobile);
}
@media (min-width: 768px) {
  h1,
  .h1 {
    --wp-heading-font-size: var(--wp-h1-font-size);
  }
}

h2,
.h2 {
  --wp-heading-font-size: var(--wp-h2-font-size-mobile);
}
@media (min-width: 768px) {
  h2,
  .h2 {
    --wp-heading-font-size: var(--wp-h2-font-size);
  }
}

h3,
.h3 {
  --wp-heading-font-size: var(--wp-h3-font-size-mobile);
}
@media (min-width: 768px) {
  h3,
  .h3 {
    --wp-heading-font-size: var(--wp-h3-font-size);
  }
}

h4,
.h4 {
  --wp-heading-font-size: var(--wp-h4-font-size-mobile);
}
@media (min-width: 768px) {
  h4,
  .h4 {
    --wp-heading-font-size: var(--wp-h4-font-size);
  }
}

h5,
.h5 {
  --wp-heading-font-size: var(--wp-h5-font-size-mobile);
}
@media (min-width: 768px) {
  h5,
  .h5 {
    --wp-heading-font-size: var(--wp-h5-font-size);
  }
}

h1,
.h1 {
  --wp-heading-line-height: 1.3;
}
@media (min-width: 768px) {
  h1,
  .h1 {
    --wp-heading-line-height: 1.3;
  }
}

h2,
.h2 {
  --wp-heading-color: var(--wp-color-secondary) !important;
  --wp-heading-line-height: 1.33;
}
@media (min-width: 768px) {
  h2,
  .h2 {
    --wp-heading-line-height: 1.33;
  }
}

h3,
.h3 {
  --wp-heading-line-height: 1.38;
}
@media (min-width: 768px) {
  h3,
  .h3 {
    --wp-heading-line-height: 1.38;
  }
}

h4,
.h4 {
  --wp-heading-line-height: 1.42;
}
@media (min-width: 768px) {
  h4,
  .h4 {
    --wp-heading-line-height: 1.42;
  }
}

h5,
.h5 {
  --wp-heading-line-height: 1.1;
}
@media (min-width: 768px) {
  h5,
  .h5 {
    --wp-heading-line-height: 1.1;
  }
}

em,
.em {
  font-style: italic;
}

small,
.small,
.text-small {
  font-size: var(--wp-font-size-s);
}

mark,
.mark {
  background-color: var(--wp-mark-background-color, var(--wp-color-secondary));
  color: var(--wp-mark-color, var(--wp-color-secondary-contrast));
  padding: 0 var(--wp-spacing-xs);
}

del,
s {
  text-decoration-line: line-through;
}

del {
  color: var(--wp-del-color, hsl(var(--wp-color-black-h), var(--wp-color-black-s), 70%));
}

b,
strong,
.strong {
  font-weight: var(--wp-font-weight-bold);
}

.text-color-primary {
  color: var(--wp-color-primary);
}
.text-color-primary a:not(.button) {
  --wp-link-color: currentColor;
}
.text-color-secondary {
  color: var(--wp-color-secondary);
}
.text-color-secondary a:not(.button) {
  --wp-link-color: currentColor;
}
.text-color-tertiary {
  color: var(--wp-color-tertiary);
}
.text-color-tertiary a:not(.button) {
  --wp-link-color: currentColor;
}
.text-color-sandstone {
  color: var(--wp-color-sandstone);
}
.text-color-sandstone a:not(.button) {
  --wp-link-color: currentColor;
}
.text-color-text {
  color: var(--wp-color-text);
}
.text-color-text a:not(.button) {
  --wp-link-color: currentColor;
}
.text-color-danger {
  color: var(--wp-color-danger);
}
.text-color-danger a:not(.button) {
  --wp-link-color: currentColor;
}
.text-color-success {
  color: var(--wp-color-success);
}
.text-color-success a:not(.button) {
  --wp-link-color: currentColor;
}
.text-color-warning {
  color: var(--wp-color-warning);
}
.text-color-warning a:not(.button) {
  --wp-link-color: currentColor;
}
.text-color-info {
  color: var(--wp-color-info);
}
.text-color-info a:not(.button) {
  --wp-link-color: currentColor;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-align-center {
  text-align: center;
}
.text-align-justify {
  text-align: justify;
}
.text-size-xxs {
  font-size: var(--wp-font-size-xxs);
}
.text-size-xs {
  font-size: var(--wp-font-size-xs);
}
.text-size-s {
  font-size: var(--wp-font-size-s);
}
.text-size-m {
  font-size: var(--wp-font-size-m);
}
.text-size-l {
  font-size: var(--wp-font-size-l);
}
.text-size-xl {
  font-size: var(--wp-font-size-xl);
}
.subtitle::before {
  content: "( "; /* stylelint-disable-line string-quotes */
  padding-right: 0.125em;
}
.subtitle::after {
  content: ")"; /* stylelint-disable-line string-quotes */
  padding-left: 0.125em;
}

a:not([class]) {
  text-decoration: underline;
}

/* stylelint-disable-line length-zero-no-unit */
:root {
  --ifabsh-color-black-h: 0;
  --ifabsh-color-black-s: 0%;
  --ifabsh-color-black-l: 0%;
  --ifabsh-color-black: hsl(var(--ifabsh-color-black-h), var(--ifabsh-color-black-s), var(--ifabsh-color-black-l));
  --ifabsh-color-white-h: 0;
  --ifabsh-color-white-s: 0%;
  --ifabsh-color-white-l: 100%;
  --ifabsh-color-white: hsl(var(--ifabsh-color-white-h), var(--ifabsh-color-white-s), var(--ifabsh-color-white-l));
  --ifabsh-color-grey-h: 0;
  --ifabsh-color-grey-s: 0%;
  --ifabsh-color-grey-l: 91%;
  --ifabsh-color-grey: hsl(var(--ifabsh-color-grey-h), var(--ifabsh-color-grey-s), var(--ifabsh-color-grey-l));
  --ifabsh-color-grey-light-h: 0;
  --ifabsh-color-grey-light-s: 0%;
  --ifabsh-color-grey-light-l: 95%;
  --ifabsh-color-grey-light: hsl(var(--ifabsh-color-grey-light-h), var(--ifabsh-color-grey-light-s), var(--ifabsh-color-grey-light-l));
  --ifabsh-color-border-h: 0;
  --ifabsh-color-border-s: 0%;
  --ifabsh-color-border-l: 91%;
  --ifabsh-color-border: hsl(var(--ifabsh-color-border-h), var(--ifabsh-color-border-s), var(--ifabsh-color-border-l));
  --ifabsh-color-placeholder-h: 0;
  --ifabsh-color-placeholder-s: 0%;
  --ifabsh-color-placeholder-l: 58%;
  --ifabsh-color-placeholder: hsl(var(--ifabsh-color-placeholder-h), var(--ifabsh-color-placeholder-s), var(--ifabsh-color-placeholder-l));
  --ifabsh-color-text-h: 0;
  --ifabsh-color-text-s: 0%;
  --ifabsh-color-text-l: 24%;
  --ifabsh-color-text: hsl(var(--ifabsh-color-text-h), var(--ifabsh-color-text-s), var(--ifabsh-color-text-l));
  --ifabsh-color-ui-h: 232;
  --ifabsh-color-ui-s: 100%;
  --ifabsh-color-ui-l: 69%;
  --ifabsh-color-ui: hsl(var(--ifabsh-color-ui-h), var(--ifabsh-color-ui-s), var(--ifabsh-color-ui-l));
  --ifabsh-color-ui-contrast-h: 0;
  --ifabsh-color-ui-contrast-s: 0%;
  --ifabsh-color-ui-contrast-l: 100%;
  --ifabsh-color-ui-contrast: hsl(var(--ifabsh-color-ui-contrast-h), var(--ifabsh-color-ui-contrast-s), var(--ifabsh-color-ui-contrast-l));
  --ifabsh-color-indeterminate-h: 37;
  --ifabsh-color-indeterminate-s: 98%;
  --ifabsh-color-indeterminate-l: 53%;
  --ifabsh-color-indeterminate: hsl(var(--ifabsh-color-indeterminate-h), var(--ifabsh-color-indeterminate-s), var(--ifabsh-color-indeterminate-l));
  --ifabsh-color-danger-h: 355;
  --ifabsh-color-danger-s: 96%;
  --ifabsh-color-danger-l: 61%;
  --ifabsh-color-danger: hsl(var(--ifabsh-color-danger-h), var(--ifabsh-color-danger-s), var(--ifabsh-color-danger-l));
  --ifabsh-color-danger-contrast-h: 0;
  --ifabsh-color-danger-contrast-s: 0%;
  --ifabsh-color-danger-contrast-l: 100%;
  --ifabsh-color-danger-contrast: hsl(var(--ifabsh-color-danger-contrast-h), var(--ifabsh-color-danger-contrast-s), var(--ifabsh-color-danger-contrast-l));
  --ifabsh-color-success-h: 158;
  --ifabsh-color-success-s: 80%;
  --ifabsh-color-success-l: 42%;
  --ifabsh-color-success: hsl(var(--ifabsh-color-success-h), var(--ifabsh-color-success-s), var(--ifabsh-color-success-l));
  --ifabsh-color-success-contrast-h: 0;
  --ifabsh-color-success-contrast-s: 0%;
  --ifabsh-color-success-contrast-l: 100%;
  --ifabsh-color-success-contrast: hsl(var(--ifabsh-color-success-contrast-h), var(--ifabsh-color-success-contrast-s), var(--ifabsh-color-success-contrast-l));
  --ifabsh-color-warning-h: 37;
  --ifabsh-color-warning-s: 98%;
  --ifabsh-color-warning-l: 53%;
  --ifabsh-color-warning: hsl(var(--ifabsh-color-warning-h), var(--ifabsh-color-warning-s), var(--ifabsh-color-warning-l));
  --ifabsh-color-warning-contrast-h: 0;
  --ifabsh-color-warning-contrast-s: 0%;
  --ifabsh-color-warning-contrast-l: 0%;
  --ifabsh-color-warning-contrast: hsl(var(--ifabsh-color-warning-contrast-h), var(--ifabsh-color-warning-contrast-s), var(--ifabsh-color-warning-contrast-l));
  --ifabsh-color-info-h: 189;
  --ifabsh-color-info-s: 64%;
  --ifabsh-color-info-l: 49%;
  --ifabsh-color-info: hsl(var(--ifabsh-color-info-h), var(--ifabsh-color-info-s), var(--ifabsh-color-info-l));
  --ifabsh-color-info-contrast-h: 0;
  --ifabsh-color-info-contrast-s: 0%;
  --ifabsh-color-info-contrast-l: 0%;
  --ifabsh-color-info-contrast: hsl(var(--ifabsh-color-info-contrast-h), var(--ifabsh-color-info-contrast-s), var(--ifabsh-color-info-contrast-l));
  --ifabsh-focus-outline-width: 1px;
  --ifabsh-focus-outline-style: dotted;
  --ifabsh-focus-outline-color: currentColor;
  --ifabsh-focus-outline-offset: 2px;
  --ifabsh-backdrop-color: var(--ifabsh-color-black);
  --ifabsh-backdrop-filter: none;
  --ifabsh-backdrop-opacity: 0.5;
  --ifabsh-font-size: 1rem;
  --ifabsh-font-size-xs: 0.5rem;
  --ifabsh-font-size-s: 0.75rem;
  --ifabsh-font-size-l: 1.25rem;
  --ifabsh-spacing: 1rem;
  --ifabsh-spacing-xxs: 0.125rem;
  --ifabsh-spacing-xs: 0.25rem;
  --ifabsh-spacing-s: 0.5rem;
  --ifabsh-spacing-l: 1.25rem;
  --ifabsh-breakpoint-xs: 0;
  --ifabsh-breakpoint-s: 576px;
  --ifabsh-breakpoint-m: 768px;
  --ifabsh-breakpoint-l: 992px;
  --ifabsh-breakpoint-xl: 1200px;
  --ifabsh-border-radius: 0px;
  --ifabsh-border-width: 1px;
  --ifabsh-input-background-color: var(--ifabsh-color-white);
  --ifabsh-input-color: var(--ifabsh-color-black);
  --ifabsh-input-border-color: var(--ifabsh-color-border);
  --ifabsh-input-border-width: var(--ifabsh-border-width);
  --ifabsh-input-disabled-background-color: var(--ifabsh-color-white);
  --ifabsh-input-disabled-opacity: var(--ifabsh-disabled-opacity, 0.4);
  --ifabsh-input-readonly-background-color: var(--ifabsh-color-grey-light);
  --ifabsh-input-readonly-opacity: var(--ifabsh-readonly-opacity, 1);
  --ifabsh-input-height: 40px;
  --ifabsh-input-height-s: 30px;
  --ifabsh-input-height-l: 50px;
}

:root {
  --ifabsh-color-border-h: var(--wp-color-black-h);
  --ifabsh-color-border-s: var(--wp-color-black-s);
  --ifabsh-color-border-l: 85%;
  --ifabsh-color-text-h: var(--wp-color-text-h);
  --ifabsh-color-text-s: var(--wp-color-text-s);
  --ifabsh-color-text-l: var(--wp-color-text-l);
  --ifabsh-color-ui-h: var(--wp-color-primary-h);
  --ifabsh-color-ui-s: var(--wp-color-primary-s);
  --ifabsh-color-ui-l: var(--wp-color-primary-l);
  --ifabsh-color-ui-contrast-h: var(--wp-color-primary-contrast-h);
  --ifabsh-color-ui-contrast-s: var(--wp-color-primary-contrast-s);
  --ifabsh-color-ui-contrast-l: var(--wp-color-primary-contrast-l);
  --ifabsh-color-danger-h: var(--wp-color-danger-h);
  --ifabsh-color-danger-s: var(--wp-color-danger-s);
  --ifabsh-color-danger-l: var(--wp-color-danger-l);
  --ifabsh-color-danger-contrast-h: var(--wp-color-danger-contrast-h);
  --ifabsh-color-danger-contrast-s: var(--wp-color-danger-contrast-s);
  --ifabsh-color-danger-contrast-l: var(--wp-color-danger-contrast-l);
  --ifabsh-color-success-h: var(--wp-color-success-h);
  --ifabsh-color-success-s: var(--wp-color-success-s);
  --ifabsh-color-success-l: var(--wp-color-success-l);
  --ifabsh-color-success-contrast-h: var(--wp-color-success-contrast-h);
  --ifabsh-color-success-contrast-s: var(--wp-color-success-contrast-s);
  --ifabsh-color-success-contrast-l: var(--wp-color-success-contrast-l);
  --ifabsh-color-warning-h: var(--wp-color-warning-h);
  --ifabsh-color-warning-s: var(--wp-color-warning-s);
  --ifabsh-color-warning-l: var(--wp-color-warning-l);
  --ifabsh-color-warning-contrast-h: var(--wp-color-warning-contrast-h);
  --ifabsh-color-warning-contrast-s: var(--wp-color-warning-contrast-s);
  --ifabsh-color-warning-contrast-l: var(--wp-color-warning-contrast-l);
  --ifabsh-color-info-h: var(--wp-color-info-h);
  --ifabsh-color-info-s: var(--wp-color-info-s);
  --ifabsh-color-info-l: var(--wp-color-info-l);
  --ifabsh-color-info-contrast-h: var(--wp-color-info-contrast-h);
  --ifabsh-color-info-contrast-s: var(--wp-color-info-contrast-s);
  --ifabsh-color-info-contrast-l: var(--wp-color-info-contrast-l);
  --ifabsh-focus-outline-width: var(--wp-focus-outline-width);
  --ifabsh-focus-outline-style: var(--wp-focus-outline-style);
  --ifabsh-focus-outline-color: var(--wp-focus-outline-color);
  --ifabsh-focus-outline-offset: var(--wp-focus-outline-offset);
  --ifabsh-backdrop-color: var(--wp-backdrop-color);
  --ifabsh-backdrop-filter: var(--wp-backdrop-filter);
  --ifabsh-backdrop-opacity: var(--wp-backdrop-opacity);
  --ifabsh-font-size-xs: var(--wp-font-size-xs);
  --ifabsh-font-size-s: var(--wp-font-size-s);
  --ifabsh-font-size-l: var(--wp-font-size-l);
  --ifabsh-spacing: var(--wp-spacing);
  --ifabsh-spacing-xxs: var(--wp-spacing-xxs);
  --ifabsh-spacing-xs: var(--wp-spacing-xs);
  --ifabsh-spacing-s: var(--wp-spacing-s);
  --ifabsh-spacing-l: var(--wp-spacing-l);
  --ifabsh-border-radius: var(--wp-border-radius);
  --ifabsh-border-width: var(--wp-border-width);
  --ifabsh-scroll-progress-zindex: var(--wp-zindex-scroll-progress);
}

[data-ajax-content] {
  --ifabsh-ajax-content-icon-content: "";
  min-height: var(--ifabsh-ajax-content-icon-size, var(--ifabsh-spacing-l));
}
[data-ajax-content]::before {
  color: var(--ifabsh-ajax-content-icon-color, currentColor);
  content: var(--ifabsh-ajax-content-icon-content, "\f110");
  font-family: var(--ifabsh-ajax-content-icon-font-family, "Font Awesome 6 Pro");
  font-size: var(--ifabsh-ajax-content-icon-size, var(--ifabsh-spacing-l));
  font-weight: var(--ifabsh-ajax-content-icon-weight, 400);
  position: absolute;
}
[data-ajax-content][data-is-rendering] {
  --ifabsh-ajax-content-icon-content: "";
  position: relative;
}
[data-ajax-content][data-is-rendering] > * {
  opacity: 0.4;
}
[data-ajax-content][data-is-rendering]::before {
  left: 50%;
  opacity: 1 !important;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  -webkit-animation: var(--ifabsh-ajax-content-animation-name, loading) var(--ifabsh-ajax-content-animation-duration, 2s) var(--ifabsh-ajax-content-animation-timing-function, linear) var(--ifabsh-ajax-content-animation-iteration-count, infinite);
  -moz-animation: var(--ifabsh-ajax-content-animation-name, loading) var(--ifabsh-ajax-content-animation-duration, 2s) var(--ifabsh-ajax-content-animation-timing-function, linear) var(--ifabsh-ajax-content-animation-iteration-count, infinite);
  animation: var(--ifabsh-ajax-content-animation-name, loading) var(--ifabsh-ajax-content-animation-duration, 2s) var(--ifabsh-ajax-content-animation-timing-function, linear) var(--ifabsh-ajax-content-animation-iteration-count, infinite);
}
[data-ajax-content][data-is-loading] {
  --ifabsh-ajax-content-icon-content: "";
  position: relative;
}
[data-ajax-content][data-is-loading]::before {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  -webkit-animation: var(--ifabsh-ajax-content-animation-name, loading) var(--ifabsh-ajax-content-animation-duration, 2s) var(--ifabsh-ajax-content-animation-timing-function, linear) var(--ifabsh-ajax-content-animation-iteration-count, infinite);
  -moz-animation: var(--ifabsh-ajax-content-animation-name, loading) var(--ifabsh-ajax-content-animation-duration, 2s) var(--ifabsh-ajax-content-animation-timing-function, linear) var(--ifabsh-ajax-content-animation-iteration-count, infinite);
  animation: var(--ifabsh-ajax-content-animation-name, loading) var(--ifabsh-ajax-content-animation-duration, 2s) var(--ifabsh-ajax-content-animation-timing-function, linear) var(--ifabsh-ajax-content-animation-iteration-count, infinite);
}

[data-ajax-content] {
  min-height: var(--ifabsh-ajax-content-icon-size, var(--ifabsh-spacing-l));
}
@-moz-keyframes loading {
  100% {
    -moz-transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes loading {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes loading {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.avatar {
  --ifabsh-avatar-background-color: var(--ifabsh-color-grey-light, #f1f1f1);
  --ifabsh-avatar-border-color: var(--ifabsh-color-border, #e8e8e8);
  --ifabsh-avatar-border-radius: var(--ifabsh-border-radius, 0px);
  --ifabsh-avatar-border-width: var(--ifabsh-border-width, 1px);
  --ifabsh-avatar-color: var(--ifabsh-color-text, #3e3e3e);
  --ifabsh-avatar-size: 40px;
  --ifabsh-avatar-duration: var(--ifabsh-duration, 0.15s);
  --_av-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-avatar-duration));
  align-items: center;
  background-color: var(--ifabsh-avatar-background-color);
  border: var(--ifabsh-avatar-border-width) solid var(--ifabsh-avatar-border-color);
  border-radius: var(--ifabsh-avatar-border-radius);
  color: var(--ifabsh-avatar-color);
  display: inline-flex;
  font-family: var(--ifabsh-avatar-font-family, inherit);
  font-weight: var(--ifabsh-avatar-font-weight, normal);
  height: var(--ifabsh-avatar-size);
  justify-content: center;
  max-width: var(--ifabsh-avatar-size);
  min-width: var(--ifabsh-avatar-size);
  overflow: hidden;
  position: relative;
  user-select: none;
  vertical-align: middle;
  width: var(--ifabsh-avatar-size);
}
.avatar__icon, .avatar__image {
  background-color: var(--ifabsh-avatar-background-color);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.avatar__image {
  border-radius: var(--ifabsh-avatar-border-radius);
  object-fit: cover;
  overflow: hidden;
}
.avatar__image img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.avatar__icon {
  align-items: center;
  display: flex;
  font-size: calc(var(--ifabsh-avatar-size) * var(--ifabsh-avatar-icon-size-factor, 0.375));
  justify-content: center;
}
.avatar__initials {
  font-size: calc(var(--ifabsh-avatar-size) * var(--ifabsh-avatar-initials-size-factor, 0.375));
  line-height: 1;
  text-transform: uppercase;
}
a.avatar {
  transition: all var(--_av-duration);
}
a.avatar, a.avatar:visited, a.avatar:hover, a.avatar:focus, a.avatar:active {
  color: var(--ifabsh-avatar-color);
  outline-color: unset;
  text-decoration: none;
}
a.avatar._focus-visible:focus, a.avatar:focus-visible:focus {
  outline-color: currentColor;
}

.avatar-group {
  --ifabsh-avatar-group-offset-factor: -0.25;
}
.avatar-group .avatar:not(:first-of-type) {
  margin-left: calc(var(--ifabsh-avatar-size) * var(--ifabsh-avatar-group-offset-factor));
}
@media (hover: hover) {
  .avatar-group a.avatar:hover {
    z-index: 1;
  }
}

.avatar.-s {
  --ifabsh-avatar-size: 30px;
}

.avatar.-l {
  --ifabsh-avatar-size: 50px;
}

.avatar.-circle {
  --ifabsh-avatar-border-radius: 100%;
}

.alert {
  --ifabsh-alert-border-radius: var(--ifabsh-border-radius, 0px);
  --ifabsh-alert-border-width: var(--ifabsh-border-width, 1px);
  --ifabsh-alert-color: var(--ifabsh-color-text, #3e3e3e);
  --ifabsh-alert-font-size: var(--ifabsh-font-size-s, 0.75rem);
  --ifabsh-alert-icon-font-size: var(--ifabsh-alert-font-size);
  --ifabsh-alert-padding-x: var(--ifabsh-spacing, 1rem);
  --ifabsh-alert-padding-y: var(--ifabsh-spacing, 1rem);
  --ifabsh-alert-duration: var(--ifabsh-duration, 0.15s);
  --ifabsh-alert-accent-color: var(--ifabsh-alert-color);
  --_al-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-alert-duration));
  align-items: stretch;
  background-color: var(--ifabsh-alert-background-color, transparent);
  border: var(--ifabsh-alert-border-width) solid var(--ifabsh-alert-border-color, var(--ifabsh-border-color, #e8e8e8));
  border-radius: var(--ifabsh-alert-border-radius);
  color: var(--ifabsh-alert-color);
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;
  transition: all var(--_al-duration) ease-in-out;
}
.alert::before {
  --_al-offset: calc(var(--ifabsh-alert-border-width) * -1);
  background-color: var(--ifabsh-alert-accent-color);
  border-top-left-radius: var(--ifabsh-alert-border-radius);
  border-top-right-radius: var(--ifabsh-alert-border-radius);
  content: ""; /* stylelint-disable-line string-quotes */
  display: block;
  height: var(--ifabsh-alert-accent-size, calc(var(--ifabsh-alert-border-width) * 2));
  left: var(--_al-offset);
  position: absolute;
  right: var(--_al-offset);
  top: var(--_al-offset);
  z-index: 3;
}
.alert__message {
  flex: 1 1 auto;
  font-size: var(--ifabsh-alert-font-size);
  order: 2;
  overflow: hidden;
  padding: var(--ifabsh-alert-padding-y) var(--ifabsh-alert-padding-x);
}
.alert__message > *:first-child {
  margin-top: 0;
}
.alert__message > *:last-child {
  margin-bottom: 0;
}
.alert__icon {
  align-items: center;
  color: var(--ifabsh-alert-accent-color);
  display: flex;
  flex: 0 0 auto;
  font-size: var(--ifabsh-alert-icon-font-size);
  margin-left: var(--ifabsh-alert-padding-x);
  order: 1;
}
.alert__close {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  font-size: var(--ifabsh-alert-icon-font-size);
  order: 3;
  padding-right: calc(var(--ifabsh-alert-padding-x) / 2);
}
.alert__close > .icon-button {
  --ifabsh-icon-button-color-hover: var(--ifabsh-alert-color);
}
[dir=rtl] .alert { /* stylelint-disable-line string-quotes */ }
[dir=rtl] .alert__message {
  text-align: right;
}
[dir=rtl] .alert__icon {
  margin-left: 0;
  margin-right: var(--ifabsh-alert-padding-x);
}

.alert.-primary {
  --ifabsh-alert-accent-color: var(--ifabsh-color-ui, #6176ff);
}

.alert.-info {
  --ifabsh-alert-accent-color: var(--ifabsh-color-info, #2db5cd);
}

.alert.-success {
  --ifabsh-alert-accent-color: var(--ifabsh-color-success, #15c182);
}

.alert.-warning {
  --ifabsh-alert-accent-color: var(--ifabsh-color-warning, #fca311);
}

.alert.-danger {
  --ifabsh-alert-accent-color: var(--ifabsh-color-danger, #fb3e4e);
}

.alert-group {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--ifabsh-alert-group-gap, var(--ifabsh-spacing-s, 0.5rem));
}
.alert-group:empty {
  display: none;
}

.badge {
  align-items: center;
  background-color: var(--ifabsh-badge-background-color, transparent);
  border: var(--ifabsh-badge-border-width, var(--ifabsh-border-width, 1px)) solid var(--ifabsh-badge-border-color, var(--ifabsh-badge-background-color));
  border-radius: var(--ifabsh-badge-border-radius, var(--ifabsh-border-radius, 0px));
  color: var(--ifabsh-badge-color, inherit);
  cursor: inherit;
  display: inline-flex;
  font-size: var(--ifabsh-badge-font-size, var(--ifabsh-font-size-s, 0.75rem));
  font-weight: var(--ifabsh-badge-font-weight, normal);
  justify-content: center;
  line-height: 1;
  padding: var(--ifabsh-badge-padding-y, var(--ifabsh-spacing-xs, 0.25rem)) var(--ifabsh-badge-padding-x, var(--ifabsh-spacing-s, 0.5rem));
  user-select: none;
  white-space: nowrap;
}
a.badge {
  pointer-events: all;
  text-decoration-line: none;
}
a.badge:link, a.badge:visited, a.badge:hover, a.badge:focus, a.badge:active {
  color: var(--ifabsh-badge-color);
}
a.badge:focus {
  box-shadow: none;
  outline: none;
}

.badge.-default {
  --ifabsh-badge-background-color: var(--ifabsh-color-grey, #e8e8e8);
  --ifabsh-badge-color: var(--ifabsh-color-text, #3e3e3e);
}

.badge.-primary {
  --ifabsh-badge-background-color: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-badge-color: var(--ifabsh-color-ui-contrast, #fff);
}

.badge.-danger {
  --ifabsh-badge-background-color: var(--ifabsh-color-danger, #fb3e4e);
  --ifabsh-badge-color: var(--ifabsh-color-danger-contrast, #fff);
}

.badge.-info {
  --ifabsh-badge-background-color: var(--ifabsh-color-info, #2db5cd);
  --ifabsh-badge-color: var(--ifabsh-color-info-contrast, #000);
}

.badge.-success {
  --ifabsh-badge-background-color: var(--ifabsh-color-success, #15c182);
  --ifabsh-badge-color: var(--ifabsh-color-success-contrast, #fff);
}

.badge.-warning {
  --ifabsh-badge-background-color: var(--ifabsh-color-warning, #fca311);
  --ifabsh-badge-color: var(--ifabsh-color-warning-contrast, #000);
}

.badge.-pill {
  --ifabsh-badge-border-radius: 10rem;
}

.badge.-outline {
  background-color: transparent;
}
.badge.-outline:not(.-default) {
  --ifabsh-badge-color: var(--ifabsh-badge-background-color);
}

@keyframes badge-pulse {
  0% {
    box-shadow: 0 0 0 0 var(--_badge-pulse-color);
  }
  70% {
    box-shadow: 0 0 0 var(--_badge-pulse-size) transparent;
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}
.badge.-pulse {
  --_badge-pulse-color: var(--ifabsh-badge-pulse-color, var(--ifabsh-badge-background-color));
  --_badge-pulse-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-badge-pulse-duration, 1.5s));
  --_badge-pulse-size: var(--ifabsh-badge-pulse-size, 0.5em);
  animation: badge-pulse var(--_badge-pulse-duration) infinite;
}

.badge-group {
  align-content: flex-start;
  display: inline-flex;
  flex-wrap: wrap;
  gap: var(--ifabsh-badge-group-gap, var(--ifabsh-spacing-s, 0.5rem));
}
.badge-group .badge {
  align-self: flex-start;
}

.badge {
  --ifabsh-badge-border-radius: 4px;
  --ifabsh-badge-border-width: 2px;
  --ifabsh-badge-font-size: var(--wp-root-font-size);
  --ifabsh-badge-font-weight: var(--wp-font-weight-bold);
}
.badge, .badge > * {
  line-height: 1.15;
}
.badge.-default, .badge.-grey {
  --ifabsh-badge-background-color: var(--wp-color-grey);
  --ifabsh-badge-border-color: var(--wp-color-grey);
  --ifabsh-badge-color: var(--wp-color-grey-contrast);
}
.badge.-default.-outline, .badge.-grey.-outline {
  --ifabsh-badge-color: var(--wp-color-text);
}
.badge.-white {
  --ifabsh-badge-background-color: var(--wp-color-white);
  --ifabsh-badge-border-color: var(--wp-color-white);
  --ifabsh-badge-color: var(--wp-color-black);
}
.badge.-pill {
  --ifabsh-badge-border-radius: 13px;
}
.badge.time {
  --ifabsh-badge-font-weight: var(--wp-font-weight-regular);
}

.badge.-primary {
  --ifabsh-badge-background-color: var(--wp-color-primary, #343434);
  --ifabsh-badge-border-color: var(--wp-color-primary, #343434);
  --ifabsh-badge-color: var(--wp-color-primary-contrast, #fff);
}
.badge.-primary.-outline {
  --ifabsh-badge-color: var(--wp-color-text, #fff);
}

.badge.-secondary {
  --ifabsh-badge-background-color: var(--wp-color-secondary, #82bb25);
  --ifabsh-badge-border-color: var(--wp-color-secondary, #82bb25);
  --ifabsh-badge-color: var(--wp-color-secondary-contrast, #000);
}
.badge.-secondary.-outline {
  --ifabsh-badge-color: var(--wp-color-text, #000);
}

.badge.-warning {
  --ifabsh-badge-background-color: var(--wp-color-warning, #fca311);
  --ifabsh-badge-border-color: var(--wp-color-warning, #fca311);
  --ifabsh-badge-color: var(--wp-color-warning-contrast, #000);
}
.badge.-warning.-outline {
  --ifabsh-badge-color: var(--wp-color-text, #000);
}

.badge.-grey-light {
  --ifabsh-badge-background-color: var(--wp-color-grey-light, #c3c3c3);
  --ifabsh-badge-border-color: var(--wp-color-grey-light, #c3c3c3);
  --ifabsh-badge-color: var(--wp-color-grey-light-contrast, #000);
}
.badge.-grey-light.-outline {
  --ifabsh-badge-color: var(--wp-color-text, #000);
}

.badge.-danger {
  --ifabsh-badge-background-color: var(--wp-color-danger, #fb3e4e);
  --ifabsh-badge-border-color: var(--wp-color-danger, #fb3e4e);
  --ifabsh-badge-color: var(--wp-color-danger-contrast, #fff);
}
.badge.-danger.-outline {
  --ifabsh-badge-color: var(--wp-color-text, #fff);
}

.badge.-success {
  --ifabsh-badge-background-color: var(--wp-color-success, #82bb25);
  --ifabsh-badge-border-color: var(--wp-color-success, #82bb25);
  --ifabsh-badge-color: var(--wp-color-success-contrast, #000);
}
.badge.-success.-outline {
  --ifabsh-badge-color: var(--wp-color-text, #000);
}

.badge.-info {
  --ifabsh-badge-background-color: var(--wp-color-info, #6176ff);
  --ifabsh-badge-border-color: var(--wp-color-info, #6176ff);
  --ifabsh-badge-color: var(--wp-color-info-contrast, #000);
}
.badge.-info.-outline {
  --ifabsh-badge-color: var(--wp-color-text, #000);
}

.breadcrumb {
  --ifabsh-breadcrumb-color: currentColor;
  --ifabsh-breadcrumb-font-weight: normal;
  --ifabsh-breadcrumb-gap-x: var(--ifabsh-spacing, 1rem);
  --ifabsh-breadcrumb-gap-y: calc(var(--ifabsh-breadcrumb-gap-x) / 2);
  --ifabsh-breadcrumb-prefix-suffix-gap: var(--ifabsh-spacing-s, 0.5rem);
  --ifabsh-breadcrumb-prefix-suffix-font-size: var(--ifabsh-font-size-s, 0.75rem);
  --_brcr-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-breadcrumb-duration, 0.15s));
  background-color: var(--ifabsh-breadcrumb-background-color, transparent);
  color: var(--ifabsh-breadcrumb-color);
  display: block;
  font-size: var(--ifabsh-breadcrumb-font-size, var(--ifabsh-font-size, 1rem));
  font-weight: var(--ifabsh-breadcrumb-font-weight);
  margin: 0;
  padding: var(--ifabsh-breadcrumb-padding-y, 0) var(--ifabsh-breadcrumb-padding-x, 0);
}
.breadcrumb-list {
  align-items: center;
  column-gap: var(--ifabsh-breadcrumb-gap-x);
  display: flex;
  flex-wrap: wrap;
  justify-content: var(--ifabsh-breadrumb-align, center);
  row-gap: var(--ifabsh-breadcrumb-gap-y);
}
.breadcrumb-item {
  align-items: center;
  display: inline-flex;
  white-space: nowrap;
}
.breadcrumb-item i,
.breadcrumb-item .icon {
  pointer-events: none;
  position: relative;
}
.breadcrumb-item__prefix, .breadcrumb-item__suffix {
  align-items: center;
  color: var(--ifabsh-breadcrumb-prefix-suffix-color, currentColor);
  display: flex;
  flex: 0 0 auto;
  font-size: var(--ifabsh-breadcrumb-prefix-suffix-font-size);
}
.breadcrumb-item__prefix:empty, .breadcrumb-item__suffix:empty {
  display: none;
}
.breadcrumb-item__prefix {
  margin-right: var(--ifabsh-breadcrumb-prefix-suffix-gap);
  order: 1;
}
.breadcrumb-item__suffix {
  margin-left: var(--ifabsh-breadcrumb-prefix-suffix-gap);
  order: 3;
}
.breadcrumb-item__label {
  order: 3;
}
.breadcrumb-item__label:is(a) {
  background: none;
  border: none;
  color: currentColor;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-weight: var(--ifabsh-breadcrumb-font-weight);
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  transition-duration: var(--_brcr-duration);
  transition-property: color;
  transition-timing-function: ease-in-out;
}
.breadcrumb-item__label:is(a):not([aria-current=page]):hover { /* stylelint-disable-line string-quotes */
  color: var(--ifabsh-breadcrumb-color-hover, var(--ifabsh-breadcrumb-color));
}
.breadcrumb-item__label:is(a):focus {
  box-shadow: none;
}
.breadcrumb-item__label:is(a)[aria-current=page] { /* stylelint-disable-line string-quotes */
  color: var(--ifabsh-breadcrumb-color-active, var(--ifabsh-color-ui, #6176ff));
}
.breadcrumb-item__label:is(span) {
  pointer-events: none;
}
.breadcrumb-item__separator {
  align-items: center;
  color: var(--ifabsh-breadcrumb-separator-color, currentColor);
  display: inline-flex;
  font-size: var(--ifabsh-breadcrumb-separator-font-size, var(--ifabsh-breadcrumb-prefix-suffix-font-size));
  margin: 0 0 0 var(--ifabsh-breadcrumb-gap-x);
  order: 4;
  user-select: none;
}
.breadcrumb-item__separator:empty::before {
  content: var(--ifabsh-breadcrumb-separator, "·"); /* stylelint-disable-line string-quotes */
}
.breadcrumb-item:last-of-type .breadcrumb-item__separator {
  display: none;
}
.breadcrumb-item::before {
  display: none;
}
.breadcrumb-item + .breadcrumb-item {
  padding: 0;
}

.breadcrumb {
  --ifabsh-breadcrumb-background-color: var(--wp-color-secondary);
  --ifabsh-breadcrumb-color: var(--wp-color-white);
  --ifabsh-breadcrumb-color-active: var(--ifabsh-breadcrumb-color);
  --ifabsh-breadcrumb-gap-x: var(--wp-spacing-s);
  --ifabsh-breadcrumb-padding-x: var(--wp-spacing);
  --ifabsh-breadcrumb-padding-y: calc(var(--wp-spacing-xs) * 3);
  --ifabsh-breadcrumb-separator: "|";
  --ifabsh-breadcrumb-separator-font-size: var(--wp-root-font-size);
  --wp-breadcrumb-border-radius: 0 0 calc(var(--wp-spacing-s) * 3) 0;
  border-radius: var(--wp-breadcrumb-border-radius);
  display: inline-flex;
}
.breadcrumb-item__label:is(a)[aria-current=page] + .breadcrumb-item__suffix {
  --ifabsh-breadcrumb-prefix-suffix-color: var(--ifabsh-breadcrumb-color-active);
}

.button {
  --ifabsh-button-background-color: var(--ifabsh-input-background-color, #fff);
  --ifabsh-button-border-color: var(--ifabsh-input-border-color, #e8e8e8);
  --ifabsh-button-border-width: var(--ifabsh-input-border-width, var(--ifabsh-border-width, 1px));
  --ifabsh-button-color: var(--ifabsh-input-color, #000);
  --ifabsh-button-duration: var(--ifabsh-duration, 0.15s);
  --ifabsh-button-font-size: var(--ifabsh-input-font-size, var(--ifabsh-font-size, 1rem));
  --ifabsh-button-height: var(--ifabsh-input-height, 40px);
  --ifabsh-button-line-height: calc(var(--ifabsh-button-height) - var(--ifabsh-button-border-width) * 2);
  --ifabsh-button-padding: var(--ifabsh-input-padding, var(--ifabsh-spacing, 1rem));
  --ifabsh-button-prefix-suffix-gap: calc(var(--ifabsh-button-font-size) / 2);
  --_btn-background-color: var(--ifabsh-button-background-color);
  --_btn-border-color: var(--ifabsh-button-border-color);
  --_btn-color: var(--ifabsh-button-color);
  --_btn-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-button-duration));
  --focus-outline-color: var(--_btn-border-color);
  align-items: stretch;
  background-color: var(--_btn-background-color);
  border: var(--ifabsh-button-border-width) solid var(--_btn-border-color);
  border-radius: var(--ifabsh-button-border-radius, var(--ifabsh-input-border-radius, var(--ifabsh-border-radius, 0px)));
  color: var(--_btn-color) !important; /* stylelint-disable-line declaration-no-important */
  display: inline-flex;
  font-size: var(--ifabsh-button-font-size);
  font-style: var(--ifabsh-button-font-style, normal);
  font-weight: var(--ifabsh-button-font-weight, normal);
  gap: var(--ifabsh-button-prefix-suffix-gap);
  height: var(--ifabsh-button-height);
  justify-content: center;
  line-height: var(--ifabsh-button-line-height);
  max-width: 100%;
  min-width: var(--ifabsh-button-height);
  overflow: hidden;
  padding: 0 var(--ifabsh-button-padding);
  text-decoration-line: none;
  text-transform: var(--ifabsh-button-text-transform, var(--ifabsh-input-text-transform, none));
  transition-duration: var(--_btn-duration);
  transition-property: background-color, border-color, color;
  transition-timing-function: ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
}
.button i,
.button .icon,
.button svg {
  pointer-events: none;
  position: relative;
}
.button__label {
  align-items: center;
  order: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.button__prefix, .button__suffix, .button__caret {
  align-items: center;
  color: var(--ifabsh-button-prefix-suffix-color, currentColor);
  display: flex;
  flex: 0 0 auto;
}
.button__prefix {
  order: 1;
}
.button__suffix {
  order: 3;
}
.button__caret {
  order: 4;
}
@media (hover: hover) {
  .button:hover {
    --_btn-background-color: var(--ifabsh-button-background-color-hover, var(--ifabsh-button-background-color));
    --_btn-border-color: var(--ifabsh-button-border-color-hover, var(--ifabsh-button-border-color));
    --_btn-color: var(--ifabsh-button-color-hover, var(--ifabsh-button-color));
  }
  .button:hover:not(.-text) {
    text-decoration-line: none;
  }
}
.button:focus, .button._focus {
  --_btn-background-color: var(--ifabsh-button-background-color-focus, var(--ifabsh-button-background-color));
  --_btn-border-color: var(--ifabsh-button-border-color-focus, var(--ifabsh-button-border-color));
  --_btn-color: var(--ifabsh-button-color-focus, var(--ifabsh-button-color));
  box-shadow: none;
  outline: none;
}
.button:focus:not(.-text), .button._focus:not(.-text) {
  text-decoration-line: none;
}
.button._focus-visible:focus, .button:focus-visible:focus {
  outline: var(--ifabsh-focus-outline-width, 1px) var(--ifabsh-focus-outline-style, dotted) var(--focus-outline-color, currentColor);
  outline-offset: var(--ifabsh-focus-outline-offset, 2px);
}
.button._active {
  --_btn-background-color: var(--ifabsh-button-background-color-active, var(--ifabsh-button-background-color));
  --_btn-border-color: var(--ifabsh-button-border-color-active, var(--ifabsh-button-border-color));
  --_btn-color: var(--ifabsh-button-color-active, var(--ifabsh-button-color));
}
.button:disabled, .button[disabled], .button._disabled {
  cursor: not-allowed !important; /* stylelint-disable-line declaration-no-important */
  pointer-events: none;
  opacity: var(--ifabsh-button-disabled-opacity, var(--ifabsh-input-disabled-opacity, var(--ifabsh-disabled-opacity, 0.4))) !important; /* stylelint-disable-line declaration-no-important */
}
.button:disabled *, .button[disabled] *, .button._disabled * {
  pointer-events: none;
}

.button.-default {
  --ifabsh-button-background-color: var(--ifabsh-color-grey, #e8e8e8);
  --ifabsh-button-border-color: var(--ifabsh-color-grey, #e8e8e8);
  --ifabsh-button-color: var(--ifabsh-color-text, #3e3e3e);
}

.button.-primary {
  --ifabsh-button-background-color: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-button-border-color: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-button-color: var(--ifabsh-color-ui-contrast, #fff);
}

.button.-info {
  --ifabsh-button-background-color: var(--ifabsh-color-info, #2db5cd);
  --ifabsh-button-border-color: var(--ifabsh-color-info, #2db5cd);
  --ifabsh-button-color: var(--ifabsh-color-info-contrast, #000);
}

.button.-success {
  --ifabsh-button-background-color: var(--ifabsh-color-success, #15c182);
  --ifabsh-button-border-color: var(--ifabsh-color-success, #15c182);
  --ifabsh-button-color: var(--ifabsh-color-success-contrast, #fff);
}

.button.-warning {
  --ifabsh-button-background-color: var(--ifabsh-color-warning, #fca311);
  --ifabsh-button-border-color: var(--ifabsh-color-warning, #fca311);
  --ifabsh-button-color: var(--ifabsh-color-warning-contrast, #000);
}

.button.-danger {
  --ifabsh-button-background-color: var(--ifabsh-color-danger, #fb3e4e);
  --ifabsh-button-border-color: var(--ifabsh-color-danger, #fb3e4e);
  --ifabsh-button-color: var(--ifabsh-color-danger-contrast, #fff);
}

.button.-l {
  --ifabsh-button-font-size: var(--ifabsh-input-font-size-l, var(--ifabsh-font-size-l, 1.25rem));
  --ifabsh-button-height: var(--ifabsh-input-height-l, 50px);
  --ifabsh-button-padding: var(--ifabsh-input-padding-l, var(--ifabsh-spacing-l, 1.25rem));
}

.button.-s {
  --ifabsh-button-font-size: var(--ifabsh-input-font-size-s, var(--ifabsh-font-size-s, 0.75rem));
  --ifabsh-button-height: var(--ifabsh-input-height-s, 30px);
  --ifabsh-button-padding: var(--ifabsh-input-padding-s, var(--ifabsh-spacing-s, 0.5rem));
}

.button.-pill, .button {
  border-radius: var(--ifabsh-button-height);
}

.button.-outline {
  --_btn-color: var(--ifabsh-button-border-color);
  --focus-outline-color: var(--_btn-color);
  background-color: transparent;
}
@media (hover: hover) {
  .button.-outline:hover {
    --_btn-border-color: var(--ifabsh-button-border-color-hover, var(--ifabsh-button-border-color));
    --_btn-color: var(--ifabsh-button-border-color-hover, var(--ifabsh-button-border-color));
    background-color: transparent;
  }
}
.button.-outline:focus, .button.-outline._focus {
  --_btn-border-color: var(--ifabsh-button-border-color-hover, var(--ifabsh-button-border-color));
  --_btn-color: var(--ifabsh-button-border-color-hover, var(--ifabsh-button-border-color));
  background-color: transparent;
}
.button.-outline._active {
  --_btn-border-color: var(--ifabsh-button-border-color-active, var(--ifabsh-button-border-color));
  --_btn-color: var(--ifabsh-button-border-color-active, var(--ifabsh-button-border-color));
  background-color: transparent;
}

.button.-square,
.button.-circle {
  --ifabsh-button-padding: 0;
  width: var(--ifabsh-button-height);
}
.button.-square .button__prefix, .button.-square .button__suffix,
.button.-circle .button__prefix,
.button.-circle .button__suffix {
  display: none;
}
.button.-circle {
  border-radius: 50%;
}

.button.-text {
  --_btn-background-color: transparent;
  --_btn-border-color: transparent;
  --_btn-color: inherit;
  --focus-outline-color: currentColor;
}

.button[data-caret] {
  --_btn-caret-size: var(--ifabsh-button-caret-size, 1em);
}
.button[data-caret] .button__suffix {
  display: none;
}
.button[data-caret] .button__caret svg {
  height: var(--_btn-caret-size);
  width: var(--_btn-caret-size);
}

.button[data-loading] {
  cursor: wait;
  pointer-events: none;
  position: relative;
}
.button > .spinner {
  display: none;
}

.button[data-loading] .button__label, .button[data-loading] .button__prefix, .button[data-loading] .button__suffix {
  visibility: hidden;
}
.button[data-loading] > .spinner {
  --_sp-pos: calc(50% - var(--ifabsh-spinner-size) / 2);
  display: inline-block;
  left: var(--_sp-pos);
  position: absolute;
  top: var(--_sp-pos);
  z-index: 5;
}

.button {
  --ifabsh-button-border-radius: 4px;
  --ifabsh-button-border-width: 2px;
  --ifabsh-button-height: 50px;
  --ifabsh-button-padding: calc(var(--wp-spacing-s) * 3);
  --ifabsh-button-font-weight: var(--wp-font-weight-bold);
}

.button.-primary {
  --ifabsh-button-background-color: var(--wp-color-primary, #343434);
  --ifabsh-button-border-color: var(--wp-color-primary, #343434);
  --ifabsh-button-color: var(--wp-color-primary-contrast, #fff);
}
.button.-primary.-outline {
  --ifabsh-button-color: var(--wp-color-text);
  --_btn-color: var(--ifabsh-button-color);
}
.button.-primary:hover:not(.-outline), .button.-primary:focus:not(.-outline) {
  --ifabsh-button-background-color: var(--wp-color-primary-darken, #343434);
  --ifabsh-button-border-color: var(--wp-color-primary-darken, #343434);
}
.button.-primary:hover.-outline, .button.-primary:focus.-outline {
  --ifabsh-button-border-color: var(--wp-color-primary, #343434);
  --_btn-color: var(--wp-color-primary-contrast, #fff);
  background-color: var(--wp-color-primary, #343434);
}

.button.-secondary {
  --ifabsh-button-background-color: var(--wp-color-secondary, #82bb25);
  --ifabsh-button-border-color: var(--wp-color-secondary, #82bb25);
  --ifabsh-button-color: var(--wp-color-secondary-contrast, #000);
}
.button.-secondary.-outline {
  --ifabsh-button-color: var(--wp-color-text);
  --_btn-color: var(--ifabsh-button-color);
}
.button.-secondary:hover:not(.-outline), .button.-secondary:focus:not(.-outline) {
  --ifabsh-button-background-color: var(--wp-color-secondary-darken, #82bb25);
  --ifabsh-button-border-color: var(--wp-color-secondary-darken, #82bb25);
}
.button.-secondary:hover.-outline, .button.-secondary:focus.-outline {
  --ifabsh-button-border-color: var(--wp-color-secondary, #82bb25);
  --_btn-color: var(--wp-color-secondary-contrast, #000);
  background-color: var(--wp-color-secondary, #82bb25);
}

.button.-grey-light {
  --ifabsh-button-background-color: var(--wp-color-grey-light, #c3c3c3);
  --ifabsh-button-border-color: var(--wp-color-grey-light, #c3c3c3);
  --ifabsh-button-color: var(--wp-color-grey-light-contrast, #000);
}
.button.-grey-light.-outline {
  --ifabsh-button-color: var(--wp-color-text);
  --_btn-color: var(--ifabsh-button-color);
}
.button.-grey-light:hover:not(.-outline), .button.-grey-light:focus:not(.-outline) {
  --ifabsh-button-background-color: var(--wp-color-grey-light-darken, #c3c3c3);
  --ifabsh-button-border-color: var(--wp-color-grey-light-darken, #c3c3c3);
}
.button.-grey-light:hover.-outline, .button.-grey-light:focus.-outline {
  --ifabsh-button-border-color: var(--wp-color-grey-light, #c3c3c3);
  --_btn-color: var(--wp-color-grey-light-contrast, #000);
  background-color: var(--wp-color-grey-light, #c3c3c3);
}

.button.-info {
  --ifabsh-button-background-color: var(--wp-color-info, #6176ff);
  --ifabsh-button-border-color: var(--wp-color-info, #6176ff);
  --ifabsh-button-color: var(--wp-color-info-contrast, #000);
}
.button.-info.-outline {
  --ifabsh-button-color: var(--wp-color-text);
  --_btn-color: var(--ifabsh-button-color);
}
.button.-info:hover:not(.-outline), .button.-info:focus:not(.-outline) {
  --ifabsh-button-background-color: var(--wp-color-info-darken, #6176ff);
  --ifabsh-button-border-color: var(--wp-color-info-darken, #6176ff);
}
.button.-info:hover.-outline, .button.-info:focus.-outline {
  --ifabsh-button-border-color: var(--wp-color-info, #6176ff);
  --_btn-color: var(--wp-color-info-contrast, #000);
  background-color: var(--wp-color-info, #6176ff);
}

.button.-warning {
  --ifabsh-button-background-color: var(--wp-color-warning, #fca311);
  --ifabsh-button-border-color: var(--wp-color-warning, #fca311);
  --ifabsh-button-color: var(--wp-color-warning-contrast, #000);
}
.button.-warning.-outline {
  --ifabsh-button-color: var(--wp-color-text);
  --_btn-color: var(--ifabsh-button-color);
}
.button.-warning:hover:not(.-outline), .button.-warning:focus:not(.-outline) {
  --ifabsh-button-background-color: var(--wp-color-warning-darken, #fca311);
  --ifabsh-button-border-color: var(--wp-color-warning-darken, #fca311);
}
.button.-warning:hover.-outline, .button.-warning:focus.-outline {
  --ifabsh-button-border-color: var(--wp-color-warning, #fca311);
  --_btn-color: var(--wp-color-warning-contrast, #000);
  background-color: var(--wp-color-warning, #fca311);
}

.button.-danger {
  --ifabsh-button-background-color: var(--wp-color-danger, #fb3e4e);
  --ifabsh-button-border-color: var(--wp-color-danger, #fb3e4e);
  --ifabsh-button-color: var(--wp-color-danger-contrast, #fff);
}
.button.-danger.-outline {
  --ifabsh-button-color: var(--wp-color-text);
  --_btn-color: var(--ifabsh-button-color);
}
.button.-danger:hover:not(.-outline), .button.-danger:focus:not(.-outline) {
  --ifabsh-button-background-color: var(--wp-color-danger-darken, #fb3e4e);
  --ifabsh-button-border-color: var(--wp-color-danger-darken, #fb3e4e);
}
.button.-danger:hover.-outline, .button.-danger:focus.-outline {
  --ifabsh-button-border-color: var(--wp-color-danger, #fb3e4e);
  --_btn-color: var(--wp-color-danger-contrast, #fff);
  background-color: var(--wp-color-danger, #fb3e4e);
}

.button.-success {
  --ifabsh-button-background-color: var(--wp-color-success, #82bb25);
  --ifabsh-button-border-color: var(--wp-color-success, #82bb25);
  --ifabsh-button-color: var(--wp-color-success-contrast, #000);
}
.button.-success.-outline {
  --ifabsh-button-color: var(--wp-color-text);
  --_btn-color: var(--ifabsh-button-color);
}
.button.-success:hover:not(.-outline), .button.-success:focus:not(.-outline) {
  --ifabsh-button-background-color: var(--wp-color-success-darken, #82bb25);
  --ifabsh-button-border-color: var(--wp-color-success-darken, #82bb25);
}
.button.-success:hover.-outline, .button.-success:focus.-outline {
  --ifabsh-button-border-color: var(--wp-color-success, #82bb25);
  --_btn-color: var(--wp-color-success-contrast, #000);
  background-color: var(--wp-color-success, #82bb25);
}

.button.-default, .button.-grey {
  --ifabsh-button-background-color: var(--wp-color-grey);
  --ifabsh-button-border-color: var(--wp-color-grey);
  --ifabsh-button-color: var(--wp-color-grey-contrast);
}
.button.-default.-outline, .button.-grey.-outline {
  --ifabsh-button-color: var(--wp-color-grey);
  --_btn-color: var(--ifabsh-button-color);
}
.button.-default:hover:not(.-outline), .button.-default:focus:not(.-outline), .button.-grey:hover:not(.-outline), .button.-grey:focus:not(.-outline) {
  --ifabsh-button-background-color: var(--wp-color-grey-darken);
  --ifabsh-button-border-color: var(--wp-color-grey-darken);
}
.button.-default:hover.-outline, .button.-default:focus.-outline, .button.-grey:hover.-outline, .button.-grey:focus.-outline {
  --ifabsh-button-border-color: var(--wp-color-grey);
  --_btn-color: var(--wp-color-grey-contrast);
  background-color: var(--wp-color-grey);
}

.button.-s {
  --ifabsh-button-height: 40px;
}
.button.-s:not(.-circle):not(.-square) {
  --ifabsh-button-padding: var(--wp-spacing);
}

.button.-pill, .button {
  --ifabsh-button-border-radius: 25px;
}

.button:focus-within {
  --_btn-color: var(--wp-color-white) !important;
  --ifabsh-button-color: var(--wp-color-white) !important;
  --ifabsh-button-background-color: var(--wp-color-black) !important;
  --ifabsh-button-border-color: var(--wp-color-black) !important;
  background-color: var(--wp-color-black) !important;
}

.list {
  --ifabsh-list-marker-color: var(--ifabsh-color-text, #3e3e3e);
  --ifabsh-list-marker-font-size: 1em;
  --ifabsh-list-marker-gap: var(--ifabsh-spacing-xs, 0.25rem);
  --ifabsh-list-item-margin: var(--ifabsh-spacing-s, 0.5rem);
  --ifabsh-list-nested-margin: var(--ifabsh-spacing-s, 0.5rem);
}
.list,
.list ul,
.list ol {
  padding: 0;
}
.list li {
  list-style-type: none;
  margin: var(--ifabsh-list-item-margin) 0;
  position: relative;
}
.list li:first-child {
  margin-top: 0;
}
.list li:last-child {
  margin-bottom: 0;
}
.list li ul,
.list li ol {
  margin: var(--ifabsh-list-nested-margin) 0;
}

ul.list {
  --ifabsh-ul-marker-color: var(--ifabsh-list-marker-color);
  --ifabsh-ul-marker-font-size: var(--ifabsh-list-marker-font-size);
  --ifabsh-ul-marker-gap: var(--ifabsh-list-marker-gap);
  --ifabsh-ul-marker-icon: "•"; /* stylelint-disable-line string-quotes */
  --ifabsh-ul-nested-marker-font-size: var(--ifabsh-ul-marker-font-size);
  --ifabsh-ul-nested-marker-gap: var(--ifabsh-ul-marker-gap);
}
ul.list > li,
ul.list ul > li {
  --_ul-marker-gap: calc(var(--ifabsh-ul-marker-font-size) + var(--ifabsh-ul-marker-gap));
  padding-left: var(--_ul-marker-gap);
}
ul.list > li::before,
ul.list ul > li::before {
  color: var(--_ul-marker-color, var(--ifabsh-ul-marker-color));
  content: var(--_ul-marker-icon, var(--ifabsh-ul-marker-icon));
  display: block;
  font-size: var(--_ul-marker-font-size, var(--ifabsh-ul-marker-font-size));
  left: 0;
  overflow: hidden;
  position: absolute;
}
ul.list ul > li {
  --_ul-marker-color: var(--ifabsh-ul-nested-marker-color, var(--ifabsh-ul-marker-color));
  --_ul-marker-font-size: var(--ifabsh-ul-nested-marker-font-size, var(--ifabsh-ul-marker-font-size));
  --_ul-marker-icon: var(--ifabsh-ul-nested-marker-icon, var(--ifabsh-ul-marker-icon));
  --_ul-marker-gap: calc(var(--ifabsh-ul-nested-marker-font-size) + var(--ifabsh-ul-nested-marker-gap));
}

ol.list {
  --ifabsh-ol-marker-color: var(--ifabsh-list-marker-color);
  --ifabsh-ol-marker-font-size: var(--ifabsh-list-marker-font-size);
  --ifabsh-ol-marker-gap: var(--ifabsh-list-marker-gap);
  --_ol-nested-index: 0.0; /* stylelint-disable-line number-no-trailing-zeros */
  --_ol-nested-index-add: 0.0; /* stylelint-disable-line number-no-trailing-zeros */
}
ol.list,
ol.list ol {
  counter-reset: count-ol;
}
ol.list > li,
ol.list ol > li {
  counter-increment: count-ol;
  display: flex;
  flex-wrap: nowrap;
  gap: var(--ifabsh-ol-marker-gap);
  justify-content: flex-start;
}
ol.list > li::before,
ol.list ol > li::before {
  color: var(--_ol-marker-color, var(--ifabsh-ol-marker-color));
  content: counters(count-ol, ".") ". "; /* stylelint-disable-line string-quotes */
  display: flex;
  flex: 0 0 auto;
  font-size: var(--_ol-marker-font-size, var(--ifabsh-ol-marker-font-size));
  text-align: left;
}
ol.list ol > li {
  --_ol-marker-color: var(--ifabsh-ol-nested-marker-color, var(--ifabsh-ol-marker-color));
  --_ol-marker-font-size: var(--ifabsh-ol-nested-marker-font-size, var(--ifabsh-ol-marker-font-size));
}

.list {
  --ifabsh-list-item-margin: var(--wp-spacing-s);
}
.list.-unstyled li {
  padding: 0;
}
.list.-unstyled li::before {
  display: none;
}

ul.list {
  --ifabsh-ul-marker-color: var(--wp-color-primary);
  --ifabsh-ul-marker-font-size: 0.35rem;
  --ifabsh-ul-marker-icon: "";
  --ifabsh-ul-marker-gap: calc(var(--wp-spacing-s) * 3);
  /* stylelint-disable string-quotes, font-family-no-missing-generic-family-keyword */
  /* stylelint-enable */
}
ul.list li::before {
  font-family: "Font Awesome 5 Pro";
  font-weight: var(--wp-font-weight-bold);
  top: 0.55rem;
}
ul.list ul.list {
  --ifabsh-ul-marker-icon: "";
}

ol.list {
  --ifabsh-ol-marker-color: var(--wp-color-secondary);
  --ifabsh-ol-marker-font-size: var(--wp-font-size-xs);
  --ifabsh-ol-marker-gap: var(--wp-spacing);
  --wp-ol-marker-background: var(--wp-color-primary);
  --wp-ol-marker-border-radius: 50%;
  --wp-ol-marker-height: 26px;
  --wp-ol-marker-width: var(--wp-ol-marker-height);
}
ol.list li::before {
  align-content: center;
  background: var(--wp-ol-marker-background, transparent);
  border-radius: var(--wp-ol-marker-border-radius, 0);
  font-weight: var(--wp-font-weight-bold);
  height: var(--wp-ol-marker-height);
  justify-content: flex-end;
  position: relative;
  top: 0.125rem;
  width: var(--wp-ol-marker-width);
}
ol.list ol.list li::before {
  --wp-ol-marker-background: transparent;
  --wp-ol-marker-border-radius: 0;
  --wp-ol-marker-height: auto;
  --wp-ol-marker-width: auto;
}

dl.list {
  --wp-dl-gap-y: var(--wp-spacing);
  --wp-dl-gap-x: var(--wp-spacing-l);
  --wp-dl-padding: 0;
  display: grid;
  flex-wrap: wrap;
  gap: var(--wp-dl-gap-y, 0) var(--wp-dl-gap-x, 0);
  grid-template-columns: min-content auto;
  padding: var(--wp-dl-padding);
}
dl.list dt + dd {
  align-items: center;
  display: inline-flex;
}
dl.list dt + dd::before {
  background: var(--wp-color-primary);
  content: "";
  display: inline-block;
  height: 2px;
  margin: 0 var(--wp-spacing-s);
  width: 0;
}
dl.list dt,
dl.list dd {
  margin-bottom: 0;
}

.deck {
  --ifabsh-deck-border-color: transparent;
  --ifabsh-deck-border-radius: var(--ifabsh-border-radius, 0px);
  --ifabsh-deck-border-width: 0px; /* stylelint-disable-line length-zero-no-unit */
  --ifabsh-deck-gap-column: var(--ifabsh-deck-gap, var(--ifabsh-spacing, 1rem));
  --ifabsh-deck-gap-row: var(--ifabsh-deck-gap-column);
  background-color: var(--ifabsh-deck-background-color, transparent);
  border: var(--ifabsh-deck-border-width) solid var(--ifabsh-deck-border-color);
  border-radius: var(--ifabsh-deck-border-radius);
  padding: var(--ifabsh-deck-padding-y, 0) var(--ifabsh-deck-padding-x, 0);
}
.deck__heading {
  grid-column: 1/-1;
}
.deck__container {
  display: grid;
  grid-gap: var(--ifabsh-deck-gap-row) var(--ifabsh-deck-gap-column);
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto;
}
.deck__container > * {
  margin: 0 !important; /* stylelint-disable-line declaration-no-important */
  width: 100%;
}
.deck__container > * + * {
  margin: 0;
}
@media (min-width: 768px) {
  .deck {
    /* stylelint-disable string-quotes */
    /* stylelint-enable string-quotes */
  }
  .deck[data-columns="2"] > .deck__container, .deck[data-columns="3"] > .deck__container, .deck[data-columns="4"] > .deck__container {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}
@media (min-width: 1200px) {
  .deck {
    /* stylelint-disable string-quotes */
    /* stylelint-enable string-quotes */
  }
  .deck[data-columns="3"] > .deck__container {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
  .deck[data-columns="4"] > .deck__container {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}

.deck {
  --ifabsh-deck-gap-column: var(--wp-grid-gap-x);
  --ifabsh-deck-gap-row: var(--wp-grid-gap-y);
}

.deck.-bordered .deck__container > *, .deck.-filled .deck__container > * {
  padding: calc(var(--wp-spacing-s) * 3 + var(--wp-spacing-xs));
}
.deck.-bordered .deck__container > * {
  border: 3px solid var(--wp-color-grey);
}
.deck.-filled .deck__container > * {
  background: var(--wp-color-grey);
}
.deck.-filled .deck__container > * a {
  border-bottom: 2px solid var(--wp-color-primary);
  font-weight: var(--wp-font-weight-semibold);
}

.events-group[data-module-user=education] .deck__heading.-future, .events-group[data-module-user=education] .deck__heading.-done {
  border-top: var(--ifabsh-deck-heading-border-width, 1px) solid var(--ifabsh-deck-heading-border-color, currentColor);
  margin-top: var(--ifabsh-deck-heading-margin, var(--ifabsh-spacing-l)) !important;
  padding-top: var(--ifabsh-deck-heading-margin, var(--ifabsh-spacing-l)) !important;
}
.events-group[data-module-user=education] .deck__heading.-active {
  border-top: none !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.details {
  --ifabsh-details-background-color: transparent;
  --ifabsh-details-border-color: var(--ifabsh-color-border, #e8e8e8);
  --ifabsh-details-border-radius: var(--ifabsh-border-radius, 0px);
  --ifabsh-details-border-width: var(--ifabsh-border-width, 1px);
  --ifabsh-details-padding-x: var(--ifabsh-spacing, 1rem);
  --ifabsh-details-padding-y: var(--ifabsh-spacing, 1rem);
  --ifabsh-details-duration: var(--ifabsh-duration, 0.15s);
  --ifabsh-details-toggle-color: var(--ifabsh-color-text, #3e3e3e);
  --ifabsh-details-toggle-color-active: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-details-toggle-padding-x: var(--ifabsh-details-padding-x);
  --ifabsh-details-toggle-padding-y: var(--ifabsh-details-padding-y);
  --_de-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-details-duration));
  background-color: var(--ifabsh-details-background-color);
  border: var(--ifabsh-details-border-width) solid var(--ifabsh-details-border-color);
  border-radius: var(--ifabsh-details-border-radius);
  overflow-anchor: auto;
}

.details__header {
  align-items: center;
  background-color: transparent;
  border: 0 none;
  border-radius: inherit;
  color: var(--ifabsh-details-toggle-color-active);
  cursor: pointer;
  display: flex;
  font-size: var(--ifabsh-details-toggle-font-size, 1rem);
  gap: var(--ifabsh-details-toggle-gap, 1em);
  height: var(--ifabsh-details-toggle-height, auto);
  line-height: normal;
  padding: var(--ifabsh-details-toggle-padding-y, var(--ifabsh-details-padding-y)) var(--ifabsh-details-toggle-padding-x, var(--ifabsh-details-padding-x));
  text-align: left;
  transition: all var(--_de-duration) ease-in-out;
  user-select: none;
  width: 100%;
}
.details__header-summary {
  align-items: center;
  flex: 1 1 auto;
}
.details__header-icon {
  align-items: center;
  color: var(--ifabsh-details-toggle-icon-color-active, var(--ifabsh-details-toggle-color-active));
  display: flex;
  flex: 0 0 auto;
  font-size: var(--ifabsh-details-toggle-icon-size, 0.5em);
  height: 1em;
  position: relative;
  transform: rotate(135deg);
  transition: all var(--_de-duration) ease-in-out;
  width: 1em;
}
.details__header-icon::before {
  border: var(--ifabsh-details-toggle-icon-stroke, 1px) solid currentColor;
  border-bottom: 0;
  border-left: 0;
  content: ""; /* stylelint-disable-line string-quotes */
  height: 1em;
  width: 1em;
}
.details__header[aria-expanded=false] {
  color: var(--ifabsh-details-toggle-color);
}
.details__header[aria-expanded=false] .details__header-icon {
  color: var(--ifabsh-details-toggle-icon-color, var(--ifabsh-details-toggle-color));
  transform: rotate(45deg);
}
.details__header:focus {
  box-shadow: none;
  outline: none;
}
[dir=rtl] .details__header {
  text-align: right;
}
[dir=rtl] .details__header[aria-expanded=false] .details__header-icon {
  transform: rotate(225deg);
}

.details__body {
  overflow: hidden;
}
.details__body.collapse {
  display: none;
}
.details__body.collapse.show {
  display: block;
}
.details__body.collapsing {
  height: 0;
  overflow: hidden;
  position: relative;
  transition: height var(--_de-duration) ease-in-out;
}
.details__body.show {
  display: block;
}

.details__content {
  padding: var(--ifabsh-details-panel-padding-y, var(--ifabsh-details-padding-y)) var(--ifabsh-details-panel-padding-x, var(--ifabsh-details-padding-x));
}
.details__content > *:first-child:not(div) {
  margin-top: 0;
}
.details__content > *:last-child:not(div) {
  margin-bottom: 0;
}

.details-group {
  --ifabsh-details-group-gap: var(--ifabsh-spacing-s, 0.5rem);
}
.details-group .details + .details {
  margin-top: var(--ifabsh-details-group-gap);
}

.details {
  --ifabsh-details-border-color: transparent;
  --ifabsh-details-border-width: 0;
  --ifabsh-details-toggle-color: var(--wp-color-text);
  --ifabsh-details-toggle-color-active: var(--wp-color-primary-contrast);
  --ifabsh-details-toggle-icon-size: 1em;
  --ifabsh-details-toggle-padding-y: var(--wp-spacing-s);
  --ifabsh-details-toggle-height: 50px;
  --ifabsh-details-panel-padding-x: var(--wp-spacing-l);
  --ifabsh-details-panel-padding-y: var(--wp-spacing-l);
  --wp-details-toggle-background-color: transparent;
  --wp-details-toggle-background-color-active: var(--wp-color-primary);
  --wp-details-toggle-border-color: var(--wp-color-primary);
  --wp-details-toggle-border-color-active: var(--wp-details-toggle-background-color-active);
  --wp-details-toggle-border-width: 2px;
}
.details__header {
  background: var(--wp-details-toggle-background-color-active, var(--wp-color-primary));
  border-width: var(--wp-details-toggle-border-width, 1px);
  border-style: solid;
  border-color: var(--wp-details-toggle-border-color-active, var(--wp-color-primary));
  color: var(--ifabsh-details-toggle-color-active, var(--wp-color-primary-contrast));
  font-weight: var(--wp-font-weight-bold);
  line-height: 1.4;
}
.details__header[aria-expanded=false] {
  background: var(--wp-details-toggle-background-color, var(--wp-color-primary));
  border-color: var(--wp-details-toggle-border-color, var(--wp-color-primary));
  color: var(--ifabsh-details-toggle-color, var(--wp-color-secondary));
}
.details__header[aria-expanded=false] .details__header-summary::before {
  color: var(--ifabsh-details-toggle-color, var(--wp-color-secondary));
}
.details__header[aria-expanded=false] .details__header-icon {
  transform: rotate(0);
}
.details__header-summary::before {
  color: var(--ifabsh-details-toggle-color-active, var(--wp-color-primary-contrast));
}
.details__header-icon {
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);
}
.details__header-icon::before {
  border: none;
  content: "\f054";
  font-family: "Font Awesome 6 Pro";
  height: auto;
  width: auto;
}

.details-group .details__content {
  padding-bottom: calc(var(--ifabsh-details-panel-padding-y) - var(--ifabsh-details-group-gap));
}

.card {
  --ifabsh-card-border-color: var(--ifabsh-color-border, #e8e8e8);
  --ifabsh-card-border-radius: var(--ifabsh-border-radius, 0px);
  --ifabsh-card-border-width: var(--ifabsh-border-width, 1px);
  --ifabsh-card-padding: var(--ifabsh-spacing, 1rem);
  --ifabsh-card-duration: var(--ifabsh-duration, 0.15s);
  --_cd-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-card-duration));
  background-color: var(--ifabsh-card-background-color, transparent);
  border: var(--ifabsh-card-border-width) solid var(--ifabsh-card-border-color);
  border-radius: var(--ifabsh-card-border-radius);
  display: flex;
  flex-direction: column;
  transition-duration: var(--_cd-duration);
  transition-property: border-color, box-shadow;
  transition-timing-function: ease-in-out;
}
.card .card-title { /* stylelint-disable-line string-quotes */
  color: var(--ifabsh-card-title-color, currentColor);
  -webkit-hyphens: manual;
  hyphens: manual;
  margin: 0;
  overflow-wrap: break-word;
}
.card__media {
  aspect-ratio: var(--ifabsh-card-media-aspect-ratio, auto);
  border-top-left-radius: var(--ifabsh-card-border-radius);
  border-top-right-radius: var(--ifabsh-card-border-radius);
  margin: calc(var(--ifabsh-card-border-width) * -1);
  overflow: hidden;
}
.card__media picture {
  aspect-ratio: var(--ifabsh-card-media-aspect-ratio);
}
.card__media .figure__caption {
  display: none;
}
.card__media > * {
  display: block;
  margin: 0;
  width: 100%;
}
.card__header, .card__body, .card__footer {
  transition-duration: var(--_cd-duration);
  transition-property: border-color;
  transition-timing-function: ease-in-out;
}
.card__header, .card__footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.card__header {
  border-bottom: var(--ifabsh-card-border-width) solid var(--ifabsh-card-border-color);
  flex-wrap: wrap;
  gap: calc(var(--ifabsh-card-padding) / 2);
  padding: var(--ifabsh-card-padding);
}
.card__header .card-title {
  width: auto;
}
.card__header .card-controls {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  margin-left: auto;
}
.card__body {
  flex-grow: 1;
  padding: var(--ifabsh-card-padding);
}
.card__body > * {
  margin: 0;
}
.card__body > * + * {
  margin-top: var(--ifabsh-card-content-gap, var(--ifabsh-spacing-l, 1.25rem));
}
.card__body > :first-child {
  margin-top: 0;
}
.card__body > :last-child {
  margin-bottom: 0;
}
.card__footer {
  border-top: var(--ifabsh-card-border-width) solid var(--ifabsh-card-border-color);
  padding: var(--ifabsh-card-padding);
}

.card {
  --ifabsh-card-background-color: var(--wp-color-white);
  --ifabsh-card-border-color: var(--wp-color-grey-light);
  --ifabsh-card-border-radius: var(--wp-spacing-s);
  --ifabsh-card-border-width: 1px;
}
.card.-primary {
  --ifabsh-card-background-color: var(--wp-color-primary-lighten);
  --ifabsh-card-border-color: var(--ifabsh-card-background-color);
  --ifabsh-card-border-width: 2px;
}
.card.-primary .card__body {
  background-color: var(--wp-color-white);
}

.drawer {
  --ifabsh-drawer-size: 25rem;
  --ifabsh-drawer-padding-x: var(--ifabsh-drawer-padding, var(--ifabsh-spacing, 1rem));
  --ifabsh-drawer-padding-y: var(--ifabsh-drawer-padding-x);
  --ifabsh-drawer-border-color: var(--ifabsh-color-border, #e8e8e8);
  --ifabsh-drawer-border-width: var(--ifabsh-border-width, 1px);
  --ifabsh-drawer-duration: 0.35s;
  --_dr-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-drawer-duration));
  backdrop-filter: var(--ifabsh-drawer-backdrop-filter, var(--ifabsh-backdrop-filter, none));
  display: none;
  height: 100%;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--ifabsh-drawer-zindex, 500);
}
.drawer__title {
  -webkit-hyphens: manual;
  hyphens: manual;
  margin: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.drawer__close {
  font-size: var(--ifabsh-drawer-close-font-size, inherit);
}
.drawer__header {
  align-items: center;
  border-bottom: var(--ifabsh-drawer-border-width) solid var(--ifabsh-drawer-border-color);
  color: var(--ifabsh-drawer-header-color, currentColor);
  display: flex;
  font-size: var(--ifabsh-drawer-header-font-size, inherit);
  gap: var(--ifabsh-spacing);
  padding: var(--ifabsh-drawer-padding-y) var(--ifabsh-drawer-padding-x);
}
.drawer__header .drawer__close {
  margin-inline-start: auto;
}
.drawer__body {
  flex: 1 1 auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
  padding: var(--ifabsh-drawer-padding-y) var(--ifabsh-drawer-padding-x);
  position: relative;
}
.drawer__footer {
  align-items: center;
  border-top: var(--ifabsh-drawer-border-width) solid var(--ifabsh-drawer-border-color);
  color: var(--ifabsh-drawer-footer-color, currentColor);
  display: flex;
  font-size: var(--ifabsh-drawer-footer-font-size, inherit);
  padding: var(--ifabsh-drawer-padding-y) var(--ifabsh-drawer-padding-x);
}
.drawer__panel {
  background-color: var(--ifabsh-drawer-background-color, var(--ifabsh-color-white, #fff));
  border: 0 solid var(--ifabsh-drawer-border-color);
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
  pointer-events: all;
  position: absolute;
  transition: var(--_dr-duration) all ease-in-out;
  z-index: 2;
}
.drawer__panel:focus {
  outline: none;
}
.drawer.-initialized {
  display: block;
}

.drawer__overlay {
  background-color: var(--ifabsh-drawer-backdrop-color, var(--ifabsh-backdrop-color, #000));
  bottom: 0;
  display: block;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: var(--_dr-duration) opacity ease-in-out;
}
.drawer.-open .drawer__overlay {
  opacity: var(--ifabsh-drawer-backdrop-opacity, var(--ifabsh-backdrop-opacity, 0.5));
  pointer-events: all;
}

.drawer.-contained {
  position: absolute;
  z-index: initial;
}
.drawer.-contained .drawer__overlay {
  position: absolute;
}

.contains-drawer {
  position: relative;
}

.drawer[data-placement=top] .drawer__panel { /* stylelint-disable-line string-quotes */
  border-bottom-width: var(--ifabsh-drawer-border-width);
  bottom: auto;
  height: var(--ifabsh-drawer-size);
  left: 0;
  right: auto;
  top: 0;
  transform: translate(0, -100%);
  width: 100%;
}

.drawer[data-placement=bottom] .drawer__panel { /* stylelint-disable-line string-quotes */
  border-top-width: var(--ifabsh-drawer-border-width);
  bottom: 0;
  height: var(--ifabsh-drawer-size);
  left: 0;
  right: auto;
  top: auto;
  transform: translate(0, 100%);
  width: 100%;
}

.drawer[data-placement=start] .drawer__panel { /* stylelint-disable-line string-quotes */
  border-right-width: var(--ifabsh-drawer-border-width);
  bottom: auto;
  height: 100%;
  left: 0;
  right: auto;
  top: 0;
  transform: translate(-100%, 0);
  width: var(--ifabsh-drawer-size);
}

.drawer[data-placement=end] .drawer__panel { /* stylelint-disable-line string-quotes */
  border-left-width: var(--ifabsh-drawer-border-width);
  bottom: auto;
  height: 100%;
  left: auto;
  right: 0;
  top: 0;
  transform: translate(100%, 0);
  width: var(--ifabsh-drawer-size);
}

.drawer[data-placement=center] .drawer__panel { /* stylelint-disable-line string-quotes */
  border-left-width: var(--ifabsh-drawer-border-width);
  bottom: auto;
  height: auto;
  left: 50%;
  opacity: 0;
  right: 0;
  top: 50%;
  transform: translate(-50%, 0%);
  width: var(--ifabsh-drawer-size);
}

.drawer.-open .drawer__panel {
  transform: translate(0, 0);
}

.drawer[data-placement=center].-open .drawer__panel {
  opacity: 1;
  transform: translate(-50%, -50%);
}

.drawer {
  --ifabsh-drawer-padding-y: var(--wp-spacing-l);
  max-height: calc(100% - var(--wp-drawer-page-offset-y));
  top: var(--wp-drawer-page-offset-y);
}
@media (min-width: 992px) {
  .drawer {
    --wp-drawer-page-offset-y: 120px;
  }
}
@media (min-width: 1200px) {
  .drawer {
    --wp-drawer-page-offset-y: 131px;
  }
}
.drawer__header, .drawer__body, .drawer__footer {
  --wp-page-max-width-padding: var(--wp-spacing);
  --wp-page-max-width-absolute: calc(var(--wp-page-max-width) + (var(--wp-page-max-width-padding) * 2));
  margin-left: auto;
  margin-right: auto;
  max-width: var(--wp-page-max-width-absolute);
  padding-left: var(--wp-page-max-width-padding);
  padding-right: var(--wp-page-max-width-padding);
  width: 100%;
}
@media (min-width: calc(1400px + 64px)) {
  .drawer__header, .drawer__body, .drawer__footer {
    --wp-page-max-width-padding: var(--wp-spacing-l);
  }
}
.drawer__header {
  border: none;
}
.drawer__header .drawer-title {
  margin: 0;
}
.drawer__body {
  background: var(--wp-drawer-body-background-color);
  color: var(--wp-drawer-body-color);
  --wp-scrollbar-track-color: transparent;
}
.drawer__body > :first-child:not(.row) {
  margin-top: 0;
}
.drawer__body > :last-child:not(.row) {
  margin-bottom: 0;
}
.drawer__body::-webkit-scrollbar {
  height: var(--wp-scrollbar-size, 4px);
  width: var(--wp-scrollbar-size, 4px);
}
.drawer__body::-webkit-scrollbar-thumb {
  background: var(--wp-scrollbar-color, #000);
}
.drawer__body::-webkit-scrollbar-track {
  background: var(--wp-scrollbar-track-color, transparent);
}
.drawer__body {
  scrollbar-face-color: var(--wp-scrollbar-color, #000);
  scrollbar-track-color: var(--wp-scrollbar-track-color, transparent);
}
.drawer__footer {
  border: none;
  flex: 0;
}
.drawer__close {
  --ifabsh-icon-button-font-size: 20px;
}
@media (min-width: 768px) {
  .drawer__close {
    --ifabsh-icon-button-font-size: 25px;
  }
}

.drawer.-full-width {
  --ifabsh-drawer-size: 100%;
}

.file {
  --ifabsh-file-color: var(--ifabsh-color-text, #3e3e3e);
  --ifabsh-file-color-hover: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-file-background-color: transparent;
  --ifabsh-file-background-color-hover: var(--ifabsh-file-background-color);
  --ifabsh-file-border-color: var(--ifabsh-color-border, #e8e8e8);
  --ifabsh-file-border-color-hover: var(--ifabsh-file-border-color);
  --ifabsh-file-border-radius: var(--ifabsh-border-radius, 0px);
  --ifabsh-file-border-width: var(--ifabsh-border-width, 1px);
  --ifabsh-file-gap: var(--ifabsh-spacing, 1rem);
  --ifabsh-file-padding-x: var(--ifabsh-spacing, 1rem);
  --ifabsh-file-padding-y: var(--ifabsh-spacing, 1rem);
  --ifabsh-file-duration: var(--ifabsh-duration, 0.15s);
  --_file-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-file-duration));
  --_file-color: var(--ifabsh-file-color);
  --_file-bg-color: var(--ifabsh-file-background-color);
  --_file-border-color: var(--ifabsh-file-border-color);
  --_file-desc-color: var(--ifabsh-file-description-color, var(--ifabsh-file-color));
  --_file-prefix-color: var(--ifabsh-file-prefix-color, var(--ifabsh-file-color));
  --_file-suffix-color: var(--ifabsh-file-suffix-color, var(--ifabsh-file-color));
  align-items: center;
  background-color: var(--_file-bg-color);
  border: var(--ifabsh-file-border-width) solid var(--_file-border-color);
  border-radius: var(--ifabsh-file-border-radius);
  color: var(--_file-color);
  display: flex;
  font-size: var(--ifabsh-file-font-size, 1rem);
  gap: var(--ifabsh-file-gap);
  justify-content: flex-start;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding: var(--ifabsh-file-padding-y) var(--ifabsh-file-padding-x);
  pointer-events: none;
  position: relative;
  transition: all var(--_file-duration) ease-in-out;
}
.file i,
.file .icon {
  pointer-events: none;
  position: relative;
}
.file i:not(.far):not(.fa):not(.fas):not(.fab):not(.fal),
.file .icon:not(.far):not(.fa):not(.fas):not(.fab):not(.fal) { /* stylelint-disable-line selector-not-notation */
  font-weight: normal;
}
@media (hover: hover) {
  .file:hover {
    --_file-color: var(--ifabsh-file-color-hover);
    --_file-bg-color: var(--ifabsh-file-background-color-hover);
    --_file-border-color: var(--ifabsh-file-border-color-hover);
    --_file-prefix-color: var(--ifabsh-file-prefix-color-hover, var(--ifabsh-file-color));
    --_file-desc-color: var(--ifabsh-file-description-color-hover, var(--ifabsh-file-color));
    --_file-suffix-color: var(--ifabsh-file-suffix-color-hover, var(--ifabsh-file-color));
  }
}
.file__label {
  align-self: center;
  color: currentColor;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: none;
  white-space: nowrap;
  z-index: 2;
}
.file__label .title {
  display: block;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.file__label .description {
  color: var(--_file-desc-color);
  display: block;
  font-size: var(--ifabsh-file-description-font-size, var(--ifabsh-font-size-xs, 0.5rem));
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
a.file__label {
  color: inherit;
  pointer-events: auto;
  text-decoration-line: none;
}
a.file__label::after {
  content: ""; /* stylelint-disable-line string-quotes */
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.file__prefix, .file__suffix {
  align-items: center;
  align-self: center;
  display: flex;
  flex: 0 0 auto;
}
.file__prefix {
  color: var(--_file-prefix-color);
  font-size: var(--ifabsh-file-prefix-font-size, 1em);
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  z-index: 1;
}
.file__prefix > i::before {
  content: "\f15b"; /* stylelint-disable-line string-quotes */
}
.file__suffix {
  color: var(--_file-suffix-color);
  font-size: var(--ifabsh-file-suffix-font-size, var(--ifabsh-font-size-xs, 0.5rem));
  margin-left: auto;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 3;
}
.file__suffix > * + *::before {
  content: ", "; /* stylelint-disable-line string-quotes */
}
.file:focus-within {
  outline: var(--ifabsh-focus-outline-width) var(--ifabsh-focus-outline-style) var(--focus-outline-color, var(--ifabsh-focus-outline-color));
  outline-offset: var(--ifabsh-focus-outline-offset);
}
.file:focus-within a.file__label, .file:focus-within a.file__label:focus {
  box-shadow: none;
  outline: none;
}

.file.-compact {
  --ifabsh-file-compact-gap: calc(var(--ifabsh-file-gap) / 2);
  --ifabsh-file-compact-padding-x: calc(var(--ifabsh-file-padding-x) / 2);
  --ifabsh-file-compact-padding-y: calc(var(--ifabsh-file-padding-y) / 2);
  gap: var(--ifabsh-file-compact-gap);
  padding: var(--ifabsh-file-compact-padding-y) var(--ifabsh-file-compact-padding-x);
}
.file.-compact .file__label .description {
  /* stylelint-disable declaration-no-important */
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
  /* stylelint-enable declaration-no-important */
}

.file-group {
  --ifabsh-file-group-gap: var(--ifabsh-spacing-s, 0.5rem);
}
.file-group .file + .file {
  margin-top: var(--ifabsh-file-group-gap);
}

.file-group.-stacked {
  --ifabsh-file-group-gap: 0;
}
.file-group.-stacked .file {
  z-index: 1;
}
.file-group.-stacked .file:hover {
  z-index: 2;
}
.file-group.-stacked .file:first-child:not(:last-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.file-group.-stacked .file:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.file-group.-stacked .file:not(:first-child):not(:last-child) { /* stylelint-disable-line selector-not-notation */
  border-radius: 0;
}
.file-group.-stacked .file:not(:first-child) {
  margin-top: calc(var(--ifabsh-file-border-width) * -1);
}

.file {
  --ifabsh-file-border-color: var(--wp-color-primary);
  --ifabsh-file-border-width: 2px;
  --ifabsh-file-color-hover: inherit;
  --ifabsh-file-padding-x: 0;
  --ifabsh-file-padding-y: calc(var(--wp-spacing-xs) * 3);
  --ifabsh-file-suffix-font-size: 1rem;
  --_file-prefix-color: var(--wp-color-secondary);
  border-left: 0;
  border-right: 0;
  border-top: 0;
  line-height: normal;
  padding-top: 0;
}
.file:not(:last-child) {
  margin-bottom: var(--ifabsh-file-padding-y);
}
.file__prefix > i {
  /* stylelint-disable string-quotes */
  /* stylelint-enable string-quotes */
}
.file__prefix > i::before {
  content: "\f33d" !important;
}
.file__prefix > i.-pdf::before {
  content: "\f1c1";
}
.file__prefix > i.-csv::before {
  content: "\f6dd";
}
.file__prefix > i.-txt::before {
  content: "\f15c";
}
.file__prefix > i[class*=-doc]::before, .file__prefix > i[class*=-dot]::before, .file__prefix > i.-odt::before {
  content: "\f1c2";
}
.file__prefix > i[class*=-xls]::before, .file__prefix > i[class*=-xlt]::before {
  content: "\f1c3";
}
.file__prefix > i[class*=-ppt]::before {
  content: "\f1c4";
}
.file__prefix > i[class*=-jp]::before, .file__prefix > i.-png::before, .file__prefix > i.-gif::before {
  content: "\f1c5";
}
.file__prefix > i.-vimeo::before, .file__prefix > i.-youtube::before, .file__prefix > i.-mp4::before {
  content: "\f1c8";
}
.file__prefix > i.-mp3::before, .file__prefix > i.-ogg::before, .file__prefix > i.-wav::before {
  content: "\f1c7";
}
.file__prefix > i.-ace::before, .file__prefix > i.-bin::before, .file__prefix > i.-cab::before, .file__prefix > i.-zip::before, .file__prefix > i.-rar::before {
  content: "\f1c6";
}
.file__suffix {
  margin-left: 0;
}
.file__suffix::before, .file__suffix::after {
  display: inline-block;
}
.file__suffix::before {
  content: "(";
}
.file__suffix::after {
  content: ")";
}
.file__suffix .size::before {
  display: none;
}
.file-group {
  --ifabsh-file-group-gap: var(--wp-spacing);
}

.link {
  --ifabsh-link-color: var(--ifabsh-color-text, #3e3e3e);
  --ifabsh-link-color-hover: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-link-background-color: transparent;
  --ifabsh-link-background-color-hover: var(--ifabsh-link-background-color);
  --ifabsh-link-border-color: var(--ifabsh-color-border, #e8e8e8);
  --ifabsh-link-border-color-hover: var(--ifabsh-link-border-color);
  --ifabsh-link-border-radius: var(--ifabsh-border-radius, 0px);
  --ifabsh-link-border-width: var(--ifabsh-border-width, 1px);
  --ifabsh-link-gap: var(--ifabsh-spacing, 1rem);
  --ifabsh-link-padding-x: var(--ifabsh-spacing, 1rem);
  --ifabsh-link-padding-y: var(--ifabsh-spacing, 1rem);
  --ifabsh-link-duration: var(--ifabsh-duration, 0.15s);
  --_link-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-link-duration));
  --_link-color: var(--ifabsh-link-color);
  --_link-bg-color: var(--ifabsh-link-background-color);
  --_link-border-color: var(--ifabsh-link-border-color);
  --_link-desc-color: var(--ifabsh-link-description-color, var(--ifabsh-link-color));
  --_link-prefix-color: var(--ifabsh-link-prefix-color, var(--ifabsh-link-color));
  --_link-suffix-color: var(--ifabsh-link-suffix-color, var(--ifabsh-link-color));
  align-items: center;
  background-color: var(--_link-bg-color);
  border: var(--ifabsh-link-border-width) solid var(--_link-border-color);
  border-radius: var(--ifabsh-link-border-radius);
  color: var(--_link-color);
  display: flex;
  font-size: var(--ifabsh-link-font-size, 1rem);
  gap: var(--ifabsh-link-gap);
  justify-content: flex-start;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding: var(--ifabsh-link-padding-y) var(--ifabsh-link-padding-x);
  position: relative;
  transition: all var(--_link-duration) ease-in-out;
}
.link i,
.link .icon {
  pointer-events: none;
  position: relative;
}
.link i:not(.far):not(.fa):not(.fas):not(.fab):not(.fal),
.link .icon:not(.far):not(.fa):not(.fas):not(.fab):not(.fal) { /* stylelint-disable-line selector-not-notation */
  font-weight: normal;
}
@media (hover: hover) {
  .link:hover {
    --_link-color: var(--ifabsh-link-color-hover);
    --_link-bg-color: var(--ifabsh-link-background-color-hover);
    --_link-border-color: var(--ifabsh-link-border-color-hover);
    --_link-prefix-color: var(--ifabsh-link-prefix-color-hover, var(--ifabsh-link-color));
    --_link-desc-color: var(--ifabsh-link-description-color-hover, var(--ifabsh-link-color));
    --_link-suffix-color: var(--ifabsh-link-suffix-color-hover, var(--ifabsh-link-color));
  }
}
.link__label {
  align-self: center;
  color: currentColor;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: none;
  white-space: nowrap;
  z-index: 2;
}
.link__label .title {
  display: block;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.link__label .description {
  color: var(--_link-desc-color);
  display: block;
  font-size: var(--ifabsh-link-description-font-size, var(--ifabsh-font-size-xs, 0.5rem));
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
a.link__label {
  color: inherit;
  pointer-events: auto;
  text-decoration-line: none;
}
a.link__label::after {
  content: ""; /* stylelint-disable-line string-quotes */
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.link__prefix, .link__suffix {
  align-items: center;
  align-self: center;
  display: flex;
  flex: 0 0 auto;
}
.link__prefix {
  color: var(--_link-prefix-color);
  font-size: var(--ifabsh-link-prefix-font-size, 1em);
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  z-index: 1;
}
.link__prefix > i::before {
  content: "\f15b"; /* stylelint-disable-line string-quotes */
}
.link__suffix {
  color: var(--_link-suffix-color);
  font-size: var(--ifabsh-link-suffix-font-size, var(--ifabsh-font-size-xs, 0.5rem));
  margin-left: auto;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 3;
}
.link__suffix > * + *::before {
  content: ", "; /* stylelint-disable-line string-quotes */
}
.link:focus-within {
  outline: var(--ifabsh-focus-outline-width) var(--ifabsh-focus-outline-style) var(--focus-outline-color, var(--ifabsh-focus-outline-color));
  outline-offset: var(--ifabsh-focus-outline-offset);
}
.link:focus-within a.link__label, .link:focus-within a.link__label:focus {
  box-shadow: none;
  outline: none;
}

.link.-compact {
  --ifabsh-link-compact-gap: calc(var(--ifabsh-link-gap) / 2);
  --ifabsh-link-compact-padding-x: calc(var(--ifabsh-link-padding-x) / 2);
  --ifabsh-link-compact-padding-y: calc(var(--ifabsh-link-padding-y) / 2);
  gap: var(--ifabsh-link-compact-gap);
  padding: var(--ifabsh-link-compact-padding-y) var(--ifabsh-link-compact-padding-x);
}
.link.-compact .link__label .description {
  /* stylelint-disable declaration-no-important */
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
  /* stylelint-enable declaration-no-important */
}

.link-group {
  --ifabsh-link-group-gap: var(--ifabsh-spacing-s, 0.5rem);
}
.link-group .link + .link {
  margin-top: var(--ifabsh-link-group-gap);
}

.link-group.-stacked {
  --ifabsh-link-group-gap: 0;
}
.link-group.-stacked .link {
  z-index: 1;
}
.link-group.-stacked .link:hover {
  z-index: 2;
}
.link-group.-stacked .link:first-child:not(:last-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.link-group.-stacked .link:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.link-group.-stacked .link:not(:first-child):not(:last-child) { /* stylelint-disable-line selector-not-notation */
  border-radius: 0;
}
.link-group.-stacked .link:not(:first-child) {
  margin-top: calc(var(--ifabsh-link-border-width) * -1);
}

.link {
  --ifabsh-link-border-color: currentColor;
  --ifabsh-link-border-width: 1px;
  --ifabsh-link-font-size: inherit;
  --ifabsh-link-padding-x: 0;
  --ifabsh-link-padding-y: 0;
  --ifabsh-link-suffix-font-size: inherit;
  --_link-prefix-color: var(--wp-color-secondary);
  border-left: 0;
  border-right: 0;
  border-top: 0;
  display: inline-flex;
  padding-top: 0;
  text-decoration: underline;
}
.link:not(:last-child) {
  margin-bottom: var(--ifabsh-link-padding-y);
}
.link__prefix > i {
  /* stylelint-disable string-quotes */
  /* stylelint-enable string-quotes */
}
.link__prefix > i::before {
  content: "\f0c1" !important;
}
.link__suffix {
  margin-left: 0;
}
.link__suffix::before, .link__suffix::after {
  display: inline-block;
}
.link__suffix::before {
  content: "(";
}
.link__suffix::after {
  content: ")";
}
.link-group {
  --ifabsh-link-group-gap: var(--wp-spacing);
}

.form {
  --ifabsh-form-control-background-color: var(--ifabsh-input-background-color, #fff);
  --ifabsh-form-control-background-color-hover: var(--ifabsh-form-control-background-color);
  --ifabsh-form-control-background-color-focus: var(--ifabsh-form-control-background-color);
  --ifabsh-form-control-border-color: var(--ifabsh-input-border-color, #e8e8e8);
  --ifabsh-form-control-border-color-hover: var(--ifabsh-form-control-border-color);
  --ifabsh-form-control-border-color-focus: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-form-control-border-width: var(--ifabsh-input-border-width, var(--ifabsh-border-width, 1px));
  --ifabsh-form-control-border-radius: var(--ifabsh-input-border-radius, var(--ifabsh-border-radius, 0px));
  --ifabsh-form-control-color: var(--ifabsh-input-color, #000);
  --ifabsh-form-control-color-hover: var(--ifabsh-form-control-color);
  --ifabsh-form-control-color-focus: var(--ifabsh-form-control-color);
  --ifabsh-form-control-font-size: var(--ifabsh-input-font-size, 1rem);
  --ifabsh-form-control-font-style: var(--ifabsh-input-font-style, normal);
  --ifabsh-form-control-font-weight: var(--ifabsh-input-font-weight, normal);
  --ifabsh-form-control-height: var(--ifabsh-input-height, 40px);
  --ifabsh-form-control-padding: var(--ifabsh-input-padding, var(--ifabsh-spacing, 1rem));
  --ifabsh-form-control-duration: var(--ifabsh-input-duration, var(--ifabsh-duration, 0.15s));
  --ifabsh-form-control-disabled-background-color: var(--ifabsh-input-disabled-background-color, #fff);
  --ifabsh-form-control-disabled-opacity: var(--ifabsh-input-disabled-opacity, var(--ifabsh-disabled-opacity, 0.4));
  --ifabsh-form-control-readonly-background-color: var(--ifabsh-input-readonly-background-color, #f1f1f1);
  --ifabsh-form-control-readonly-opacity: var(--ifabsh-input-readonly-opacity, var(--ifabsh-readonly-opacity, 1));
  --ifabsh-form-invalid-border-color: var(--ifabsh-color-invalid, #fb3e4e);
  --ifabsh-form-invalid-label-color: var(--ifabsh-color-invalid, #fb3e4e);
  --ifabsh-form-label-color: var(--ifabsh-color-text, #3e3e3e);
  --ifabsh-form-label-font-size: var(--ifabsh-font-size-s, 0.75rem);
  --ifabsh-form-label-font-style: normal;
  --ifabsh-form-label-font-weight: normal;
  --ifabsh-form-label-margin: var(--ifabsh-spacing-s, 0.5rem);
  --ifabsh-form-text-color: var(--ifabsh-color-text, #3e3e3e);
  --ifabsh-form-text-font-size: var(--ifabsh-font-size-xs, 0.5rem);
  --ifabsh-form-text-font-style: normal;
  --ifabsh-form-text-font-weight: normal;
  --ifabsh-form-text-margin: var(--ifabsh-spacing-s, 0.5rem);
}

.form-control, .form-select {
  --_foco-background-color: var(--ifabsh-form-control-background-color);
  --_foco-border-color: var(--ifabsh-form-control-border-color);
  --_foco-color: var(--ifabsh-form-control-color);
  --_foco-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-form-control-duration));
  --_foco-line-height: calc(var(--ifabsh-form-control-height) - var(--ifabsh-form-control-border-width) * 2);
  --_poco-padding-left: var(--ifabsh-form-control-padding-left, var(--ifabsh-form-control-padding));
  --_poco-padding-right: var(--ifabsh-form-control-padding-right, var(--ifabsh-form-control-padding));
  background-color: var(--_foco-background-color) !important; /* stylelint-disable-line declaration-no-important */
  border: var(--ifabsh-form-control-border-width) solid var(--_foco-border-color) !important; /* stylelint-disable-line declaration-no-important */
  border-radius: var(--ifabsh-form-control-border-radius);
  color: var(--_foco-color) !important; /* stylelint-disable-line declaration-no-important */
  font-size: var(--ifabsh-form-control-font-size);
  font-style: var(--ifabsh-form-control-font-style);
  font-weight: var(--ifabsh-form-control-font-weight);
  line-height: var(--_foco-line-height);
  padding-bottom: 0;
  padding-left: var(--_poco-padding-left) !important; /* stylelint-disable-line declaration-no-important */
  padding-right: var(--_poco-padding-right) !important; /* stylelint-disable-line declaration-no-important */
  padding-top: 0; /* stylelint-disable-line declaration-block-no-redundant-longhand-properties */
  text-transform: var(--ifabsh-form-control-text-transform, var(--ifabsh-input-text-transform, none));
  transition: background-color var(--_foco-duration) ease-in-out, color var(--_foco-duration) ease-in-out, border var(--_foco-duration) ease-in-out, box-shadow var(--_foco-duration) ease-in-out, outline var(--_foco-duration) ease-in-out;
}
@media (hover: hover) {
  .form-control:hover:not(:disabled), .form-control:hover:not([disabled]), .form-control:hover:not(._disabled), .form-select:hover:not(:disabled), .form-select:hover:not([disabled]), .form-select:hover:not(._disabled) {
    --_foco-background-color: var(--ifabsh-form-control-background-color-hover);
    --_foco-border-color: var(--ifabsh-form-control-border-color-hover);
    --_foco-color: var(--ifabsh-form-control-color-hover);
    box-shadow: none;
  }
}
.form-control:focus, .form-select:focus {
  outline: none;
}
.form-control:focus:not(:disabled), .form-control:focus:not([disabled]), .form-control:focus:not(._disabled), .form-select:focus:not(:disabled), .form-select:focus:not([disabled]), .form-select:focus:not(._disabled) {
  --_foco-background-color: var(--ifabsh-form-control-background-color-focus);
  --_foco-border-color: var(--ifabsh-form-control-border-color-focus);
  --_foco-color: var(--ifabsh-form-control-color-focus);
  box-shadow: none;
}
.form-control._focus-visible:focus, .form-control:focus-visible:focus, .form-select._focus-visible:focus, .form-select:focus-visible:focus {
  outline: var(--ifabsh-focus-outline-width, 1px) var(--ifabsh-focus-outline-style, dotted) var(--ifabsh-focus-outline-color, currentColor);
  outline-offset: var(--ifabsh-focus-outline-offset, 2px);
}
.form-control:disabled, .form-control[disabled], .form-control._disabled, .form-select:disabled, .form-select[disabled], .form-select._disabled {
  --_foco-background-color: var(--ifabsh-form-control-disabled-background-color);
  opacity: var(--ifabsh-form-control-disabled-opacity);
  pointer-events: none;
}
.form-control:read-only[readonly], .form-control[readonly], .form-control._readonly, .form-select:read-only[readonly], .form-select[readonly], .form-select._readonly {
  --_foco-background-color: var(--ifabsh-form-control-readonly-background-color);
  opacity: var(--ifabsh-form-control-readonly-opacity);
}
.form-group.-has-error .form-control,
.form-group.-has-error .form-select {
  --_foco-border-color: var(--ifabsh-color-danger);
}
.form-group.-has-error .form-check-input {
  --_foch-border-color: var(--ifabsh-color-danger);
}

.form-control.-s,
.form-select.-s,
[class*=form-control-sm] { /* stylelint-disable-line string-quotes */
  --ifabsh-form-control-height: var(--ifabsh-input-height-s, 30px);
  --ifabsh-form-control-font-size: var(--ifabsh-input-font-size-s, var(--ifabsh-font-size-s, 0.75rem));
  --ifabsh-form-control-padding: var(--ifabsh-input-padding-s, var(--ifabsh-spacing-s, 0.5rem));
}

.form-control.-l,
.form-select.-l,
[class*=form-control-lg] { /* stylelint-disable-line string-quotes */
  --ifabsh-form-control-height: var(--ifabsh-input-height-l, 50px);
  --ifabsh-form-control-font-size: var(--ifabsh-input-font-size-l, var(--ifabsh-font-size-l, 1.25rem));
  --ifabsh-form-control-padding: var(--ifabsh-input-padding-l, var(--ifabsh-spacing-l, 1.25rem));
}

.form-text {
  color: var(--ifabsh-form-text-color);
  font-size: var(--ifabsh-form-text-font-size);
  font-style: var(--ifabsh-form-text-font-style);
  font-weight: var(--ifabsh-form-text-font-weight);
  margin-top: var(--ifabsh-form-text-margin);
}

.form-fieldset {
  margin: 0;
}
.form-fieldset ~ fieldset {
  margin-top: var(--ifabsh-form-fieldset-margin, var(--ifabsh-spacing, 1rem));
}

.form-legend {
  color: var(--ifabsh-form-legend-color, var(--ifabsh-color-text, #3e3e3e));
  float: none;
  font-size: var(--ifabsh-form-legend-font-size, var(--ifabsh-font-size, 1rem));
  font-weight: var(--ifabsh-form-legend-font-weight, normal);
}
.form-legend:not(:last-child) {
  margin-bottom: var(--ifabsh-form-legend-margin, var(--ifabsh-spacing, 1rem));
}

.form-label {
  color: var(--ifabsh-form-label-color);
  font-size: var(--ifabsh-form-label-font-size);
  font-style: var(--ifabsh-form-label-font-style);
  font-weight: var(--ifabsh-form-label-font-weight);
  margin-bottom: var(--ifabsh-form-label-margin);
}
.form-label__required {
  color: var(--ifabsh-form-label-required-color, currentColor);
  padding-left: var(--ifabsh-form-label-required-margin, var(--ifabsh-spacing-xxs, 0.125rem));
}

.form-label.-match-form-control,
[class*=col-form-label] { /* stylelint-disable-line string-quotes */
  --_lbl-height: var(--ifabsh-form-input-height, 40px);
  align-items: center;
  display: inline-flex;
  line-height: normal;
  margin-bottom: 0;
  min-height: var(--_lbl-height);
  padding-bottom: 0;
  padding-top: 0;
}

.form-label.-match-form-control.-l,
[class*=col-form-label-lg] { /* stylelint-disable-line string-quotes */
  --_lbl-height: var(--ifabsh-form-input-height-l, 50px);
}

.form-label.-match-form-control.-s,
[class*=col-form-label-sm] { /* stylelint-disable-line string-quotes */
  --_lbl-height: var(--ifabsh-form-input-height-s, 30px);
}

.form-label.-s,
[class*=col-form-label-sm] { /* stylelint-disable-line string-quotes */
  --ifabsh-form-label-font-size: var(--ifabsh-input-font-size-s, var(--ifabsh-font-size-s, 0.75rem));
}

.form-label.-l,
[class*=col-form-label-lg] { /* stylelint-disable-line string-quotes */
  --ifabsh-form-label-font-size: var(--ifabsh-input-font-size-l, var(--ifabsh-font-size-l, 1.25rem));
}

label > a {
  color: currentColor !important;
}

.form-control {
  height: var(--ifabsh-form-control-height);
}
.form-control.-plaintext {
  --ifabsh-form-control-padding: var(--ifabsh-form-control-plaintext-padding, 0);
}
.form-control.-plaintext, .form-control.-plaintext:hover {
  --_foco-background-color: transparent;
  --_foco-border-color: transparent;
  padding: 0;
}

::placeholder,
::-webkit-input-placeholder,
:-moz-placeholder,
:-ms-placeholder {
  color: var(--ifabsh-form-control-placeholder-color, var(--ifabsh-color-placeholder, #959595));
}

.form-select {
  --ifabsh-form-select-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='black' stroke-linecap='square' stroke-linejoin='miter' stroke-width='3' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  --ifabsh-form-select-multiple-padding-y: calc(var(--ifabsh-form-control-padding) / 2);
  background-image: var(--ifabsh-form-select-icon) !important; /* stylelint-disable-line declaration-no-important */
}
.form-select:not([multiple]) {
  --ifabsh-form-control-padding-right: calc(var(--ifabsh-form-control-padding) * var(--ifabsh-form-select-icon-gap-factor, 2.5));
}
.form-select option {
  padding: var(--ifabsh-form-option-pading-y, var(--ifabsh-spacing-xxs, 0.125rem)) var(--ifabsh-form-option-pading-x, 0);
}
.form-select[multiple], .form-select[size]:not([size="1"]) { /* stylelint-disable-line string-quotes */
  background-image: none !important; /* stylelint-disable-line declaration-no-important */
  height: var(--ifabsh-form-select-height, "auto");
  min-height: var(--ifabsh-form-control-height);
  padding-bottom: var(--ifabsh-form-select-multiple-padding-y);
  padding-top: var(--ifabsh-form-select-multiple-padding-y);
}
.form-select:-moz-focusring {
  text-shadow: none;
}

textarea.form-control {
  --ifabsh-form-textarea-padding-y: calc(var(--ifabsh-form-control-padding) / 2);
  height: var(--ifabsh-form-textarea-height, calc(var(--ifabsh-form-control-height) * 2));
  line-height: inherit;
  min-height: var(--ifabsh-form-textarea-min-height, var(--ifabsh-form-control-height)) !important; /* stylelint-disable-line declaration-no-important */
  padding-bottom: var(--ifabsh-form-textarea-padding-y);
  padding-top: var(--ifabsh-form-textarea-padding-y);
}

.form-control {
  --_foup-button-offset: calc(var(--ifabsh-form-control-padding) * -1);
}
.form-control::file-selector-button {
  background-color: var(--ifabsh-form-upload-button-background-color, var(--ifabsh-color-grey-light, #f1f1f1));
  border-inline-end-width: var(--ifabsh-form-control-border-width);
  color: var(--ifabsh-form-upload-button-border-color, var(--ifabsh-form-control-color));
  line-height: var(--_foco-line-height);
  margin: 0 var(--_foup-button-offset);
  margin-inline-end: var(--ifabsh-form-control-padding);
  padding: 0 var(--ifabsh-form-control-padding);
  transition: background-color var(--_foco-duration) ease-in-out, color var(--_foco-duration) ease-in-out;
}
@media (hover: hover) {
  .form-control:hover:not(:disabled)::file-selector-button, .form-control:hover:not([readonly])::file-selector-button {
    background-color: var(--ifabsh-form-upload-button-background-color-hover, var(--ifabsh-color-grey, #e8e8e8));
    color: var(--ifabsh-form-upload-button-border-color-hover, var(--ifabsh-form-control-color-hover));
  }
}
.form-control:focus:not(:disabled)::file-selector-button, .form-control:focus:not([readonly])::file-selector-button {
  background-color: var(--ifabsh-form-upload-button-background-color-focus, var(--ifabsh-color-grey, #e8e8e8));
  color: var(--ifabsh-form-upload-button-color-focus, var(--ifabsh-form-control-color-focus));
}

.form-check {
  --ifabsh-form-check-accent-color: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-form-check-accent-color-contrast: var(--ifabsh-color-ui-contrast, #fff);
  --ifabsh-form-check-background-color: var(--ifabsh-form-control-background-color);
  --ifabsh-form-check-border-color: var(--ifabsh-form-control-border-color);
  --ifabsh-form-check-border-radius: var(--ifabsh-form-control-border-radius);
  --ifabsh-form-check-border-width: var(--ifabsh-form-control-border-width);
  --ifabsh-form-check-label-color: var(--ifabsh-form-label-color);
  --ifabsh-form-check-control-gap: var(--ifabsh-spacing-s, 0.5rem);
  --ifabsh-form-check-gap: var(--ifabsh-spacing-s, 0.5rem);
  --ifabsh-form-check-gap-inline: var(--ifabsh-spacing, 1rem);
  --ifabsh-form-check-padding: 0;
  --ifabsh-form-check-size: 1em;
  --ifabsh-form-check-duration: 0.15s;
  --ifabsh-form-check-icon-check: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='square' stroke-linejoin='miter' stroke-width='3' d='M6 10l3 3l6-6'/%3E%3C/svg%3E");
  --ifabsh-form-check-icon-check-indeterminate: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='square' stroke-linejoin='miter' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
  --ifabsh-form-check-icon-radio: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='-10 -10 20 20'%3E%3Ccircle r='4' fill='%23000'/%3E%3C/svg%3E");
  --_foch-label-color: var(--ifabsh-form-check-label-color);
  --_foch-border-color: var(--ifabsh-form-check-border-color);
  --_foch-background-color: var(--ifabsh-form-check-background-color);
  --_foch-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-form-check-duration));
  align-items: center;
  display: flex;
  gap: var(--ifabsh-form-check-control-gap);
  margin: 0;
  min-height: auto;
  padding: var(--ifabsh-form-check-padding);
}
.form-check-label {
  color: var(--_foch-label-color) !important; /* stylelint-disable-line declaration-no-important */
  cursor: pointer;
}
.form-check + .form-check {
  margin-top: var(--ifabsh-form-check-gap);
}
.form-check-inline {
  display: inline-flex;
  margin: 0 var(--ifabsh-form-check-gap) var(--ifabsh-form-check-gap) 0;
}

.form-check .form-check-input {
  background-color: var(--_foch-background-color) !important; /* stylelint-disable-line declaration-no-important */
  border-color: var(--_foch-border-color) !important; /* stylelint-disable-line declaration-no-important */
  border-style: solid;
  border-width: var(--ifabsh-form-check-border-width);
  flex-shrink: 0;
  float: none;
  height: var(--ifabsh-form-check-size);
  margin: 0;
  transition: all var(--_foch-duration) ease-in-out;
  width: var(--ifabsh-form-check-size);
}
.form-check .form-check-input[type=checkbox] { /* stylelint-disable-line string-quotes */
  border-radius: var(--ifabsh-form-check-border-radius);
}
.form-check .form-check-input[type=radio] { /* stylelint-disable-line string-quotes */
  border-radius: 100%;
}
.form-check .form-check-input:active {
  filter: none;
}
.form-check .form-check-input:focus {
  --_foch-border-color: var(--ifabsh-form-check-accent-color);
  box-shadow: none;
}
.form-check .form-check-input._focus-visible:focus, .form-check .form-check-input:focus-visible:focus {
  outline: var(--ifabsh-focus-outline-width, 1px) var(--ifabsh-focus-outline-style, dotted) var(--ifabsh-focus-outline-color, currentColor);
  outline-offset: var(--ifabsh-focus-outline-offset, 2px);
}
.form-check .form-check-input:checked {
  --_foch-background-color: var(--ifabsh-form-check-accent-color);
  --_foch-border-color: var(--ifabsh-form-check-accent-color);
}
.form-check .form-check-input:checked[type=checkbox] { /* stylelint-disable-line string-quotes */
  background-image: var(--ifabsh-form-check-icon-check);
}
.form-check .form-check-input:checked[type=radio] { /* stylelint-disable-line string-quotes */
  background-image: var(--ifabsh-form-check-icon-radio);
}
.form-check .form-check-input[type=checkbox]:indeterminate { /* stylelint-disable-line string-quotes */
  --_foch-background-color: var(--ifabsh-form-check-accent-color);
  --_foch-border-color: var(--ifabsh-form-check-accent-color);
  background-image: var(--ifabsh-form-check-icon-check-indeterminate);
}
.form-check .form-check-input:disabled, .form-check .form-check-input[disabled], .form-check .form-check-input._disabled {
  opacity: var(--ifabsh-form-check-disabled-opacity, var(--ifabsh-input-disabled-opacity, 0.4));
  pointer-events: none;
}
.form-check .form-check-input:disabled ~ .form-check-label, .form-check .form-check-input[disabled] ~ .form-check-label, .form-check .form-check-input._disabled ~ .form-check-label {
  opacity: var(--ifabsh-form-check-disabled-opacity, var(--ifabsh-input-disabled-opacity, 0.4));
  pointer-events: none;
}

.form-check.form-switch {
  --ifabsh-form-check-icon-switch-off: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='-10 -10 20 20'%3E%3Ccircle r='8' fill='%23e8e8e8'/%3E%3C/svg%3E");
  --ifabsh-form-check-icon-switch-on: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='-10 -10 20 20'%3E%3Ccircle r='8' fill='%23fff'/%3E%3C/svg%3E");
}
.form-check.form-switch .form-check-input {
  background-image: var(--ifabsh-form-check-icon-switch-off);
  border-radius: var(--ifabsh-form-check-size);
  margin-left: 0;
  transition: all var(--_foch-duration) ease-in-out;
  width: calc(var(--ifabsh-form-check-size) * 2);
}
.form-check.form-switch .form-check-input:focus {
  background-image: var(--ifabsh-form-check-icon-switch-off);
}
.form-check.form-switch .form-check-input:checked {
  background-image: var(--ifabsh-form-check-icon-switch-on);
}

.form-check-group {
  display: flex;
  flex-flow: column wrap;
  gap: var(--ifabsh-form-check-group-gap, var(--ifabsh-spacing-s, 0.5rem));
}
.form-check-group > .form-check {
  margin: 0;
}
.form-check-group.-inline {
  flex-direction: row;
  gap: var(--ifabsh-form-check-group-gap-inline, var(--ifabsh-spacing, 1rem));
}

.form-control-color {
  --ifabsh-form-color-swatch-size: 1em;
  padding: 0 var(--ifabsh-form-color-padding, 0);
  width: var(--ifabsh-form-color-width, var(--ifabsh-form-control-height));
}
.form-control-color::-moz-color-swatch {
  border: 0 none;
  border-radius: var(--ifabsh-form-control-border-radius);
  height: var(--ifabsh-form-color-swatch-size);
  width: var(--ifabsh-form-color-swatch-size);
}
.form-control-color::-webkit-color-swatch-wrapper {
  position: relative;
}
.form-control-color::-webkit-color-swatch {
  border: 0 none;
  border-radius: var(--ifabsh-form-control-border-radius);
  height: var(--ifabsh-form-color-swatch-size);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: var(--ifabsh-form-color-swatch-size);
}

.input-group > *:not(label):not(:first-child) { /* stylelint-disable-line selector-not-notation */
  margin-left: calc(var(--ifabsh-form-control-border-width) * -1) !important; /* stylelint-disable-line declaration-no-important */
}
.input-group > :not(label):focus {
  z-index: 2;
}
@media (hover: hover) {
  .input-group:not(:focus-within) > :not(label):hover {
    z-index: 2;
  }
}

.input-group-text {
  --ifabsh-form-input-group-text-border-width: var(--ifabsh-form-control-border-width);
  --ifabsh-form-input-group-text-border-color: var(--ifabsh-form-control-border-color);
  --ifabsh-form-input-group-text-color: var(--ifabsh-form-label-color);
  --_foig-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-form-control-duration));
  background-color: var(--ifabsh-form-input-group-text-background-color, var(--ifabsh-color-grey-light, #f1f1f1));
  border: var(--ifabsh-form-input-group-text-border-width) solid var(--ifabsh-form-input-group-text-border-color);
  border-radius: var(--ifabsh-form-input-group-text-border-radius, var(--ifabsh-form-control-border-radius));
  color: var(--ifabsh-form-input-group-text-color);
  font-size: var(--ifabsh-form-input-group-text-font-size, var(--ifabsh-form-label-font-size));
  padding: 0 var(--ifabsh-form-input-group-text-padding, var(--ifabsh-form-control-padding));
  transition: background-color var(--_foig-duration) ease-in-out, color var(--_foig-duration) ease-in-out, border var(--_foig-duration) ease-in-out, box-shadow var(--_foig-duration) ease-in-out, outline var(--_foig-duration) ease-in-out;
}
.input-group-text:not(:first-child):not(:last-child) { /* stylelint-disable-line selector-not-notation */
  border-left: 0 none;
  border-right: 0 none;
}

.button-group {
  gap: var(--ifabsh-button-group-gap, 0);
}
.button-group, .button-group-toolbar {
  display: flex;
  flex-wrap: wrap;
}
.button-group > * {
  margin: 0;
}
.button-group > *:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.button-group > *:last-child:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.button-group > *:not(:first-child):not(:last-child) { /* stylelint-disable-line selector-not-notation */
  border-radius: 0;
}

.button-group-toolbar {
  gap: var(--ifabsh-button-group-toolbar-gap, var(--ifabsh-spacing, 1rem));
}

.form-navigation {
  --ifabsh-form-navigation-gap: var(--ifabsh-spacing, 1rem);
  --ifabsh-form-navigation-margin: var(--ifabsh-spacing-l, 1.25rem);
  display: flex;
  gap: var(--ifabsh-form-navigation-gap);
}
.form-navigation:not(:first-child) {
  margin-top: var(--ifabsh-form-navigation-margin);
}
.form-navigation > * {
  align-self: center;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  --_foco-background-color: var(--ifabsh-form-invalid-background-color, var(--ifabsh-form-control-background-color));
  --_foco-border-color: var(--ifabsh-form-invalid-border-color, var(--ifabsh-form-control-border-color));
  --_foco-color: var(--ifabsh-form-invalid-color, var(--ifabsh-form-control-color));
  background-image: none;
  padding-right: var(--ifabsh-form-control-padding);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  box-shadow: none;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: var(--ifabsh-form-control-padding);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  --_foco-background-color: var(--ifabsh-form-invalid-background-color, var(--ifabsh-form-control-background-color));
  --_foco-border-color: var(--ifabsh-form-invalid-border-color, var(--ifabsh-form-control-border-color));
  --_foco-color: var(--ifabsh-form-invalid-color, var(--ifabsh-form-control-color));
  /* stylelint-disable-next-line selector-not-notation */
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] { /* stylelint-disable-line string-quotes */
  padding-right: var(--ifabsh-form-control-padding);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  box-shadow: none;
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  --_foch-background-color: var(--ifabsh-form-invalid-background-color, var(--ifabsh-form-check-background-color));
  --_foch-border-color: var(--ifabsh-form-invalid-border-color, var(--ifabsh-form-check-border-color));
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  --_foch-background-color: var(--ifabsh-form-invalid-background-color, var(--ifabsh-form-check-accent-color));
  --_foch-border-color: var(--ifabsh-form-invalid-border-color, var(--ifabsh-form-check-accent-color));
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: none;
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  --_foch-label-color: var(--ifabsh-form-invalid-label-color, var(--ifabsh-form-check-label-color));
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: none;
  padding-right: var(--ifabsh-form-control-padding);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  box-shadow: none;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: var(--ifabsh-form-control-padding);
}

.was-validated .form-select:valid, .form-select.is-valid {
  /* stylelint-disable-next-line selector-not-notation */
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] { /* stylelint-disable-line string-quotes */
  padding-right: var(--ifabsh-form-control-padding);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  box-shadow: none;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: none;
}

.form-control-clearable {
  --ifabsh-clearable-color: var(--ifabsh-form-control-color);
  --ifabsh-clearable-color-hover: var(--ifabsh-color-ui);
  --ifabsh-clearable-trigger-offset: 0.25rem;
  display: block;
  position: relative;
  width: 100%;
}
.form-control-clearable > .icon-button {
  --ifabsh-icon-button-color: var(--ifabsh-clearable-color);
  --ifabsh-icon-button-color-hover: var(--ifabsh-clearable-color-hover);
  --ifabsh-icon-button-color-active: var(--ifabsh-clearable-color);
  background-color: transparent;
  border: 0 none;
  font-size: var(--ifabsh-clearable-trigger-font-size, 0.75em);
  margin: var(--ifabsh-form-control-border-width) 0 0;
  position: absolute;
  right: var(--_trigger-offset, var(--ifabsh-clearable-trigger-offset));
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}
.form-control-clearable > .form-control.-s + .icon-button {
  --_trigger-offset: calc(var(--ifabsh-clearable-trigger-offset-s, var(--ifabsh-clearable-trigger-offset)) / 2);
}
.form-control-clearable > .form-control.-l {
  --_trigger-offset: calc(var(--ifabsh-clearable-trigger-offset-s, var(--ifabsh-clearable-trigger-offset)) * 2);
}
.form-control-clearable > .form-control[type=search] { /* stylelint-disable-line string-quotes */ }
.form-control-clearable > .form-control[type=search]::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
.form-control-clearable > .form-control[type=search]::-ms-reveal {
  display: none;
  height: 0;
  width: 0;
}
.form-control-clearable > .form-control[type=search]::-webkit-search-decoration, .form-control-clearable > .form-control[type=search]::-webkit-search-cancel-button, .form-control-clearable > .form-control[type=search]::-webkit-search-results-button, .form-control-clearable > .form-control[type=search]::-webkit-search-results-decoration {
  display: none;
}

.input-group > .form-control-clearable {
  flex: 1 1 auto;
  min-width: 0;
  width: 1%;
}
.input-group > .form-control-clearable:not(:first-child), .input-group > .form-control-clearable:not(:first-child) > .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.input-group > .form-control-clearable:not(:last-child), .input-group > .form-control-clearable:not(:last-child) > .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.form {
  --ifabsh-form-check-border-width: 2px;
  --ifabsh-form-control-border-color: #8f8c89;
  --ifabsh-form-control-border-color-focus: var(--wp-color-black);
  --ifabsh-form-control-border-radius: 3px;
  --ifabsh-form-control-height: 51px;
  width: 100%;
}
.form fieldset + fieldset {
  margin-top: var(--wp-spacing-xl);
}

.form-check {
  --ifabsh-form-check-icon-radio: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='-10 -10 20 20'%3E%3Ccircle r='6' fill='%23000'/%3E%3C/svg%3E");
  --ifabsh-form-check-icon-check: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='square' stroke-linejoin='miter' stroke-width='3' d='M6 10l3 3l6-6'/%3E%3C/svg%3E");
  --ifabsh-form-check-accent-color: var(--wp-color-primary);
  --ifabsh-form-check-border-width: 2px;
  --ifabsh-form-check-size: 20px;
}
.form-check .form-check-input:checked {
  --_foch-background-color: var(--wp-color-white);
}
.form-check.form-switch {
  --ifabsh-form-check-icon-switch-off: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='-10 -10 20 20'%3E%3Ccircle r='8' fill='%238f8c89'/%3E%3C/svg%3E");
  --ifabsh-form-check-icon-switch-on: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='-10 -10 20 20'%3E%3Ccircle r='8' fill='%2381a5ea'/%3E%3C/svg%3E");
}
.form-check.form-switch:not(.-disabled):not([disabled=true]):not([aria-disabled=true]), .form-check.form-switch:not(.-disabled):not([disabled=true]):not([aria-disabled=true]) > * {
  cursor: pointer;
}

.global-alert {
  --ifabsh-global-alert-border-width: 3px;
  --ifabsh-global-alert-border-radius: var(--ifabsh-spacing-s);
  --ifabsh-global-alert-color: var(--ifabsh-color-text, #3e3e3e);
  --ifabsh-global-alert-font-size: var(--ifabsh-font-size-s, 0.75rem);
  --ifabsh-global-alert-duration: var(--ifabsh-duration, 0.15s);
  --ifabsh-global-alert-padding: var(--ifabsh-spacing);
  --ifabsh-global-alert-accent-color: var(--ifabsh-global-alert-color);
  --ifabsh-global-alert-header-padding: 0;
  --ifabsh-global-alert-body-padding: var(--ifabsh-spacing-l) var(--ifabsh-spacing);
  --ifabsh-global-alert-icon-background-color: var(--ifabsh-global-alert-accent-color);
  --ifabsh-global-alert-icon-color: var(--ifabsh-global-alert-color, var(--ifabsh-color-black));
  --ifabsh-global-alert-icon-font-size: var(--ifabsh-global-alert-font-size);
  --ifabsh-global-alert-icon-padding: var(--ifabsh-spacing);
  --_al-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-global-alert-duration));
  background-color: var(--ifabsh-global-alert-background-color, var(--ifabsh-color-white));
  border: var(--ifabsh-global-alert-border-width) solid var(--ifabsh-global-alert-accent-color, var(--ifabsh-border-color, #e8e8e8));
  border-radius: var(--ifabsh-global-alert-border-radius, var(--ifabsh-spacing-s));
  color: var(--ifabsh-global-alert-color);
  left: 50%;
  position: fixed;
  top: 50%;
  transition: all var(--_al-duration) ease-in-out;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.global-alert__header, .global-alert__footer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: var(--ifabsh-global-alert-body-gap, var(--ifabsh-spacing));
  padding: var(--ifabsh-global-alert-footer-padding, var(--ifabsh-global-alert-padding));
}
.global-alert__header {
  background: var(--ifabsh-global-alert-header-background-color, var(--ifabsh-global-alert-accent-color), transparent);
  gap: var(--ifabsh-global-alert-header-gap, var(--ifabsh-spacing));
  padding: var(--ifabsh-global-alert-header-padding, var(--ifabsh-global-alert-padding));
}
.global-alert__header > *:first-child {
  border-bottom-right-radius: var(--ifabsh-global-alert-border-radius, var(--ifabsh-spacing-s));
}
.global-alert__header > *:last-child {
  border-bottom-left-radius: var(--ifabsh-global-alert-border-radius, var(--ifabsh-spacing-s));
}
.global-alert__body {
  background: var(--ifabsh-global-alert-body-background-color, var(--ifabsh-global-alert-accent-color), transparent);
  display: flex;
  flex-direction: column;
  gap: var(--ifabsh-global-alert-body-gap, var(--ifabsh-spacing));
  padding: var(--ifabsh-global-alert-body-padding, var(--ifabsh-global-alert-padding));
}
.global-alert__footer {
  background: var(--ifabsh-global-alert-footer-background-color, var(--ifabsh-global-alert-accent-color), transparent);
  border-top: 2px solid var(--ifabsh-global-alert-accent-color);
  gap: var(--ifabsh-global-alert-body-gap, var(--ifabsh-spacing));
  padding: var(--ifabsh-global-alert-footer-padding, var(--ifabsh-global-alert-padding));
}
.global-alert__icon, .global-alert__close {
  align-items: center;
  background: var(--ifabsh-global-alert-icon-background-color);
  color: var(--ifabsh-global-alert-icon-color);
  display: flex;
  flex: 0 0 auto;
  font-size: var(--ifabsh-global-alert-icon-font-size);
  padding: var(--ifabsh-global-alert-icon-padding);
}
.global-alert__icon {
  transform: translate(-1px, -1px);
}
.global-alert__close {
  border: none;
  margin-left: auto;
  transform: translate(1px, -1px);
}

.global-alert.-primary {
  --ifabsh-global-alert-accent-color: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-global-alert-icon-color: var(--ifabsh-color-ui-contrast, #fff) ;
}

.global-alert.-info {
  --ifabsh-global-alert-accent-color: var(--ifabsh-color-info, #2db5cd);
  --ifabsh-global-alert-icon-color: var(--ifabsh-color-info-contrast, #000) ;
}

.global-alert.-success {
  --ifabsh-global-alert-accent-color: var(--ifabsh-color-success, #15c182);
  --ifabsh-global-alert-icon-color: var(--ifabsh-color-success-contrast, #fff) ;
}

.global-alert.-warning {
  --ifabsh-global-alert-accent-color: var(--ifabsh-color-warning, #fca311);
  --ifabsh-global-alert-icon-color: var(--ifabsh-color-warning-contrast, #000) ;
}

.global-alert.-danger {
  --ifabsh-global-alert-accent-color: var(--ifabsh-color-danger, #fb3e4e);
  --ifabsh-global-alert-icon-color: var(--ifabsh-color-danger-contrast, #fff) ;
}

.global-alert-group {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--ifabsh-global-alert-group-gap, var(--ifabsh-spacing-s, 0.5rem));
}
.global-alert-group:empty {
  display: none;
}

.gmap {
  --ifabsh-gmap-border-color: var(--ifabsh-color-border, #e8e8e8);
  --ifabsh-gmap-border-width: var(--ifabsh-border-width, 1px);
  --ifabsh-gmap-marker-gap: var(--ifabsh-spacing-s);
  background-color: var(--ifabsh-gmap-background-color, var(--ifabsh-color-grey-light, #f1f1f1));
  border: var(--ifabsh-gmap-border-width) solid var(--ifabsh-gmap-border-color);
  border-radius: var(--ifabsh-gmap-border-radius, var(--ifabsh-border-radius, 0px));
  display: block;
  max-height: var(--ifabsh-gmap-max-height, 100vh);
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}
.gmap::before {
  content: ""; /* stylelint-disable-line string-quotes */
  display: block;
  padding-bottom: var(--ifabsh-gmap-ratio, calc((9 / 16) * 100%));
}
.gmap-card {
  border-radius: var(--ifabsh-gmap-border-radius);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.gmap-marker {
  display: flex;
  flex-flow: column nowrap;
  font-size: var(--ifabsh-gmap-marker-font-size, var(--ifabsh-font-size-xs, 0.5rem));
  gap: var(--ifabsh-gmap-marker-gap);
  line-height: var(--ifabsh-gmap-marker-line-height, 1.375);
}
.gmap-marker > [role=heading] { /* stylelint-disable-line string-quotes */
  font-weight: var(--ifabsh-gmap-marker-title-font-weight, bold);
}
.gmap-marker > [role=heading]:not(:first-child) {
  margin-top: var(--ifabsh-gmap-marker-gap);
}
.gmap-marker > * {
  margin-bottom: 0;
  margin-top: 0;
}
.gmap .privacy-info {
  bottom: 0;
  display: none;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  z-index: 5;
}
.gmap[data-privacy] .privacy-info {
  display: block;
  pointer-events: all;
}

.icon-button {
  --ifabsh-icon-button-background-color: transparent;
  --ifabsh-icon-button-background-color-hover: var(--ifabsh-icon-button-background-color);
  --ifabsh-icon-button-background-color-active: var(--ifabsh-icon-button-background-color-hover);
  --ifabsh-icon-button-color: inherit;
  --ifabsh-icon-button-color-hover: var(--ifabsh-icon-button-color);
  --ifabsh-icon-button-color-active: var(--ifabsh-icon-button-color-hover);
  --ifabsh-icon-button-duration: var(--ifabsh-duration, 0.15s);
  --ifabsh-icon-button-padding: var(--ifabsh-spacing-s, 0.5rem);
  --_ibtn-background-color: var(--ifabsh-icon-button-background-color);
  --_ibtn-color: var(--ifabsh-icon-button-color);
  --_ibtn-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-icon-button-duration));
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--_ibtn-background-color);
  border: none;
  border-radius: 0;
  color: var(--_ibtn-color) !important; /* stylelint-disable-line declaration-no-important */
  display: inline-flex;
  flex: 0 0 auto;
  font-size: var(--ifabsh-icon-button-font-size, inherit);
  font-weight: var(--ifabsh-icon-button-font-weight, 400);
  gap: var(--ifabsh-icon-button-gap, var(--ifabsh-spacing-s));
  height: auto;
  justify-content: center;
  line-height: 1;
  padding: var(--ifabsh-icon-button-padding);
  position: relative;
  text-decoration-line: none;
  transition-duration: var(--_ibtn-duration);
  transition-property: background-color, border-color, color, opacity;
  transition-timing-function: ease-in-out;
}
.icon-button__icon {
  align-items: center;
  display: flex;
  height: 1em;
  justify-content: center;
  width: 1em;
}
@media (hover: hover) {
  .icon-button:hover {
    --_ibtn-background-color: var(--ifabsh-icon-button-background-color-hover);
    --_ibtn-color: var(--ifabsh-icon-button-color-hover);
  }
}
.icon-button:focus, .icon-button._focus {
  --_ibtn-background-color: var(--ifabsh-icon-button-background-color-hover);
  --_ibtn-color: var(--ifabsh-icon-button-color-hover);
  box-shadow: none;
  outline: none;
}
.icon-button._focus-visible:focus, .icon-button:focus-visible:focus {
  outline: var(--ifabsh-focus-outline-width, 1px) var(--ifabsh-focus-outline-style, dotted) var(--focus-outline-color, currentColor);
  outline-offset: var(--ifabsh-focus-outline-offset, 2px);
}
.icon-button:active, .icon-button._active {
  --_ibtn-background-color: var(--ifabsh-icon-button-background-color-active);
  --_ibtn-color: var(--ifabsh-icon-button-color-active);
}
.icon-button:disabled, .icon-button[disabled], .icon-button._disabled {
  box-shadow: none;
  cursor: not-allowed;
  opacity: var(--ifabsh-icon-button-disabled-opacity, 0.4);
  outline: none;
  pointer-events: none;
}

.icon-button[data-is-loading] {
  cursor: wait;
  pointer-events: none;
}
.icon-button > .spinner {
  display: none;
}

.icon-button[data-is-loading] .icon-button__icon {
  visibility: hidden;
}
.icon-button[data-is-loading] > .spinner {
  --_sp-pos: calc(50% - var(--ifabsh-spinner-size) / 2);
  display: inline-block;
  left: var(--_sp-pos);
  position: absolute;
  top: var(--_sp-pos);
  z-index: 5;
}

.figure {
  margin: 0;
}
.figure img {
  max-width: none;
  width: 100%;
}
.figure > a {
  display: block;
  position: relative;
  text-decoration-line: none;
  width: 100%;
}
.figure__caption {
  background-color: var(--ifabsh-figure-caption-background-color, transparent);
  color: var(--ifabsh-figure-caption-color, inherit);
  font-size: var(--ifabsh-figure-caption-font-size, var(--ifabsh-font-size-s, 0.75rem));
  margin: var(--ifabsh-figure-caption-margin, var(--ifabsh-spacing-s, 0.5rem)) 0 0;
  padding: var(--ifabsh-figure-caption-padding, 0);
}
.figure__caption > * {
  display: inline;
  margin: 0;
}
.figure__caption > * + * {
  margin-left: var(--ifabsh-figure-caption-copyright-gap, var(--ifabsh-spacing-s, 0.5rem));
}
.figure__caption > .copyright::before {
  content: "("; /* stylelint-disable-line string-quotes */
}
.figure__caption > .copyright::after {
  content: ")"; /* stylelint-disable-line string-quotes */
}

.figure {
  --ifabsh-figure-caption-font-size: var(--wp-font-size-xs);
  --ifabsh-figure-caption-margin: var(--wp-spacing-s);
  --ifabsh-figure-caption-padding: 0 calc(var(--wp-spacing-s) * 3);
}
.figure:not(:first-child:last-child) {
  margin: 0 0 var(--wp-figure-margin, var(--wp-block-element-margin));
}

.jumplist {
  --ifabsh-jumplist-link-background-color: transparent;
  --ifabsh-jumplist-link-background-color-hover: transparent;
  --ifabsh-jumplist-link-background-color-active: var(--ifabsh-jumplist-link-background-color-hover);
  --ifabsh-jumplist-link-color: var(--ifabsh-color-text, #3e3e3e);
  --ifabsh-jumplist-link-color-hover: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-jumplist-link-color-active: var(--ifabsh-jumplist-link-color-hover);
  --ifabsh-jumplist-duration: var(--ifabsh-duration, 0.15s);
  --_jpl-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-jumplist-duration));
  background-color: var(--ifabsh-jumplist-background-color, transparent);
  font-size: var(--ifabsh-jumplist-font-size, var(--ifabsh-font-size, 1rem));
  padding: var(--ifabsh-jumplist-padding-y, 0) var(--ifabsh-jumplist-padding-x, 0);
}
.jumplist.-refresh {
  pointer-events: none;
}

.jumplist-menu {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--ifabsh-jumplist-menu-gap, 0);
}
.jumplist-menu .jumplist-menu__link {
  --_jpl-link-background-color: var(--ifabsh-jumplist-link-background-color);
  --_jpl-link-color: var(--ifabsh-jumplist-link-color);
  background-color: var(--_jpl-link-background-color);
  color: var(--_jpl-link-color);
  display: block;
  font-weight: var(--ifabsh-jumplist-link-font-weight, normal);
  padding: var(--ifabsh-jumplist-link-padding-y, 0) var(--ifabsh-jumplist-link-padding-x, 0);
  text-decoration-line: none;
  transition-duration: var(--_jpl-duration);
  transition-property: all;
  transition-timing-function: ease-in-out;
  white-space: nowrap;
}
.jumplist-menu .jumplist-menu__link__text {
  display: block;
}
.jumplist-menu .jumplist-menu__link * {
  color: currentColor;
}
@media (hover: hover) {
  .jumplist-menu .jumplist-menu__link:hover {
    --_jpl-link-background-color: var(--ifabsh-jumplist-link-background-color-hover);
    --_jpl-link-color: var(--ifabsh-jumplist-link-color-hover);
    text-decoration-line: none;
  }
}
.jumplist-menu .jumplist-menu__link:focus, .jumplist-menu .jumplist-menu__link._focus {
  --_jpl-link-background-color: var(--ifabsh-jumplist-link-background-color);
  --_jpl-link-color: var(--ifabsh-jumplist-link-color);
  box-shadow: none;
  outline: none;
  text-decoration-line: none;
}
.jumplist-menu .jumplist-menu__link._focus-visible:focus, .jumplist-menu .jumplist-menu__link:focus-visible:focus {
  outline: var(--ifabsh-focus-outline-width, 1px) var(--ifabsh-focus-outline-style, dotted) var(--focus-outline-color, currentColor);
  outline-offset: var(--ifabsh-focus-outline-offset, 2px);
}
.jumplist-menu .jumplist-menu__link._active {
  --_jpl-link-background-color: var(--ifabsh-jumplist-link-background-color-active);
  --_jpl-link-color: var(--ifabsh-jumplist-link-color-active);
  text-decoration-line: none;
}
.jumplist-menu:empty {
  display: none;
}

.language-picker {
  --ifabsh-language-picker-duration: var(--ifabsh-duration, 0.15s);
  --ifabsh-language-picker-background-color: transparent;
  --ifabsh-language-picker-background-color-hover: transparent;
  --ifabsh-language-picker-background-color-active: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-language-picker-color: inherit;
  --ifabsh-language-picker-color-hover: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-language-picker-color-active: var(--ifabsh-color-ui-contrast, #fff);
  --ifabsh-language-picker-item-gap: 0;
  --ifabsh-language-picker-padding-x: var(--ifabsh-spacing-s, 0.5rem);
  --ifabsh-language-picker-padding-y: var(--ifabsh-spacing-xxs, 0.125rem);
  --ifabsh-language-picker-toggle-background-color: transparent;
  --ifabsh-language-picker-toggle-background-color-hover: transparent;
  --ifabsh-language-picker-toggle-color: inherit;
  --ifabsh-language-picker-toggle-color-hover: var(--ifabsh-language-picker-toggle-color);
  --ifabsh-language-picker-toggle-duration: var(--ifabsh-language-picker-duration);
  --ifabsh-language-picker-menu-background-color: var(--ifabsh-color-white, #fff);
  --ifabsh-language-picker-menu-border-color: var(--ifabsh-color-grey, #e8e8e8);
  --ifabsh-language-picker-menu-border-radius: var(--ifabsh-border-radius, 0px);
  --ifabsh-language-picker-menu-border-width: var(--ifabsh-border-width, 1px);
  --_lnpi-menu-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-language-picker-duration));
  display: inline-block;
  position: relative;
}
.language-picker__desc {
  /* stylelint-disable declaration-no-important */
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
  /* stylelint-enable declaration-no-important */
}

.language-picker-toggle {
  --_lnpi-toggle-background-color: var(--ifabsh-language-picker-toggle-background-color, transparent);
  --_lnpi-toggle-color: var(--ifabsh-language-picker-toggle-color, inherit);
  --_lnpi-toggle-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-language-picker-toggle-duration));
  align-items: center;
  -webkit-appearance: none;
  background-color: var(--_lnpi-toggle-background-color);
  border: none;
  border-radius: 0;
  color: var(--_lnpi-toggle-color);
  display: inline-flex;
  flex: 0 0 auto;
  font-size: inherit;
  height: auto;
  justify-content: center;
  line-height: 1;
  padding: var(--ifabsh-language-picker-toggle-padding, var(--ifabsh-spacing-s, 0.5rem));
  text-decoration-line: none;
  transition: all var(--_lnpi-toggle-duration) ease-in-out;
}
@media (hover: hover) {
  .language-picker-toggle:hover {
    --_lnpi-toggle-background-color: var(--ifabsh-language-picker-toggle-background-color-hover);
    --_lnpi-toggle-color: var(--ifabsh-language-picker-toggle-color-hover);
    text-decoration-line: none;
  }
}
.language-picker-toggle:focus {
  --_lnpi-toggle-background-color: var(--ifabsh-language-picker-toggle-background-color-hover);
  --_lnpi-toggle-color: var(--ifabsh-language-picker-toggle-color-hover);
  box-shadow: none;
  outline: none;
  text-decoration-line: none;
}
.language-picker-toggle._focus-visible:focus, .language-picker-toggle:focus-visible:focus {
  outline: var(--ifabsh-focus-outline-width, 1px) var(--ifabsh-focus-outline-style, dotted) var(--focus-outline-color, currentColor);
  outline-offset: var(--ifabsh-focus-outline-offset, 2px);
}
.language-picker-toggle[aria-expanded=true] { /* stylelint-disable-line string-quotes */
  --_lnpi-toggle-background-color: var(--ifabsh-language-picker-toggle-background-color-hover);
  --_lnpi-toggle-color: var(--ifabsh-language-picker-toggle-color-hover);
  cursor: pointer;
  text-decoration-line: none;
}
.language-picker-toggle[aria-expanded=true] + .language-picker-menu { /* stylelint-disable-line string-quotes */
  opacity: 1;
  pointer-events: all;
  transition: visibility 0s, opacity var(--_lnpi-menu-duration) ease-in-out;
  visibility: visible;
}
.language-picker-toggle:disabled, .language-picker-toggle[disabled], .language-picker-toggle._disabled {
  cursor: not-allowed;
  opacity: var(--ifabsh-language-picker-toggle-disabled-opacity, var(--ifabsh-input-disabled-opacity, var(--0.4disabled-opacity, 0.4))) !important; /* stylelint-disable-line declaration-no-important */
}
.language-picker-toggle:disabled *, .language-picker-toggle[disabled] *, .language-picker-toggle._disabled * {
  pointer-events: none;
}

.language-picker-menu {
  background-color: var(--ifabsh-language-picker-menu-background-color);
  border: var(--ifabsh-language-picker-menu-border-width) solid var(--ifabsh-language-picker-menu-border-color);
  border-radius: var(--ifabsh-language-picker-menu-border-radius);
  color: var(--ifabsh-language-picker-color);
  display: block;
  opacity: 0;
  padding: var(--ifabsh-language-picker-padding-y) 0;
  pointer-events: none;
  transition: visibility 0s ease-in-out var(--_lnpi-menu-duration), opacity var(--_lnpi-menu-duration) ease-in-out;
  visibility: hidden;
}

.language-picker-lang {
  color: currentColor;
  display: block;
  padding: var(--ifabsh-language-picker-padding-y) var(--ifabsh-language-picker-padding-x);
  text-decoration-line: none;
}
.language-picker-lang__label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (hover: hover) {
  .language-picker-lang:hover {
    background-color: var(--ifabsh-language-picker-background-color-hover);
    color: var(--ifabsh-language-picker-color-hover);
    text-decoration-line: none;
  }
}
.language-picker-lang:focus, .language-picker-lang._focus {
  background-color: var(--ifabsh-language-picker-background-color-focus, var(--ifabsh-language-picker-background-color-hover));
  box-shadow: none;
  color: var(--ifabsh-language-picker-color-focus, var(--ifabsh-language-picker-color-hover));
  outline: none;
  text-decoration-line: none;
}
.language-picker-lang._focus-visible:focus, .language-picker-lang:focus-visible:focus {
  outline: var(--ifabsh-focus-outline-width, 1px) var(--ifabsh-focus-outline-style, dotted) var(--focus-outline-color, currentColor);
  outline-offset: var(--ifabsh-focus-outline-offset, 2px);
}
.language-picker-lang[aria-checked=true] { /* stylelint-disable-line string-quotes */
  background-color: var(--ifabsh-language-picker-background-color-active);
  color: var(--ifabsh-language-picker-color-active);
}

.language-picker.-list {
  display: flex;
  gap: var(--ifabsh-language-picker-item-gap);
}

.swiper {
  --swiper-theme-color: var(--ifabsh-slider-theme-color);
  --swiper-preloader-color: var(--ifabsh-slider-preloader-color, var(--ifabsh-slider-theme-color));
  opacity: 0;
  transition-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-slider-init-duration, 0.25s));
  transition-property: opacity;
}
.swiper.swiper-initialized {
  opacity: 1;
}
.swiper-lazy-preloader {
  border-width: 0.125em;
  font-size: var(--ifabsh-slider-preloader-size, 1rem);
  height: 1em;
  margin-left: -0.5em;
  margin-top: -0.5em;
  width: 1em;
}
.swiper-button-lock {
  display: none;
  pointer-events: none;
}
.swiper-button-next, .swiper-button-prev {
  --ifabsh-icon-button-color: var(--ifabsh-slider-button-color);
  font-size: var(--ifabsh-slider-button-font-size);
  margin: 0;
  opacity: var(--ifabsh-slider-button-opacity, 1);
  position: absolute;
  transform: translateY(-50%);
  width: auto;
}
.swiper-button-next[aria-disabled=true], .swiper-button-prev[aria-disabled=true] { /* stylelint-disable-line string-quotes */
  opacity: var(--ifabsh-slider-button-disabled-opacity, 0);
}
.swiper-button-next::after, .swiper-button-prev::after {
  display: none;
}
.swiper-button-next {
  right: 0;
}
.swiper-button-prev {
  left: 0;
}
.swiper-pagination {
  --swiper-pagination-color: var(--ifabsh-slider-theme-color);
  background-color: var(--ifabsh-slider-pagination-background-color);
  column-gap: var(--ifabsh-slider-pagination-column-gap);
  display: inline-flex;
  flex-wrap: wrap;
  padding: var(--ifabsh-slider-pagination-padding);
  row-gap: var(--ifabsh-slider-pagination-row-gap);
}
.swiper-pagination-bullet {
  --swiper-pagination-bullet-color: var(--ifabsh-slider-pagination-item-color-active);
  --swiper-pagination-bullet-height: var(--ifabsh-slider-pagination-item-height);
  --swiper-pagination-bullet-inactive-color: var(--ifabsh-slider-pagination-item-color);
  --swiper-pagination-bullet-inactive-opacity: var(--ifabsh-slider-pagination-item-opacity, 1);
  --swiper-pagination-bullet-opacity: var(--ifabsh-slider-pagination-item-opacity-active, 1);
  --swiper-pagination-bullet-width: var(--ifabsh-slider-pagination-item-width);
  --swiper-pagination-bullet-horizontal-gap: 0;
  --swiper-pagination-bullet-vertival-gap: 0;
  border-radius: var(--ifabsh-slider-pagination-item-border-radius, 0);
  transition-duration: calc(var(--global-duration-multiplier, 1) * 0.15s);
  transition-property: all;
}
.swiper-pagination-bullet:focus {
  box-shadow: none;
  outline: none;
}
.swiper-pagination-bullet._focus-visible:focus, .swiper-pagination-bullet:focus-visible:focus {
  outline: var(--ifabsh-focus-outline-width, 1px) var(--ifabsh-focus-outline-style, dotted) var(--ifabsh-focus-outline-color, currentColor);
  outline-offset: var(--ifabsh-focus-outline-offset, 2px);
}
.swiper-pagination.swiper-pagination-lock {
  display: none;
  pointer-events: none;
}
.swiper-pagination.swiper-pagination-horizontal {
  bottom: auto;
  justify-content: flex-end;
  left: auto;
  line-height: 1;
  max-width: 100%;
  right: var(--ifabsh-slider-pagination-offset);
  top: var(--ifabsh-slider-pagination-offset);
  width: max-content;
}

.slider {
  --ifabsh-slider-theme-color: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-slider-button-color: var(--ifabsh-slider-theme-color);
  --ifabsh-slider-button-font-size: var(--ifabsh-font-size, 1rem);
  --ifabsh-slider-pagination-item-height: var(--ifabsh-slider-pagination-item-size, 0.3125rem);
  --ifabsh-slider-pagination-item-width: var(--ifabsh-slider-pagination-item-size, 1.25rem);
  --ifabsh-slider-pagination-item-color: var(--ifabsh-color-white, #fff);
  --ifabsh-slider-pagination-item-color-active: var(--ifabsh-slider-theme-color);
  --ifabsh-slider-pagination-background-color: transparent;
  --ifabsh-slider-pagination-column-gap: var(--ifabsh-slider-pagination-gap, var(--ifabsh-spacing-s, 0.5rem));
  --ifabsh-slider-pagination-row-gap: var(--ifabsh-slider-pagination-gap, var(--ifabsh-spacing-s, 0.5rem));
  --ifabsh-slider-pagination-offset: 0;
  --ifabsh-slider-pagination-padding: var(--ifabsh-spacing-s, 0.5rem);
  position: relative;
}
.slider-item__inner {
  position: relative;
}
.slider-item__inner > *:not(.swiper-lazy-preloader) {
  margin: 0;
}

.media-gallery {
  --ifabsh-media-gallery-gap-column: var(--ifabsh-media-gallery-gap, var(--ifabsh-spacing, 1rem));
  --ifabsh-media-gallery-gap-row: var(--ifabsh-media-gallery-gap-column);
}
@media (min-width: 768px) {
  .media-gallery {
    /* stylelint-disable string-quotes */
    /* stylelint-enable string-quotes */
  }
  .media-gallery[data-columns="2"].-grid .media-gallery__inner, .media-gallery[data-columns="3"].-grid .media-gallery__inner, .media-gallery[data-columns="4"].-grid .media-gallery__inner {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
  .media-gallery[data-columns="2"].-slider .slider-item, .media-gallery[data-columns="3"].-slider .slider-item, .media-gallery[data-columns="4"].-slider .slider-item {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .media-gallery {
    /* stylelint-disable string-quotes */
    /* stylelint-enable string-quotes */
  }
  .media-gallery[data-columns="3"].-grid .media-gallery__inner {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
  .media-gallery[data-columns="3"].-slider .slider-item {
    width: 33.3333333333%;
  }
  .media-gallery[data-columns="4"].-grid .media-gallery__inner {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
  .media-gallery[data-columns="4"].-slider .slider-item {
    width: 25%;
  }
}

.media-gallery.-grid .media-gallery__inner {
  display: grid;
  grid-gap: var(--ifabsh-media-gallery-gap-row) var(--ifabsh-media-gallery-gap-column);
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto;
}
.media-gallery.-grid .media-gallery__inner > * {
  margin: 0 !important; /* stylelint-disable-line declaration-no-important */
  width: 100%;
}
.media-gallery.-grid .media-gallery__inner > * + * {
  margin: 0;
}

.media-gallery.-slider {
  --_mgal-gap: calc(var(--ifabsh-media-gallery-gap-column) / 2);
}
.media-gallery.-slider .slider-container {
  margin-left: calc(var(--_mgal-gap) * -1);
  margin-right: calc(var(--_mgal-gap) * -1);
}
.media-gallery.-slider .slider-item__inner {
  padding-left: var(--_mgal-gap);
  padding-right: var(--_mgal-gap);
}

.media-gallery {
  --ifabsh-media-gallery-gap-column: var(--wp-grid-gap-x);
  --ifabsh-media-gallery-gap-row: var(--wp-grid-gap-y);
}
.media-gallery.-slider .slider {
  --ifabsh-slider-pagination-background-color: transparent;
}
.media-gallery.-slider .slider .swiper-pagination {
  --ifabsh-slider-pagination-offset: calc(var(--wp-spacing) + var(--wp-spacing-xs));
  --ifabsh-slider-pagination-padding: 0;
  justify-content: center;
  margin-top: var(--ifabsh-slider-pagination-offset);
  position: relative;
  bottom: auto;
  left: auto;
  right: auto;
  top: auto;
  width: 100%;
}
.media-gallery.-slider .figure__caption {
  display: none;
}

.media-placeholder {
  --ifabsh-media-placeholder-background-color: var(--ifabsh-color-grey-light, #f1f1f1);
  --ifabsh-media-placeholder-border-radius: var(--ifabsh-border-radius, 0px);
  --ifabsh-media-placeholder-color: var(--ifabsh-color-grey, #e8e8e8);
  --ifabsh-media-placeholder-size: 3rem;
  align-items: center;
  background-color: var(--ifabsh-media-placeholder-background-color);
  border-radius: var(--ifabsh-media-placeholder-border-radius);
  color: var(--ifabsh-media-placeholder-color);
  display: flex;
  font-size: var(--ifabsh-media-placeholder-size);
  height: 1em;
  justify-content: center;
  overflow: hidden;
  pointer-events: none;
  width: 1em;
}
img.media-placeholder__image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

svg.media-placeholder__image {
  --placeholder-color: var(--ifabsh-media-placeholder-color);
  max-height: 100%;
  max-width: 100%;
}

.media-placeholder__text {
  color: var(--ifabsh-media-placeholder-text-color, var(--ifabsh-color-placeholder, #959595));
  font-size: var(--ifabsh-media-placeholder-text-font-size, var(--ifabsh-font-size-s, 0.75rem));
}

.nav {
  --ifabsh-nav-font-size: var(--ifabsh-font-size, 1rem);
  --ifabsh-nav-duration: var(--ifabsh-duration, 0.15s);
  --ifabsh-nav-list-gap-x: var(--ifabsh-nav-gap, 0);
  --ifabsh-nav-list-gap-y: var(--ifabsh-nav-gap, var(--ifabsh-spacing-s, 0.5rem));
  --ifabsh-nav-link-color: var(--ifabsh-color-text, #3e3e3e);
  --ifabsh-nav-link-color-hover: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-nav-link-color-active: var(--ifabsh-nav-link-color-hover);
  --ifabsh-nav-link-background-color: transparent;
  --ifabsh-nav-link-background-color-hover: transparent;
  --ifabsh-nav-link-background-color-active: var(--ifabsh-nav-link-background-color-hover);
  --ifabsh-nav-link-padding-x: var(--ifabsh-nav-link-padding, var(--ifabsh-spacing, 1rem));
  --ifabsh-nav-link-padding-y: var(--ifabsh-nav-link-padding, var(--ifabsh-spacing-xs, 0.25rem));
  --ifabsh-nav-link-prefix-suffix-gap: calc(var(--ifabsh-nav-font-size) / 2);
  --_nv-duration: 0.75s;
  background-color: var(--ifabsh-nav-background-color, transparent);
  flex-wrap: nowrap;
  font-size: var(--ifabsh-nav-font-size);
  gap: var(--ifabsh-nav-gap, var(--ifabsh-spacing-s, 0.5rem));
}
.nav-list, .nav-item, .nav-link, .nav-delimiter {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav-desc {
  display: none;
}
.nav-delimiter {
  user-select: none;
  display: none;
  pointer-events: none;
}
.nav-link,
.nav :any-link {
  transition: all var(--_nv-duration) ease-in-out;
}
.nav-link {
  --_nvli-background-color: var(--ifabsh-nav-link-background-color);
  --_nvli-color: var(--ifabsh-nav-link-color);
  align-items: stretch;
  background-color: var(--_nvli-background-color) !important; /* stylelint-disable-line declaration-no-important */
  border: 0 none;
  color: var(--_nvli-color) !important; /* stylelint-disable-line declaration-no-important */
  display: inline-flex;
  gap: var(--ifabsh-nav-link-prefix-suffix-gap);
  padding: var(--ifabsh-nav-link-padding-y) var(--ifabsh-nav-link-padding-x);
  text-decoration-line: none;
  vertical-align: middle;
}
.nav-link i,
.nav-link .icon,
.nav-link svg {
  pointer-events: none;
  position: relative;
}
.nav-link__label {
  align-items: center;
  font-weight: var(--ifabsh-nav-font-weight, normal);
  order: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nav-link__prefix, .nav-link__suffix {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  transition: all var(--_nv-duration) ease-in-out;
}
.nav-link__prefix {
  color: var(--ifabsh-nav-link-prefix-color, currentColor);
  order: 1;
}
.nav-link__suffix {
  color: var(--ifabsh-nav-link-suffix-color, currentColor);
  order: 3;
}
@media (hover: hover) {
  .nav-link:not(span):hover {
    --_nvli-background-color: var(--ifabsh-nav-link-background-color-hover);
    --_nvli-color: var(--ifabsh-nav-link-color-hover);
  }
}
.nav-link:focus-visible {
  box-shadow: none;
}
.nav-item {
  position: relative;
}
@media (hover: hover) {
  .nav-item:hover > .nav-link:not(span):not(.-active) {
    --_nvli-background-color: var(--ifabsh-nav-link-background-color-hover);
    --_nvli-color: var(--ifabsh-nav-link-color-hover);
  }
}
.nav-item.-active > .nav-link {
  --_nvli-background-color: var(--ifabsh-nav-link-background-color-active);
  --_nvli-color: var(--ifabsh-nav-link-color-active);
}
.nav-list, .nav-container {
  transition: all var(--_nv-duration) ease-in-out;
}
.nav-list .nav-item > .nav-container,
.nav-list .nav-item > .nav-list {
  background-color: var(--ifabsh-nav-list-background-color, transparent);
  left: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: relative;
  top: 0;
  transition: all var(--_nv-duration) ease-in-out;
  visibility: hidden;
  z-index: -1;
}
.nav-list .nav-item > .nav-container[aria-hidden=false],
.nav-list .nav-item > .nav-list[aria-hidden=false] { /* stylelint-disable-line string-quotes */
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}
.nav-list .nav-item > .nav-container.-right,
.nav-list .nav-item > .nav-list.-right {
  left: auto;
  right: 0;
}
.nav-list .nav-list .nav-item:not(:first-child) {
  margin-top: var(--ifabsh-nav-list-gap-y);
}
.nav > .nav-list {
  column-gap: var(--ifabsh-nav-list-gap-x);
  display: flex;
  justify-content: stretch;
  row-gap: var(--ifabsh-nav-list-gap-y);
}
@media (hover: hover) {
  .nav.no-js .nav-item > .nav-contrainer,
  .nav.no-js .nav-item > .nav-list {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }
}
.nav.no-js .nav-item:focus-within > .nav-contrainer,
.nav.no-js .nav-item:focus-within > .nav-list {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}
.nav.no-js .nav-item:focus-within > .nav-link {
  --_nvli-background-color: var(--ifabsh-nav-link-background-color-hover);
  --_nvli-color: var(--ifabsh-nav-link-color-hover);
}

.pagination {
  --ifabsh-pagination-color: var(--ifabsh-color-text, #3e3e3e);
  --ifabsh-pagination-color-hover: var(--ifabsh-pagination-color);
  --ifabsh-pagination-color-active: var(--ifabsh-color-ui-contrast, #fff);
  --ifabsh-pagination-background-color: transparent;
  --ifabsh-pagination-background-color-hover: var(--ifabsh-pagination-background-color);
  --ifabsh-pagination-background-color-active: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-pagination-border-color: var(--ifabsh-color-border, #e8e8e8);
  --ifabsh-pagination-border-color-hover: var(--ifabsh-pagination-border-color);
  --ifabsh-pagination-border-color-active: var(--ifabsh-pagination-background-color-active);
  --ifabsh-pagination-border-radius: var(--ifabsh-border-radius, 0px);
  --ifabsh-pagination-border-width: var(--ifabsh-border-width, 1px);
  --ifabsh-pagination-font-size: var(--ifabsh-font-size, 1rem);
  --ifabsh-pagination-gap: var(--ifabsh-spacing-s, 0.5rem);
  --ifabsh-pagination-height: var(--ifabsh-input-height, 40px);
  --ifabsh-pagination-padding: var(--ifabsh-spacing-s, 0.5rem);
  --ifabsh-pagination-duration: var(--ifabsh-duration, 0.15s);
  --_pg-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-pagination-duration));
  background-color: transparent;
  color: var(--ifabsh-pagination-color);
  display: flex;
  flex-flow: row nowrap;
  font-size: var(--ifabsh-pagination-font-size);
  gap: var(--ifabsh-pagination-gap);
  justify-content: var(--ifabsh-pagination-align, center);
  padding: 0;
}
.pagination-list {
  display: inline-flex;
  flex-flow: row wrap;
  gap: var(--ifabsh-pagination-gap);
  justify-content: center;
  list-style: none;
  margin: 0;
  order: 3;
  padding: 0;
  z-index: 1;
}
.pagination-list > li {
  align-self: center;
  line-height: 1;
  margin: 0;
  padding: 0;
  z-index: 1;
}
.pagination-list > li::after, .pagination-list > li::before {
  display: none;
}
.pagination-list > li:hover, .pagination-list > li:focus-within {
  z-index: 2;
}
.pagination-item {
  --_pg-background-color: var(--ifabsh-pagination-background-color);
  --_pg-border-color: var(--ifabsh-pagination-border-color);
  --_pg-color: var(--ifabsh-pagination-color);
  align-items: stretch;
  background-color: var(--_pg-background-color);
  border: solid var(--ifabsh-pagination-border-width) var(--_pg-border-color);
  border-radius: var(--ifabsh-pagination-border-radius);
  color: var(--_pg-color) !important; /* stylelint-disable-line declaration-no-important */
  display: inline-flex;
  gap: var(--ifabsh-pagination-icon-margin, calc(var(--ifabsh-pagination-font-size) / 2));
  height: var(--ifabsh-pagination-height);
  justify-content: center;
  line-height: calc(var(--ifabsh-pagination-height) - var(--ifabsh-pagination-border-width) * 2);
  min-width: var(--ifabsh-pagination-height);
  padding: 0 var(--ifabsh-pagination-padding);
  text-decoration-line: none;
  transition: all var(--_pg-duration) ease-in-out;
}
.pagination-item__label {
  align-items: center;
}
.pagination-item.-prev {
  min-width: fit-content;
  order: 1;
}
.pagination-item.-prev::before {
  content: var(--ifabsh-pagination-icon-prev, "❮");
}
.pagination-item.-next {
  min-width: fit-content;
  order: 4;
}
.pagination-item.-next::after {
  content: var(--ifabsh-pagination-icon-next, "❯");
}
.pagination-item.-info {
  --_pg-border-color: var(--ifabsh-pagination-info-border-color, transparent);
  display: none;
  order: 2;
  pointer-events: none;
  user-select: none;
}
.pagination-item.-delimiter {
  --_pg-border-color: var(--ifabsh-pagination-delimiter-border-color, transparent);
  pointer-events: none;
  user-select: none;
}
@media (hover: hover) {
  .pagination-item:not(span):hover {
    --_pg-background-color: var(--ifabsh-pagination-background-color-hover);
    --_pg-border-color: var(--ifabsh-pagination-border-color-hover);
    --_pg-color: var(--ifabsh-pagination-color-hover);
    text-decoration-line: none;
    z-index: 2;
  }
}
.pagination-item:focus {
  --_pg-background-color: var(--ifabsh-pagination-background-color);
  --_pg-border-color: var(--ifabsh-pagination-border-color);
  --_pg-color: var(--ifabsh-pagination-color);
  box-shadow: none;
  outline: none;
  text-decoration-line: none;
}
.pagination-item._focus-visible:focus, .pagination-item:focus-visible:focus {
  outline: var(--ifabsh-focus-outline-width, 1px) var(--ifabsh-focus-outline-style, dotted) var(--focus-outline-color, currentColor);
  outline-offset: var(--ifabsh-focus-outline-offset, 2px);
}
.pagination-item[aria-current=true] { /* stylelint-disable-line string-quotes */ }
.pagination-item[aria-current=true], .pagination-item[aria-current=true]:hover, .pagination-item[aria-current=true]:focus, .pagination-item[aria-current=true]:active {
  --_pg-background-color: var(--ifabsh-pagination-background-color-active);
  --_pg-border-color: var(--ifabsh-pagination-border-color-active);
  --_pg-color: var(--ifabsh-pagination-color-active);
  --focus-outline-color: var(--_pg-background-color);
}
.pagination-item[aria-disabled] {
  opacity: var(--ifabsh-pagination-disabled-opacity, var(--ifabsh-disabled-opacity, 0.4));
  pointer-events: none;
}

.pagination.-l {
  --ifabsh-pagination-font-size: var(--ifabsh-input-font-size-l, var(--ifabsh-font-size-l, 1.25rem));
  --ifabsh-pagination-height: var(--ifabsh-input-height-l, 50px);
}

.pagination.-s {
  --ifabsh-pagination-font-size: var(--ifabsh-input-font-size-s, var(--ifabsh-font-size-s, 0.75rem));
  --ifabsh-pagination-height: var(--ifabsh-input-height-s, 30px);
}

.pagination.-compact .pagination-item.-prev, .pagination.-compact .pagination-item.-next,
.pagination.-minimal .pagination-item.-prev,
.pagination.-minimal .pagination-item.-next {
  min-width: var(--ifabsh-pagination-height);
}
.pagination.-compact .pagination-item.-prev .pagination-item__label, .pagination.-compact .pagination-item.-next .pagination-item__label,
.pagination.-minimal .pagination-item.-prev .pagination-item__label,
.pagination.-minimal .pagination-item.-next .pagination-item__label {
  display: none;
}

.pagination.-compact {
  --ifabsh-pagination-gap: 0;
}
.pagination.-compact > .pagination-item:not(:first-child),
.pagination.-compact .pagination-list,
.pagination.-compact .pagination-list > li:not(:first-child) {
  margin-left: calc(var(--ifabsh-pagination-border-width) * -1);
}
.pagination.-compact .pagination-item:not(.-prev):not(.-next) { /* stylelint-disable-line selector-not-notation */
  border-radius: 0;
}
.pagination.-compact .pagination-item.-prev {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.pagination.-compact .pagination-item.-next {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.pagination.-minimal .pagination-list {
  display: none;
}
.pagination.-minimal .pagination-item.-info {
  display: list-item;
}

.pagination {
  --ifabsh-pagination-background-color: var(--wp-color-primary);
  --ifabsh-pagination-background-color-active: var(--wp-color-primary);
  --ifabsh-pagination-border-color: transparent;
  --ifabsh-pagination-border-color-active: transparent;
  --ifabsh-pagination-border-color-hover: transparent;
  --ifabsh-pagination-color: var(--wp-color-secondary);
  --ifabsh-pagination-color-active: var(--wp-color-secondary);
  --ifabsh-pagination-color-hover: var(--wp-color-secondary-lighten);
  --ifabsh-pagination-height: 28px;
  --wp-pagination-list-border-radius: 25px;
  --wp-pagination-list-height: 50px;
  --wp-pagination-list-padding-x: 20px;
  --wp-pagination-list-padding-y: calc((var(--wp-pagination-list-height) - var(--ifabsh-pagination-height)) / 2);
  background-color: var(--wp-color-primary);
  border: none;
  border-radius: var(--wp-pagination-list-border-radius);
  display: inline-flex;
  padding: var(--wp-pagination-list-padding-y) var(--wp-pagination-list-padding-x);
}
.pagination-list {
  --ifabsh-pagination-background-color: var(--wp-color-primary);
  --ifabsh-pagination-background-color-active: var(--wp-color-secondary);
  --ifabsh-pagination-background-color-hover: var(--wp-color-primary);
  --ifabsh-pagination-border-color: var(--wp-color-primary);
  --ifabsh-pagination-border-color-active: var(--wp-color-secondary);
  --ifabsh-pagination-border-color-hover: var(--wp-color-secondary);
  --ifabsh-pagination-color: var(--wp-color-secondary);
  --ifabsh-pagination-color-active: var(--wp-color-white);
  --ifabsh-pagination-color-hover: var(--wp-color-secondary);
  --ifabsh-pagination-height: 28px;
}
.pagination-item {
  font-weight: var(--wp-font-weight-bold);
}
.pagination-item:not(.-prev):not(.-next) {
  --ifabsh-pagination-border-radius: 50%;
}
.pagination-item.-prev, .pagination-item.-next {
  font-size: var(--wp-font-size-s);
}
.pagination-item.-prev {
  border-bottom-left-radius: var(--wp-pagination-list-border-radius);
  border-top-left-radius: var(--wp-pagination-list-border-radius);
}
.pagination-item.-next {
  border-bottom-right-radius: var(--wp-pagination-list-border-radius);
  border-top-right-radius: var(--wp-pagination-list-border-radius);
}

.pagination.-compact .pagination-item:not(.-prev):not(.-next) {
  border-radius: var(--ifabsh-pagination-border-radius);
}

.privacy-info {
  --ifabsh-privacy-info-background-color: var(--ifabsh-color-grey, #e8e8e8);
  --ifabsh-privacy-info-color: var(--ifabsh-color-text, #3e3e3e);
  --ifabsh-privacy-info-font-size: var(--ifabsh-font-size-s, 0.75rem);
  --ifabsh-privacy-info-gap: 0;
  --ifabsh-privacy-info-padding: var(--ifabsh-spacing-s, 0.5rem);
}
.privacy-info__container {
  align-items: center;
  background-color: var(--ifabsh-privacy-info-background-color);
  color: var(--ifabsh-privacy-info-color);
  display: flex;
  flex-direction: column;
  gap: var(--ifabsh-privacy-info-gap);
  max-height: 100%;
  text-align: center;
}
.privacy-info__text {
  font-size: var(--ifabsh-privacy-info-font-size);
  overflow-y: auto;
  overscroll-behavior: none;
  padding: var(--ifabsh-privacy-info-padding);
}
.privacy-info__text, .privacy-info__text a {
  color: currentColor;
  margin: 0;
}
.privacy-info__controls {
  padding: var(--ifabsh-privacy-info-padding);
}

.player .plyr {
  --plyr-color-main: var(--ifabsh-player-theme-color);
  --plyr-control-radius: var(--ifabsh-player-control-border-radius);
  --plyr-control-icon-size: var(--ifabsh-player-control-icon-size);
  --plyr-control-spacing: var(--ifabsh-player-control-gap);
  --plyr-progress-loading-size: var(--ifabsh-player-progress-loading-size);
  --plyr-range-thumb-height: var(--ifabsh-player-range-thumb-height);
  --plyr-range-thumb-shadow: none;
  --plyr-range-track-height: var(--ifabsh-player-range-track-height);
  --plyr-audio-controls-background: var(--ifabsh-player-audio-background-color);
  --plyr-audio-control-color: var(--ifabsh-player-audio-control-color);
  --plyr-video-background: var(--ifabsh-player-video-background-color);
  --plyr-video-controls-background: var(--ifabsh-player-video-controls-background-color);
  --plyr-video-control-color: var(--ifabsh-player-video-control-color);
  --plyr-video-control-color-hover: var(--ifabsh-player-video-control-color-hover);
  --plyr-video-control-background-hover: var(--ifabsh-player-video-control-background-color-hover);
}
.player .plyr__controls {
  gap: var(--plyr-control-spacing);
}
.player .plyr__controls__item {
  flex-grow: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0 var(--ifabsh-player-control-padding);
}
.player .plyr__controls__item.plyr__volume {
  max-width: none;
  min-width: 0;
  position: relative;
  width: auto;
}
.player .plyr__controls__item.plyr__progress__container {
  flex-grow: 1;
  padding: 0 var(--ifabsh-player-control-padding);
}
.player .plyr__controls__item.plyr__time {
  padding: 0 var(--ifabsh-player-control-padding);
}
.player .plyr__controls .plyr__control {
  font-size: var(--ifabsh-player-control-font-size);
  padding: var(--ifabsh-player-control-padding);
}
.player .plyr__progress__buffer::-webkit-progress-value {
  border-radius: var(--ifabsh-player-range-border-radius);
}
.player .plyr--full-ui input[type=range] { /* stylelint-disable-line string-quotes */ }
.player .plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  border-radius: var(--ifabsh-player-range-border-radius);
}
.player .plyr--full-ui input[type=range]::-webkit-slider-thumb {
  border-radius: var(--ifabsh-player-range-thumb-border-radius);
}
.player .plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  outline: var(--ifabsh-focus-outline-width, 1px) var(--ifabsh-focus-outline-style, dotted) var(--ifabsh-focus-outline-color, currentColor);
  outline-offset: var(--ifabsh-focus-outline-offset, 2px);
}
.player .plyr--audio {
  --plyr-range-thumb-background: var(--plyr-audio-control-color);
}
.player .plyr--audio .plyr__controls {
  border: var(--ifabsh-player-audio-border-width) solid var(--ifabsh-player-audio-border-color);
  padding: var(--ifabsh-player-audio-controls-padding);
}
.player .plyr--audio .plyr__controls__item.plyr__progress__container {
  padding: 0 var(--ifabsh-player-control-padding);
}
.player .plyr--video {
  --plyr-range-thumb-background: var(--plyr-video-control-color);
}
.player .plyr--video .plyr__controls {
  padding: var(--ifabsh-player-video-controls-padding);
}
.player .plyr--video .plyr__controls__item.plyr__time {
  text-shadow: 0 0 0.25em #000;
}

.player {
  --ifabsh-player-theme-color: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-player-controls-padding: var(--ifabsh-spacing-s, 0.5rem);
  --ifabsh-player-control-border-radius: 0px;
  --ifabsh-player-control-font-size: 1rem;
  --ifabsh-player-control-gap: 0;
  --ifabsh-player-control-icon-size: 1em;
  --ifabsh-player-control-padding: var(--ifabsh-spacing-s, 0.5rem);
  --ifabsh-player-progress-loading-size: 1rem;
  --ifabsh-player-range-border-radius: var(--ifabsh-player-control-border-radius);
  --ifabsh-player-range-thumb-border-radius: var(--ifabsh-player-control-border-radius);
  --ifabsh-player-range-thumb-height: 0.5rem;
  --ifabsh-player-range-track-height: 0.5rem;
  --ifabsh-player-audio-background-color: transparent;
  --ifabsh-player-audio-border-color: var(--ifabsh-color-border, #e8e8e8);
  --ifabsh-player-audio-border-width: var(--ifabsh-border-width, 1px);
  --ifabsh-player-audio-controls-padding: var(--ifabsh-player-controls-padding);
  --ifabsh-player-audio-control-color: var(--ifabsh-color-black, #000);
  --ifabsh-player-audio-control-color-hover: var(--ifabsh-player-audio-control-color);
  --ifabsh-player-audio-control-background-color-hover: var(--ifabsh-player-theme-color);
  --ifabsh-player-video-background-color: transparent;
  --ifabsh-player-video-controls-padding: var(--ifabsh-player-controls-padding);
  --ifabsh-player-video-controls-background-color: transparent;
  --ifabsh-player-video-control-color: var(--ifabsh-color-white, #fff);
  --ifabsh-player-video-control-color-hover: var(--ifabsh-player-video-control-color);
  --ifabsh-player-video-control-background-color-hover: var(--ifabsh-player-theme-color);
}
.player-caption {
  background-color: var(--ifabsh-player-caption-background-color, transparent);
  color: var(--ifabsh-player-caption-color, inherit);
  font-size: var(--ifabsh-player-caption-font-size, var(--ifabsh-font-size-s, 0.75rem));
  margin: var(--ifabsh-player-caption-margin, var(--ifabsh-spacing-s, 0.5rem)) 0 0;
  padding: var(--ifabsh-player-caption-padding, 0);
}
.player-caption > * {
  display: inline;
  margin: 0;
}
.player-caption > * + * {
  margin-left: var(--ifabsh-player-caption-copyright-gap, var(--ifabsh-spacing-s, 0.5rem));
}
.player-caption > .copyright::before {
  content: "("; /* stylelint-disable-line string-quotes */
}
.player-caption > .copyright::after {
  content: ")"; /* stylelint-disable-line string-quotes */
}

.player-video {
  --_plvi-poster-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-player-video-poster-duration, 0.15s));
}
.player-video .player-media {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}
.player-video .player-media::before {
  content: ""; /* stylelint-disable-line string-quotes */
  display: block;
  padding-top: var(--ifabsh-player-video-ratio, calc((9 / 16) * 100%));
}
.player-video .player-media [data-player-media], .player-video .player-media__poster, .player-video .player-media__player {
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.player-video .player-media__player > .plyr {
  height: 100%;
  width: 100%;
}
.player-video .player-media__poster {
  background-color: var(--ifabsh-player-video-background-color);
  opacity: 1;
  transition: var(--_plvi-poster-duration) opacity var(--_plvi-poster-duration) ease-in-out;
  z-index: 2;
}
.player-video .player-media__poster > *:not(.player-media__play) {
  height: 100%;
  width: 100%;
}
.player-video .player-media__poster > img {
  object-fit: cover;
}
.player-video .player-media__play {
  --ifabsh-icon-button-color: var(--ifabsh-player-video-control-color);
  --ifabsh-icon-button-color-hover: var(--ifabsh-player-video-control-color-hover);
  font-size: var(--ifabsh-player-control-font-size);
  left: 50%;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 2;
}
.player-video .player-media__play::after {
  --_offset: calc(var(--ifabsh-player-control-font-size) * -1);
  --_size: calc(100% + var(--ifabsh-player-control-font-size) * 2);
  content: ""; /* stylelint-disable-line string-quotes */
  display: block;
  height: var(--_size);
  left: var(--_offset);
  position: absolute;
  top: var(--_offset);
  width: var(--_size);
}
.player-video .player-media__play:focus {
  box-shadow: none;
  outline: none;
}
.player-video .player-media__play:focus, .player-video .player-media__play:hover {
  background-color: var(--ifabsh-player-video-control-background-color-hover);
}
.player-video .player-media__play._focus-visible:focus, .player-video .player-media__play:focus-visible:focus {
  outline: var(--ifabsh-focus-outline-width, 1px) var(--ifabsh-focus-outline-style, dotted) var(--ifabsh-focus-outline-color, currentColor);
  outline-offset: var(--ifabsh-focus-outline-offset, 2px);
}
.player-video .privacy-info {
  bottom: 0;
  display: none;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  z-index: 5;
}
.player-video[data-privacy] .player-media__play {
  display: none;
  pointer-events: none;
}
.player-video[data-privacy] .privacy-info {
  display: block;
  pointer-events: all;
}
.player-video.-initialized .player-media__poster {
  opacity: 0;
  pointer-events: none;
}
.player-video.-initialized .player-media .plyr__control--overlaid[data-plyr=play] { /* stylelint-disable-line string-quotes */
  display: none;
}

.player-video .player-media__play {
  --ifabsh-icon-button-background-color-hover: var(--wp-color-primary);
  --ifabsh-icon-button-color: var(--wp-color-primary);
  --ifabsh-icon-button-color-hover: var(--wp-color-white);
}

.privacy-info {
  --ifabsh-privacy-info-background-color: hsla(var(--wp-color-white-h), var(--wp-color-white-s), var(--wp-color-white-l), 0.8);
}
.privacy-info__container {
  align-self: flex-start;
}
.privacy-info__text {
  --wp-scrollbar-track-color: transparent;
}
.privacy-info__text::-webkit-scrollbar {
  height: var(--wp-scrollbar-size, 4px);
  width: var(--wp-scrollbar-size, 4px);
}
.privacy-info__text::-webkit-scrollbar-thumb {
  background: var(--wp-scrollbar-color, #000);
}
.privacy-info__text::-webkit-scrollbar-track {
  background: var(--wp-scrollbar-track-color, transparent);
}
.privacy-info__text {
  scrollbar-face-color: var(--wp-scrollbar-color, #000);
  scrollbar-track-color: var(--wp-scrollbar-track-color, transparent);
}
.player-video .privacy-info, .gmap .privacy-info {
  bottom: var(--ifabsh-privacy-info-padding);
  height: calc(100% - var(--ifabsh-privacy-info-padding) * 2);
  left: var(--ifabsh-privacy-info-padding);
  right: var(--ifabsh-privacy-info-padding);
  top: var(--ifabsh-privacy-info-padding);
}

.progress-bar {
  --ifabsh-progress-bar-border-radius: var(--ifabsh-border-radius, 0px);
  --ifabsh-progress-bar-height: var(--height, 1rem);
  --ifabsh-progress-bar-duration: 0.35s;
  --_prba-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-progress-bar-duration, var(--ifabsh-duration, 0.15s)));
  background-color: var(--ifabsh-progress-bar-track-color, var(--ifabsh-color-grey-light, #f1f1f1));
  border-radius: var(--ifabsh-progress-bar-border-radius);
  display: block;
  height: var(--ifabsh-progress-bar-height);
  overflow: hidden;
  position: relative;
}
.progress-bar__indicator {
  background-color: var(--ifabsh-progress-bar-color, var(--ifabsh-color-ui, #6176ff));
  border-radius: var(--ifabsh-progress-bar-border-radius);
  color: var(--ifabsh-progress-bar-label-color, var(--ifabsh-color-ui-contrast, #fff));
  font-size: var(--ifabsh-progress-bar-label-font-size, var(--ifabsh-font-size-xs, 0.5rem));
  height: 100%;
  line-height: var(--ifabsh-progress-bar-height);
  overflow: hidden;
  text-align: center;
  transition-duration: var(--_prba-duration);
  transition-property: background-color, color, width;
  transition-timing-function: cubic-bezier(0.36, 0.9, 0.35, 1);
  user-select: none;
  white-space: nowrap;
}

.progress-bar[data-indeterminate] .progress-bar__indicator {
  animation: progress-bar-indeterminate var(--ifabsh-progress-bar-indeterminate-duration, 2s) infinite linear;
  position: absolute;
}
.progress-bar[data-indeterminate] .progress-bar__label {
  display: none;
}

@keyframes progress-bar-indeterminate {
  0% {
    inset-inline-start: -50%;
    width: 50%;
  }
  75%, 100% {
    inset-inline-start: 100%;
    width: 50%;
  }
}
.progress-ring {
  --ifabsh-progress-ring-size: var(--size, 8rem);
  --ifabsh-progress-ring-track-width: 0.5rem;
  --_prri-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-progress-ring-duration, 0.35s));
  align-items: center;
  display: flex;
  height: var(--ifabsh-progress-ring-size);
  justify-content: center;
  position: relative;
  width: var(--ifabsh-progress-ring-size);
}
.progress-ring__image {
  height: 100%;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  width: 100%;
}
.progress-ring__track, .progress-ring__indicator {
  --_prri-radius: calc(var(--ifabsh-progress-ring-size) / 2 - var(--ifabsh-progress-ring-track-width) * 0.5);
  --_prri-circumference: calc(var(--_prri-radius) * 2 * 3.141592654); /* stylelint-disable-line number-max-precision */
  cx: calc(var(--ifabsh-progress-ring-size) / 2);
  cy: calc(var(--ifabsh-progress-ring-size) / 2);
  fill: none;
  r: var(--_prri-radius);
  stroke-width: var(--ifabsh-progress-ring-track-width);
}
.progress-ring__track {
  stroke: var(--ifabsh-progress-ring-track-color, var(--ifabsh-color-grey-light, #f1f1f1));
}
.progress-ring__indicator {
  stroke: var(--ifabsh-progress-ring-color, var(--ifabsh-color-ui, #6176ff));
  stroke-dasharray: var(--_prri-circumference) var(--_prri-circumference);
  stroke-dashoffset: calc(var(--_prri-circumference) - var(--percentage) * var(--_prri-circumference));
  stroke-linecap: var(--ifabsh-progress-ring-linecap, square);
  transition-duration: var(--_prri-duration);
  transition-property: stroke-dashoffset;
  transition-timing-function: cubic-bezier(0.36, 0.9, 0.35, 1);
}
.progress-ring__label {
  align-items: center;
  color: var(--ifabsh-progress-ring-label-color, currentColor);
  display: flex;
  font-size: var(--ifabsh-progress-ring-label-font-size, var(--ifabsh-font-size-s, 0.75rem));
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  user-select: none;
  width: 100%;
}

.reveal-password {
  --ifabsh-reveal-password-background-color: transparent;
  --ifabsh-reveal-password-background-color-active: var(--ifabsh-reveal-password-background-color);
  --ifabsh-reveal-password-color: var(--ifabsh-form-control-color, var(--ifabsh-input-color, #000));
  --ifabsh-reveal-password-color-active: var(--ifabsh-reveal-password-color);
  --ifabsh-reveal-password-font-size: var(--ifabsh-font-size-s);
  --ifabsh-reveal-password-offset: var(--ifabsh-input-padding, var(--ifabsh-spacing, 1rem));
  --ifabsh-reveal-password-opacity: 0.5;
  --ifabsh-reveal-password-opacity-active: 1;
  --ifabsh-reveal-duration: var(--ifabsh-form-control-duration, var(--ifabsh-input-duration, var(--ifabsh-duration, 0.15s)));
  --_repa-background-color: var(--ifabsh-reveal-password-background-color);
  --_repa-color: var(--ifabsh-reveal-password-color);
  --_repa-opacity: var(--ifabsh-reveal-password-opacity);
  --_repa-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-reveal-duration));
  display: block;
  position: relative;
  width: 100%;
  transition: background-color var(--_repa-duration) ease-in-out, color var(--_repa-duration) ease-in-out, border var(--_repa-duration) ease-in-out, box-shadow var(--_repa-duration) ease-in-out, outline var(--_repa-duration) ease-in-out;
}
.reveal-password .form-control {
  padding-right: calc(var(--ifabsh-reveal-password-offset) * 2 + var(--ifabsh-reveal-password-font-size)) !important; /* stylelint-disable-line declaration-no-important */
}
.reveal-password .icon-button {
  --ifabsh-icon-button-background-color: var(--ifabsh-reveal-password-background-color);
  --ifabsh-icon-button-color: var(--ifabsh-reveal-password-color);
  --ifabsh-icon-button-font-size: var(--ifabsh-reveal-password-font-size);
  opacity: var(--_repa-opacity);
  position: absolute;
  right: calc(var(--ifabsh-reveal-password-offset) - var(--ifabsh-icon-button-padding));
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  /* stylelint-disable string-quotes */
  /* stylelint-enable string-quotes */
}
.reveal-password .icon-button i::before {
  content: "\f070";
}
.reveal-password .icon-button[aria-pressed=true] {
  --_repa-background-color: var(--ifabsh-reveal-password-background-color-active);
  --_repa-color: var(--ifabsh-reveal-password-color-active);
  --_repa-opacity: var(--ifabsh-reveal-password-opacity-active);
}
.reveal-password .icon-button[aria-pressed=true] i::before {
  content: "\f06e";
}

.input-group > .reveal-password {
  flex: 1 1 auto;
  min-width: 0;
  width: 1%;
}
.input-group > .reveal-password:not(:first-child), .input-group > .reveal-password:not(:first-child) > .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.input-group > .reveal-password:not(:last-child), .input-group > .reveal-password:not(:last-child) > .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.scroll-progress {
  background-color: var(--ifabsh-scroll-progress-track-color, transparent);
  display: block;
  height: var(--ifabsh-scroll-progress-height, 1px);
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--ifabsh-scroll-progress-zindex, 50);
}
.scroll-progress > span {
  --_scpr-calc-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-scroll-progress-duration, 0.15s));
  background: var(--ifabsh-scroll-progress-color, var(--ifabsh-color-ui, #6176ff));
  display: block;
  height: 100%;
  transform: scaleX(0);
  transform-origin: 0 0 0;
  transition: transform var(--_scpr-calc-duration) ease;
  width: 100%;
}

.skip-to {
  --ifabsh-skip-to-background-color: var(--ifabsh-color-black, #000);
  --ifabsh-skip-to-border-radius: var(--ifabsh-border-radius, 0px);
  --ifabsh-skip-to-color: var(--ifabsh-color-white, #fff);
  --ifabsh-skip-to-duration: var(--ifabsh-duration, 0.15s);
  --ifabsh-skip-to-font-size: var(--ifabsh-font-size, 1rem);
  --ifabsh-skip-to-opacity: 0.75;
  --ifabsh-skip-to-padding-x: var(--ifabsh-spacing, 1rem);
  --ifabsh-skip-to-padding-y: var(--ifabsh-spacing-s, 0.5rem);
  --_skto-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-skipt-to-duration));
  background-color: var(--ifabsh-skip-to-background-color);
  border-radius: var(--ifabsh-skip-to-border-radius);
  color: var(--ifabsh-skip-to-color);
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  text-align: center;
  top: 0;
  transform: translate3d(-50%, -50%, 0);
  transition: var(--_skto-duration) opacity ease-in-out;
  z-index: var(--ifabsh-skip-to-zindex, 510);
}
.skip-to, .skip-to__link {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  line-height: 1;
  width: 1px;
}
.skip-to__link {
  color: inherit;
  position: absolute;
}
.skip-to__link:focus {
  clip: auto;
  color: inherit;
  display: inline-block;
  height: auto;
  padding: var(--ifabsh-skip-to-padding-y) var(--ifabsh-skip-to-padding-x);
  position: relative;
  text-decoration: none;
  width: auto;
}
.skip-to:focus-within {
  clip: auto;
  height: auto;
  left: 50%;
  opacity: var(--ifabsh-skip-to-opacity);
  top: 50%;
  white-space: normal;
  width: auto;
}

.swiper {
  border-radius: var(--wp-spacing);
}
.swiper-pagination-bullet {
  --swiper-pagination-bullet-inactive-color: var(--wp-color-primary);
}
.swiper-pagination.swiper-pagination-horizontal {
  border-radius: 0 0 0 var(--wp-spacing-xs);
}
.swiper-button-prev {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.swiper-button-next {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.slider {
  --ifabsh-slider-pagination-background-color: var(--wp-color-primary-lighten);
  --ifabsh-slider-pagination-item-height: 10px;
}
.slider img {
  border-radius: var(--wp-spacing);
}

.spinner {
  --ifabsh-spinner-color: currentColor;
  --ifabsh-spinner-size: 1em;
  animation: 1s linear infinite spin;
  border: var(--ifabsh-spinner-stroke-width, 2px) solid var(--ifabsh-spinner-track-color, transparent);
  border-radius: 50%;
  border-right-color: var(--ifabsh-spinner-color);
  border-top-color: var(--ifabsh-spinner-color);
  display: inline-block;
  height: var(--ifabsh-spinner-size);
  width: var(--ifabsh-spinner-size);
}
p .spinner {
  vertical-align: middle;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.stack {
  --ifabsh-stack-background-color: transparent;
  --ifabsh-stack-border-color: var(--ifabsh-color-border, #e8e8e8);
  --ifabsh-stack-border-width: var(--ifabsh-border-width, 1px);
  --ifabsh-stack-border-radius: var(--ifabsh-border-radius, 0px);
  --ifabsh-stack-gap: var(--ifabsh-spacing, 1rem);
  --ifabsh-stack-padding: var(--ifabsh-stack-gap);
  background-color: var(--ifabsh-stack-background-color);
}
.stack__container {
  border: var(--ifabsh-stack-border-width) solid var(--ifabsh-stack-border-color);
  border-radius: var(--ifabsh-stack-border-radius);
  padding: var(--ifabsh-stack-padding);
}
.stack__container > * {
  border-radius: 0;
  margin: 0;
  max-width: none;
  width: 100%;
}
.stack__container > * + * {
  margin-top: var(--ifabsh-stack-gap);
}
.stack__container > * + *::before {
  background-color: var(--ifabsh-stack-border-color);
  content: ""; /* stylelint-disable-line string-quotes */
  display: block;
  height: var(--ifabsh-stack-border-width);
  margin-bottom: var(--ifabsh-stack-gap);
  margin-left: calc(var(--ifabsh-stack-gap) * -1);
  margin-right: calc(var(--ifabsh-stack-gap) * -1);
  overflow: hidden;
}
.stack__container > :first-child {
  border-top-left-radius: var(--ifabsh-stack-border-radius);
  border-top-right-radius: var(--ifabsh-stack-border-radius);
}
.stack__container > :last-child {
  border-bottom-left-radius: var(--ifabsh-stack-border-radius);
  border-bottom-right-radius: var(--ifabsh-stack-border-radius);
}

.tag {
  --ifabsh-tag-background-color-alpha: 0.1;
  --ifabsh-tag-background-color: hsla(var(--ifabsh-color-black-h), var(--ifabsh-color-black-s), var(--ifabsh-color-black-l), var(--ifabsh-tag-background-color-alpha));
  --ifabsh-tag-border-color: var(--ifabsh-color-border, #e8e8e8);
  --ifabsh-tag-border-radius: var(--ifabsh-border-radius, 0px);
  --ifabsh-tag-border-width: var(--ifabsh-border-width, 1px);
  --ifabsh-tag-color: var(--ifabsh-color-text, #3e3e3e);
  --ifabsh-tag-font-size: var(--ifabsh-font-size-s, 0.75rem);
  --ifabsh-tag-font-weight: normal;
  --ifabsh-tag-gap: var(--ifabsh-spacing-s, 0.5rem);
  --ifabsh-tag-height: calc(var(--ifabsh-input-height, 40px) * 0.8);
  --ifabsh-tag-padding: var(--ifabsh-spacing, 1rem);
  --ifabsh-tag-duration: 0.125s;
  --_tag-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-tag-duration));
  align-items: center;
  background-color: var(--ifabsh-tag-background-color);
  border: var(--ifabsh-tag-border-width) solid var(--ifabsh-tag-border-color);
  border-radius: var(--ifabsh-tag-border-radius);
  color: var(--ifabsh-tag-color);
  cursor: default;
  display: inline-flex;
  flex: 0 0 auto;
  font-size: var(--ifabsh-tag-font-size);
  gap: var(--ifabsh-tag-gap);
  height: var(--ifabsh-tag-height);
  line-height: calc(var(--ifabsh-tag-height) - var(--ifabsh-tag-border-width) * 2);
  overflow: hidden;
  padding: 0 var(--ifabsh-tag-padding);
  transition-duration: var(--_tag-duration);
  transition-property: background-color, border, color, opacity;
  transition-timing-function: ease-in-out;
  user-select: none;
  white-space: nowrap;
}
.tag:focus-within, .tag.focus-visible:focus {
  outline: var(--ifabsh-focus-outline-width) var(--ifabsh-focus-outline-style) var(--focus-outline-color, var(--ifabsh-focus-outline-color));
  outline-offset: var(--ifabsh-focus-outline-offset);
  pointer-events: auto;
}
.tag__label {
  order: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tag > .icon-button {
  --ifabsh-icon-button-color: var(--ifabsh-tag-button-color, inherit);
  --ifabsh-icon-button-color-hover: var(--ifabsh-tag-button-color-hover, inherit);
  --ifabsh-icon-button-padding: 0;
  order: 2;
}
.tag > .icon-button:focus, .tag > .icon-button._focus-visible {
  outline: none;
}
.tag > .icon-button::after {
  content: ""; /* stylelint-disable-line string-quotes */
  height: var(--ifabsh-tag-height);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: var(--ifabsh-tag-height);
}
.tag.-remove {
  opacity: 0;
}

.tag.-primary {
  --ifabsh-tag-background-color: hsla(var(--ifabsh-color-ui-h), var(--ifabsh-color-ui-s), var(--ifabsh-color-ui-l), var(--ifabsh-tag-background-color-alpha));
  --ifabsh-tag-border-color: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-tag-color: var(--ifabsh-color-ui, #6176ff);
}

.tag.-success {
  --ifabsh-tag-background-color: hsla(var(--ifabsh-color-success-h), var(--ifabsh-color-success-s), var(--ifabsh-color-success-l), var(--ifabsh-tag-background-color-alpha));
  --ifabsh-tag-border-color: var(--ifabsh-color-success, #15c182);
  --ifabsh-tag-color: var(--ifabsh-color-success, #15c182);
}

.tag.-info {
  --ifabsh-tag-background-color: hsla(var(--ifabsh-color-info-h), var(--ifabsh-color-info-s), var(--ifabsh-color-info-l), var(--ifabsh-tag-background-color-alpha));
  --ifabsh-tag-border-color: var(--ifabsh-color-info, #2db5cd);
  --ifabsh-tag-color: var(--ifabsh-color-info, #2db5cd);
}

.tag.-warning {
  --ifabsh-tag-background-color: hsla(var(--ifabsh-color-warning-h), var(--ifabsh-color-warning-s), var(--ifabsh-color-warning-l), var(--ifabsh-tag-background-color-alpha));
  --ifabsh-tag-border-color: var(--ifabsh-color-warning, #fca311);
  --ifabsh-tag-color: var(--ifabsh-color-warning, #fca311);
}

.tag.-danger {
  --ifabsh-tag-background-color: hsla(var(--ifabsh-color-danger-h), var(--ifabsh-color-danger-s), var(--ifabsh-color-danger-l), var(--ifabsh-tag-background-color-alpha));
  --ifabsh-tag-border-color: var(--ifabsh-color-danger, #fb3e4e);
  --ifabsh-tag-color: var(--ifabsh-color-danger, #fb3e4e);
}

.tag.-l {
  --ifabsh-tag-font-size: var(--ifabsh-font-size, 1rem);
  --ifabsh-tag-height: calc(var(--ifabsh-input-height-l) * 0.8);
  --ifabsh-tag-padding: calc(var(--ifabsh-spacing, 1rem) * 1.25);
}

.tag.-s {
  --ifabsh-tag-font-size: var(--ifabsh-font-size-xs, 0.5rem);
  --ifabsh-tag-height: calc(var(--ifabsh-input-height-s, 30px) * 0.8);
  --ifabsh-tag-padding: var(--ifabsh-spacing-s, 0.5rem);
}

.tag.-pill {
  --ifabsh-tag-border-radius: 10rem;
}

.tag-group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--ifabsh-tag-group-gap, var(--ifabsh-spacing-s, 0.5rem));
}

.table {
  --ifabsh-table-border-color: var(--ifabsh-color-border, #e8e8e8);
  --ifabsh-table-border-style: solid;
  --ifabsh-table-border-width: var(--ifabsh-border-width, 1px);
  --ifabsh-table-color: currentColor;
  --ifabsh-table-header-border-width: calc(var(--ifabsh-table-border-width) * 2);
  --ifabsh-table-cell-padding-x: var(--ifabsh-spacing, 1rem);
  --ifabsh-table-cell-padding-y: var(--ifabsh-spacing, 1rem);
  --ifabsh-table-striped-background-color: var(--ifabsh-color-grey-light, #f1f1f1);
  --ifabsh-table-striped-color: var(--ifabsh-table-color);
  border-collapse: collapse;
  border-color: var(--ifabsh-table-border-color);
  caption-side: bottom;
  color: var(--ifabsh-table-color);
  width: 100%;
}
.table > :not(:first-child) {
  border-top: initial;
}
.table > :not(caption) > * > * {
  box-shadow: inset 0 0 0 9999px var(--ifabsh-table-accent-background-color, transparent);
  color: var(--ifabsh-table-accent-color, currentColor);
}
.table tr {
  border-color: var(--ifabsh-table-border-color);
}
.table th,
.table td {
  padding: var(--_tbl-cell-padding-y, var(--ifabsh-table-cell-padding-y)) var(--_tbl-cell-padding-x, var(--ifabsh-table-cell-padding-x));
  vertical-align: top;
}
.table th > :first-child,
.table td > :first-child {
  margin-top: 0;
}
.table th > :last-child,
.table td > :last-child {
  margin-bottom: 0;
}
.table > caption {
  font-size: var(--ifabsh-table-caption-font-size, var(--ifabsh-font-size-s, 0.75rem));
  padding: var(--ifabsh-table-caption-padding-y, var(--ifabsh-table-cell-padding-y)) var(--ifabsh-table-caption-padding-x, var(--ifabsh-table-cell-padding-x));
  text-align: center;
}
.table > thead > tr > * {
  background-color: var(--ifabsh-table-header-background-color, transparent);
  color: var(--ifabsh-table-header-color, var(--ifabsh-color-ui, #6176ff));
  font-size: var(--ifabsh-table-header-font-size, 1rem);
  font-weight: var(--ifabsh-table-header-font-weight, normal);
}
.table > thead > tr:last-child {
  border-bottom-color: var(--ifabsh-table-header-border-color, var(--ifabsh-color-ui, #6176ff));
  border-bottom-style: var(--ifabsh-table-header-border-style, var(--ifabsh-table-border-style));
  border-bottom-width: var(--ifabsh-table-header-border-width); /* stylelint-disable-line */
}
.table > tfoot > tr > * {
  background-color: var(--ifabsh-table-footer-background-color, transparent);
  color: var(--ifabsh-table-footer-color, var(--ifabsh-table-color));
  font-size: var(--ifabsh-table-footer-font-size, 1rem);
  font-weight: var(--ifabsh-table-footer-font-weight, normal);
}
.table > tfoot > tr:first-child {
  border-top-color: var(--ifabsh-table-footer-border-color, var(--ifabsh-color-ui, #6176ff));
  border-top-style: var(--ifabsh-table-footer-border-style, var(--ifabsh-table-border-style));
  border-top-width: var(--ifabsh-table-footer-border-width, var(--ifabsh-table-header-border-width)); /* stylelint-disable-line */
}

.table.-s {
  --_tbl-cell-padding-x: calc(var(--ifabsh-table-cell-padding-x) / 2);
  --_tbl-cell-padding-y: calc(var(--ifabsh-table-cell-padding-y) / 2);
}

.table.-bordered > :not(caption) > * {
  border-width: var(--ifabsh-table-border-width) 0;
}
.table.-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table.-striped > tbody > tr:nth-of-type(odd) > * {
  --ifabsh-table-accent-background-color: var(--ifabsh-table-striped-background-color);
  color: var(--ifabsh-table-striped-color);
}

.table-container {
  overflow-x: auto;
  scrollbar-width: none;
}
.table-container > table {
  margin: 0;
}

.table {
  --ifabsh-table-header-color: var(--wp-color-secondary);
  --ifabsh-table-header-font-weight: var(--wp-font-weight-bold);
  --ifabsh-table-header-background-color: var(--wp-color-primary);
}

.table-container {
  --wp-scrollbar-track-color: transparent;
}
.table-container::-webkit-scrollbar {
  height: var(--wp-scrollbar-size, 4px);
  width: var(--wp-scrollbar-size, 4px);
}
.table-container::-webkit-scrollbar-thumb {
  background: var(--wp-scrollbar-color, #000);
}
.table-container::-webkit-scrollbar-track {
  background: var(--wp-scrollbar-track-color, transparent);
}
.table-container {
  scrollbar-face-color: var(--wp-scrollbar-color, #000);
  scrollbar-track-color: var(--wp-scrollbar-track-color, transparent);
}

.table table thead > tr > th,
.table table thead > tr > tr {
  background: var(--ifabsh-table-header-background-color);
  border-bottom: 2px solid var(--wp-color-secondary);
  color: var(--ifabsh-table-header-color);
}
.table table tbody > tr > th,
.table table tbody > tr > td {
  border-bottom: 2px solid var(--wp-color-grey);
}
.table table tbody > tr > th {
  font-weight: var(--wp-font-weight-bold);
}

.tab-group {
  --ifabsh-tabgroup-background-color: transparent;
  --ifabsh-tabgroup-border-color: var(--ifabsh-color-border, #e8e8e8);
  --ifabsh-tabgroup-border-radius: var(--ifabsh-border-radius, 0px);
  --ifabsh-tabgroup-border-width: var(--ifabsh-border-width, 1px);
  --ifabsh-tabgroup-padding-x: var(--ifabsh-spacing, 1rem);
  --ifabsh-tabgroup-padding-y: var(--ifabsh-spacing, 1rem);
  --ifabsh-tabgroup-duration: var(--ifabsh-duration, 0.15s);
  --ifabsh-tabgroup-tab-background-color: transparent;
  --ifabsh-tabgroup-tab-background-color-active: transparent;
  --ifabsh-tabgroup-tab-color: var(--ifabsh-color-text, #3e3e3e);
  --ifabsh-tabgroup-tab-color-active: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-tabgroup-tab-font-size: var(--ifabsh-font-size, 1rem);
  --ifabsh-tabgroup-tab-gap: var(--ifabsh-spacing-s, 0.5rem);
  --_tabg-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-tabgroup-duration));
  background-color: var(--ifabsh-tabgroup-background-color);
  display: flex;
  flex-direction: column;
}
.tab-group__nav {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none;
}
.tab-group__nav::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.tab-group__nav-container {
  order: 1;
}
.tab-group__body {
  border: solid var(--ifabsh-tabgroup-border-width) var(--ifabsh-tabgroup-border-color);
  border-radius: var(--ifabsh-tabgroup-border-radius);
  order: 2;
}
.tab-group__tabs {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  font-size: var(--ifabsh-tabgroup-tab-font-size);
  gap: var(--ifabsh-tabgroup-tab-gap);
  position: relative;
}
.tab-group__tabs.nav {
  --ifabsh-nav-gap: var(--ifabsh-tabgroup-tab-gap);
}
.tab-group__indicator {
  position: absolute;
  transition: var(--_tabg-duration) transform ease-in-out, var(--_tabg-duration) width ease-in-out;
}

.tab-group .tab {
  align-items: center;
  background-color: var(--ifabsh-tabgroup-tab-background-color);
  border: 0 none;
  border-radius: var(--ifabsh-tabgroup-border-radius);
  color: var(--ifabsh-tabgroup-tab-color);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--ifabsh-tabgroup-tab-font-size);
  height: auto;
  line-height: normal;
  padding: var(--ifabsh-tabgroup-tab-padding-y, var(--ifabsh-tabgroup-padding-y)) var(--ifabsh-tabgroup-tab-padding-x, var(--ifabsh-tabgroup-padding-x));
  transition: all var(--_tabg-duration) ease-in-out;
  user-select: none;
  white-space: nowrap;
}
.tab-group .tab[aria-selected=true] { /* stylelint-disable-line string-quotes */
  background-color: var(--ifabsh-tabgroup-tab-background-color-active);
  color: var(--ifabsh-tabgroup-tab-color-active);
}
.tab-group .tab:focus {
  box-shadow: none;
  outline: none;
}

.tab-group .tab-panel {
  transition: opacity var(--_tabg-duration) ease-in-out;
}
.tab-group .tab-panel__content {
  padding: var(--ifabsh-tabgroup-panel-padding-y, var(--ifabsh-tabgroup-padding-y)) var(--ifabsh-tabgroup-panel-padding-x, var(--ifabsh-tabgroup-padding-x));
}
.tab-group .tab-panel__content > :first-child:not(.row) {
  margin-top: 0;
}
.tab-group .tab-panel__content > :last-child:not(.row) {
  margin-bottom: 0;
}

.tab-group.-tabs-top .tab-group__indicator, .tab-group.-tabs-bottom .tab-group__indicator {
  left: 0;
  width: 0;
}
.tab-group.-tabs-top .tab {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.tab-group.-tabs-top .tab-group__nav-container {
  order: 1;
}
.tab-group.-tabs-top .tab-group__body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-width: 0;
  order: 2;
}
.tab-group.-tabs-top .tab-group__tabs {
  border-bottom: var(--ifabsh-tabgroup-border-width) solid var(--ifabsh-tabgroup-border-color);
}
.tab-group.-tabs-top .tab-group__indicator {
  border-bottom: var(--ifabsh-tabgroup-border-width) solid var(--ifabsh-tabgroup-tab-color-active);
  bottom: calc(var(--ifabsh-tabgroup-border-width) * -1);
}
.tab-group.-tabs-bottom .tab {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.tab-group.-tabs-bottom .tab-group__nav-container {
  order: 2;
}
.tab-group.-tabs-bottom .tab-group__body {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-width: 0;
  order: 1;
}
.tab-group.-tabs-bottom .tab-group__tabs {
  border-top: var(--ifabsh-tabgroup-border-width) solid var(--ifabsh-tabgroup-border-color);
}
.tab-group.-tabs-bottom .tab-group__indicator {
  border-top: var(--ifabsh-tabgroup-border-width) solid var(--ifabsh-tabgroup-tab-color-active);
  top: calc(var(--ifabsh-tabgroup-border-width) * -1);
}
.tab-group.-tabs-left, .tab-group.-tabs-right {
  flex-direction: row;
}
.tab-group.-tabs-left .tab-group__nav, .tab-group.-tabs-right .tab-group__nav {
  min-height: 100%;
  overflow-x: auto;
}
.tab-group.-tabs-left .tab-group__body, .tab-group.-tabs-right .tab-group__body {
  flex: 1 1 auto;
}
.tab-group.-tabs-left .tab-group__indicator, .tab-group.-tabs-right .tab-group__indicator {
  height: 0;
  top: 0;
}
.tab-group.-tabs-left .tab-group__tabs, .tab-group.-tabs-right .tab-group__tabs {
  border: 0 none;
  flex: 0 0 auto;
  flex-direction: column;
}
.tab-group.-tabs-left .tab {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.tab-group.-tabs-left .tab-group__nav-container {
  order: 1;
}
.tab-group.-tabs-left .tab-group__body {
  border-bottom-left-radius: 0;
  border-left-width: 0;
  border-top-left-radius: 0;
  order: 2;
}
.tab-group.-tabs-left .tab-group__tabs {
  border-right: var(--ifabsh-tabgroup-border-width) solid var(--ifabsh-tabgroup-border-color);
}
.tab-group.-tabs-left .tab-group__indicator {
  border-right: var(--ifabsh-tabgroup-border-width) solid var(--ifabsh-tabgroup-tab-color-active);
  right: calc(var(--ifabsh-tabgroup-border-width) * -1);
}
.tab-group.-tabs-right .tab {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.tab-group.-tabs-right .tab-group__nav-container {
  order: 2;
}
.tab-group.-tabs-right .tab-group__body {
  border-bottom-right-radius: 0;
  border-right-width: 0;
  border-top-right-radius: 0;
  order: 1;
}
.tab-group.-tabs-right .tab-group__tabs {
  border-left: var(--ifabsh-tabgroup-border-width) solid var(--ifabsh-tabgroup-border-color);
}
.tab-group.-tabs-right .tab-group__indicator {
  border-left: var(--ifabsh-tabgroup-border-width) solid var(--ifabsh-tabgroup-tab-color-active);
  left: calc(var(--ifabsh-tabgroup-border-width) * -1);
}

.tab-group {
  --ifabsh-tabgroup-border-width: 2px;
  --ifabsh-tabgroup-tab-color: var(--wp-color-secondary);
  --ifabsh-tabgroup-tab-color-active: var(--ifabsh-tabgroup-tab-color);
  --ifabsh-tabgroup-tab-font-size: var(--wp-font-size-s);
  --ifabsh-tabgroup-tab-padding-x: calc(var(--wp-spacing-s) * 3);
  --ifabsh-tabgroup-tab-padding-y: var(--wp-spacing-s);
  --ifabsh-tabgroup-panel-padding-x: var(--wp-spacing-l);
  --ifabsh-tabgroup-panel-padding-y: var(--wp-spacing-l);
  --wp-tabgroup-tab-indicator-color: var(--wp-color-primary);
}
.tab-group__indicator {
  border-color: var(--wp-tabgroup-tab-indicator-color) !important;
  border-width: calc(var(--ifabsh-tabgroup-border-width) + 1px) !important;
}

.tab-group .tab {
  font-weight: var(--wp-font-weight-semibold);
  line-height: 1.9;
}

.tab-group.-tabs-bottom .tab-group__body {
  border-left: none;
  border-right: none;
  border-top: none;
}
.tab-group.-tabs-left .tab-group__body {
  border-bottom: none;
  border-right: none;
  border-top: none;
}
.tab-group.-tabs-right .tab-group__body {
  border-bottom: none;
  border-left: none;
  border-top: none;
}
.tab-group.-tabs-top .tab-group__body {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.text-block {
  --ifabsh-text-block-gap: var(--ifabsh-spacing, 1rem);
  --ifabsh-text-block-column-media-size: 100%;
  background-color: var(--ifabsh-text-block-background-color, transparent);
  padding: var(--ifabsh-text-block-padding, 0);
  position: relative;
}
.text-block-text {
  hyphens: manual;
  order: 2;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.text-block-text > :first-child {
  margin-top: 0;
}
.text-block-text > :last-child {
  margin-bottom: 0;
}
.text-block-media {
  order: 1;
}
.text-block-media > * {
  display: block;
  margin: 0 auto;
  width: 100%;
}
@media (min-width: 768px) {
  .text-block {
    --ifabsh-text-block-column-media-size: 50%;
  }
}

.text-block[class*=-media] { /* stylelint-disable-line string-quotes */ }
.text-block[class*=-media] > .text-block__inner {
  align-items: var(--ifabsh-text-block-align-items, flex-start);
  display: flex;
  flex-wrap: wrap;
  gap: var(--ifabsh-text-block-gap);
}

/* stylelint-disable string-quotes */
.text-block[class*=-media-left] .text-block-text, .text-block[class*=-media-left] .text-block-media,
.text-block[class*=-media-right] .text-block-text,
.text-block[class*=-media-right] .text-block-media {
  flex-grow: 1;
  flex-shrink: 0;
}
.text-block[class*=-media-left] .text-block-text,
.text-block[class*=-media-right] .text-block-text {
  flex-basis: calc(100% - var(--ifabsh-text-block-column-media-size) - var(--ifabsh-text-block-gap));
}
.text-block[class*=-media-left] .text-block-media,
.text-block[class*=-media-right] .text-block-media {
  flex-basis: calc(var(--ifabsh-text-block-column-media-size) - var(--ifabsh-text-block-gap));
  min-width: var(--ifabsh-text-block-column-media-min-width, 0);
}

/* stylelint-enable string-quotes */
/* stylelint-disable string-quotes */
.text-block[class*=-media-above] > .text-block__inner,
.text-block[class*=-media-below] > .text-block__inner {
  flex-direction: column;
}
.text-block[class*=-media-above] .text-block-text, .text-block[class*=-media-above] .text-block-media,
.text-block[class*=-media-below] .text-block-text,
.text-block[class*=-media-below] .text-block-media {
  width: 100%;
}
.text-block[class*=-media-above] .text-block-media > *,
.text-block[class*=-media-below] .text-block-media > * {
  max-width: var(--ifabsh-text-block-vertical-media-max-width, none);
}

/* stylelint-enable string-quotes */
/* stylelint-disable string-quotes */
.text-block[class*=-media-right] .text-block-text,
.text-block[class*=-media-below] .text-block-text {
  order: 1;
}
.text-block[class*=-media-right] .text-block-media,
.text-block[class*=-media-below] .text-block-media {
  order: 2;
}

/* stylelint-enable string-quotes */
.text-block {
  --ifabsh-text-block-gap: var(--wp-block-element-margin);
  --ifabsh-text-block-column-media-size: 40%;
}
.toast {
  --bs-toast-border-color: var(--ifabsh-toast-border-color, var(--ifabsh-color-black));
  --bs-toast-border-radius: var(--ifabsh-toast-border-radius, var(--ifabsh-spacing-s));
  --bs-toast-border-width: var(--ifabsh-toast-border-width, 1px);
  --bs-toast-bg: var(--ifabsh-toast-background-color, var(--ifabsh-color-white));
  --bs-toast-box-shadow: var(--ifabsh-toast-box-shadow, none);
  --bs-toast-color: var(--ifabsh-toast-color, var(--ifabsh-color-black));
  --bs-toast-font-size: var(--ifabsh-toast-font-size, 1rem);
  --bs-toast-header-bg: var(--ifabsh-toast-header-background-color, var(--ifabsh-color-white));
  --bs-toast-header-border-color: var(--ifabsh-toast-header-border-color, var(--ifabsh-color-black));
  --bs-toast-header-color: var(--ifabsh-toast-header-color, var(--ifabsh-color-black));
  --bs-toast-max-width: var(--ifabsh-toast-max-width, 350px);
  --bs-toast-padding-x: var(--ifabsh-toast-padding-x, var(--ifabsh-spacing-s));
  --bs-toast-padding-y: var(--ifabsh-toast-padding-y, var(--ifabsh-spacing-s));
  --bs-toast-spacing: var(--ifabsh-toast-spacing, var(--ifabsh-spacing));
  --bs-toast-zindex: var(--ifabsh-toast-zindex, 1000);
}
.toast-header {
  display: flex;
  gap: var(--ifabsh-spacing-s);
}
.toast-header .btn-close {
  --bs-btn-close-bg: "";
  --bs-btn-close-color: inherit;
  --bs-btn-close-opacity: 1;
  height: auto;
  margin-left: auto;
  width: auto;
}
.toast-header .btn-close::before {
  content: "\f00d";
  display: inline-block;
  font-family: "Font Awesome 6 Pro";
}
.toast-footer {
  display: flex;
  gap: var(--ifabsh-spacing-s);
  justify-content: space-between;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
}

.toast {
  --ifabsh-toast-border-color: var(--wp-color-secondary);
  --ifabsh-toast-background-color: var(--wp-color-secondary);
  --ifabsh-toast-box-shadow: var(--wp-box-shadow-size) var(--wp-box-shadow-color);
  --ifabsh-toast-color: var(--wp-color-secondary-contrast);
  --ifabsh-toast-header-background-color: var(--wp-color-secondary);
  --ifabsh-toast-header-border-color: var(--wp-color-secondary-contrast);
  --ifabsh-toast-header-color: var(--wp-color-secondary-contrast);
  --ifabsh-toast-padding-x: var(--wp-spacing);
}

.toast.-primary {
  --ifabsh-toast-background-color: var(--wp-color-white, #fff);
  --ifabsh-toast-border-color: var(--wp-color-primary, #343434);
  --ifabsh-toast-color: var(--wp-color-text);
  --ifabsh-toast-header-background-color: var(--wp-color-primary, #343434);
  --ifabsh-toast-header-border-color: var(--wp-color-primary, #fff);
  --ifabsh-toast-header-color: var(--wp-color-primary-contrast, #fff);
}

.toast.-secondary {
  --ifabsh-toast-background-color: var(--wp-color-white, #fff);
  --ifabsh-toast-border-color: var(--wp-color-secondary, #82bb25);
  --ifabsh-toast-color: var(--wp-color-text);
  --ifabsh-toast-header-background-color: var(--wp-color-secondary, #82bb25);
  --ifabsh-toast-header-border-color: var(--wp-color-secondary, #000);
  --ifabsh-toast-header-color: var(--wp-color-secondary-contrast, #000);
}

.toast.-info {
  --ifabsh-toast-background-color: var(--wp-color-white, #fff);
  --ifabsh-toast-border-color: var(--wp-color-info, #6176ff);
  --ifabsh-toast-color: var(--wp-color-text);
  --ifabsh-toast-header-background-color: var(--wp-color-info, #6176ff);
  --ifabsh-toast-header-border-color: var(--wp-color-info, #000);
  --ifabsh-toast-header-color: var(--wp-color-info-contrast, #000);
}

.toast.-warning {
  --ifabsh-toast-background-color: var(--wp-color-white, #fff);
  --ifabsh-toast-border-color: var(--wp-color-warning, #fca311);
  --ifabsh-toast-color: var(--wp-color-text);
  --ifabsh-toast-header-background-color: var(--wp-color-warning, #fca311);
  --ifabsh-toast-header-border-color: var(--wp-color-warning, #000);
  --ifabsh-toast-header-color: var(--wp-color-warning-contrast, #000);
}

.toast.-danger {
  --ifabsh-toast-background-color: var(--wp-color-white, #fff);
  --ifabsh-toast-border-color: var(--wp-color-danger, #fb3e4e);
  --ifabsh-toast-color: var(--wp-color-text);
  --ifabsh-toast-header-background-color: var(--wp-color-danger, #fb3e4e);
  --ifabsh-toast-header-border-color: var(--wp-color-danger, #fff);
  --ifabsh-toast-header-color: var(--wp-color-danger-contrast, #fff);
}

.toast.-success {
  --ifabsh-toast-background-color: var(--wp-color-white, #fff);
  --ifabsh-toast-border-color: var(--wp-color-success, #82bb25);
  --ifabsh-toast-color: var(--wp-color-text);
  --ifabsh-toast-header-background-color: var(--wp-color-success, #82bb25);
  --ifabsh-toast-header-border-color: var(--wp-color-success, #000);
  --ifabsh-toast-header-color: var(--wp-color-success-contrast, #000);
}

.alert {
  --ifabsh-alert-border-color: var(--ifabsh-alert-accent-color);
  --ifabsh-alert-border-radius: var(--wp-spacing-xs);
  --ifabsh-alert-border-width: 2px;
  --ifabsh-alert-font-size: var(--wp-font-size-xs);
  --ifabsh-alert-icon-font-size: 1.25rem;
}

.avatar {
  --ifabsh-avatar-border-width: 0;
}
.avatar-group .avatar {
  --ifabsh-avatar-border-color: var(--wp-color-white);
  --ifabsh-avatar-border-width: var(--ifabsh-border-width);
}

.details-group {
  --ifabsh-details-group-gap: var(--wp-spacing);
}

.icon-button {
  --ifabsh-icon-button-font-weight: var(--wp-font-weight-bold);
}
.icon-button.-active {
  --ifabsh-icon-button-color: var(--wp-color-primary);
}
.icon-button.-underline {
  text-decoration: underline;
}

.slider {
  --ifabsh-slider-pagination-item-width: var(--ifabsh-slider-pagination-item-height);
}
.slider .swiper-pagination-bullet-active {
  width: calc(var(--ifabsh-slider-pagination-item-width) * 4);
}

.stack {
  --ifabsh-stack-padding: calc(var(--wp-spacing-xs) * 7);
  --ifabsh-stack-gap: var(--ifabsh-stack-padding);
}

.header {
  --wp-header-margin: var(--wp-spacing-l);
  color: var(--wp-header-color, currentColor);
  display: flex;
  flex-direction: column;
  font-size: var(--wp-header-font-size, 1rem);
  gap: var(--wp-header-gap, var(--wp-spacing-xs));
}
.header > time {
  color: var(--wp-header-time-color, currentColor);
  display: none;
  font-size: var(--wp-header-time-font-size, inherit);
  order: 3;
}
.header__title {
  margin: 0;
  order: 1;
}
.header__subtext {
  color: var(--wp-header-subtext-color, currentColor);
  font-size: var(--wp-header-subtext-font-size, inherit);
  margin: 0;
  order: 2;
}
.header:not(:first-child) {
  margin-top: var(--wp-header-margin);
}
.header:not(:last-child) {
  margin-bottom: var(--wp-header-margin);
}
@media (min-width: 992px) {
  .header:not(:last-child) {
    margin-bottom: calc(var(--wp-header-margin) * 2);
  }
}

.audio-content .player {
  margin-bottom: var(--wp-block-element-margin);
}
.audio-content > :last-child {
  margin-bottom: 0;
}

.client {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--wp-spacing-l);
}
@media (min-width: 576px) {
  .client {
    flex-direction: row;
  }
}
.client__text {
  flex: 1;
  order: 2;
}
.client__media {
  max-width: 150px;
  order: 1;
}

.video {
  --wp-video-content-max-width: 36rem;
}
.video-media, .video-body {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--wp-video-content-max-width);
}
.video-body {
  padding-top: var(--wp-spacing-xl);
}
.video-body > :last-child {
  margin-bottom: 0;
}
.video-media .player-video .player-media {
  --_video-ply-width: var(--_video-ply-width-overwrite, 100vw);
  left: 50%;
  margin-left: calc(var(--_video-ply-width) / 2 * -1);
  position: relative;
  width: var(--_video-ply-width);
}
.video-media .player-video .plyr__controls {
  margin: 0 auto;
  max-width: var(--wp-video-content-max-width);
  padding: var(--wp-spacing) 0;
  width: calc(100% - var(--wp-page-offset) * 2);
}

.section.-video {
  --fs-section-background-color: var(--wp-color-primary);
  color: var(--wp-color-primary-contrast);
  padding-bottom: var(--wp-spacing-xl);
  padding-top: 0;
}
.section.-video::before {
  display: none;
}

.article {
  --wp-article-gap: var(--wp-spacing-l);
  --wp-article-sidebar-gap: var(--wp-spacing-l);
  --wp-article-sidebar-sidebar-width: 33%;
  display: flex;
  flex-direction: column;
  gap: var(--wp-article-gap);
  position: relative;
}
.article-header {
  display: flex;
  flex-direction: column;
  gap: var(--wp-article-header-gap, var(--wp-spacing));
}
.article-header > * {
  margin: 0;
}
.article-body {
  display: flex;
  flex-wrap: wrap;
  gap: var(--wp-article-sidebar-gap);
}
.article-body > .content {
  flex-basis: 100%;
  flex-grow: 666;
  min-width: 100%;
  order: 1;
}
.article-body > .sidebar {
  flex-basis: 100%;
  flex-grow: 1;
  min-width: 0;
  order: 2;
  width: 100%;
}
@media (min-width: 768px) {
  .article-body {
    --_article-sidebar-width: calc(var(--wp-article-sidebar-sidebar-width) + var(--wp-article-sidebar-gap));
    align-items: flex-start;
    flex-wrap: nowrap;
  }
  .article-body > .content,
  .article-body > .sidebar {
    position: sticky;
    top: var(--global-scroll-top-offset);
  }
  .article-body > .content {
    flex-basis: calc(100% - var(--_article-sidebar-width));
    min-width: calc(100% - var(--_article-sidebar-width));
  }
  .article-body > .sidebar {
    flex-basis: var(--_article-sidebar-width);
    width: var(--_article-sidebar-width);
  }
}
.article-footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .article {
    --wp-article-header-gap: var(--wp-spacing-l);
    --wp-article-teaser-padding: var(--wp-spacing-l);
  }
}
@media (min-width: 992px) {
  .article {
    --wp-article-sidebar-gap: var(--wp-spacing-xl);
  }
}

.article-header__teaser {
  font-size: var(--wp-font-size-l);
}
.article-header__meta {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.article-header__meta .time__time {
  display: none;
}
.article-header__meta .article-category + .time {
  margin-right: auto;
}
.article-header__meta .article-category + .time::before {
  content: "·"; /* stylelint-disable-line string-quotes */
  margin: 0 var(--wp-spacing-s);
}

.article-section > * {
  margin-bottom: 0;
  margin-top: 0;
}
.article-section > *:not(:first-child) {
  margin-top: var(--wp-block-element-margin);
}
.article-section > .ce:not(:first-child) {
  margin-top: var(--wp-content-element-margin);
}

.icon-box {
  --wp-icon-box-gap: calc(var(--wp-spacing-xs) * 3);
  --wp-icon-box-icon-background-color: var(--wp-color-primary);
  --wp-icon-box-icon-color: var(--wp-color-secondary);
  --wp-icon-box-icon-font-size: 75px;
  --wp-icon-box-icon-dimension: 208px;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--wp-icon-box-gap);
}
.icon-box__icon {
  background: var(--wp-icon-box-icon-background-color);
  border-radius: 50%;
  color: var(--wp-icon-box-icon-color);
  font-size: var(--wp-icon-box-icon-font-size);
  height: 0;
  padding-top: var(--wp-icon-box-icon-dimension);
  position: relative;
  width: var(--wp-icon-box-icon-dimension);
}
.icon-box__icon > i {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.icon-box__label {
  font-weight: var(--wp-font-weight-bold);
}

.icon-link {
  --wp-icon-link-gap: var(--wp-spacing-s);
  background: var(--wp-icon-link-background-color, transparent);
  border: none;
  color: var(--wp-icon-link-color, inherit);
  display: flex;
  font-size: var(--wp-icon-link-font-size, inherit);
  gap: var(--wp-icon-link-gap);
  padding: var(--wp-icon-link-padding, transparent);
  text-decoration: var(--wp-icon-link-text-decoration, underline);
}
.icon-link__icon {
  color: var(--wp-icon-link-icon-color-hover, inherit);
}
.icon-link__label {
  color: var(--wp-icon-link-label-color, inherit);
  font-weight: var(--wp-icon-link-font-weight, inherit);
}
.icon-link:hover, .icon-link:focus {
  color: var(--wp-icon-link-color-hover, inherit);
}
.icon-link:hover .icon-link__icon, .icon-link:focus .icon-link__icon {
  color: var(--wp-icon-link-icon-color-hover, inherit);
}
.icon-link:hover .icon-link__label, .icon-link:focus .icon-link__label {
  color: var(--wp-icon-link-label-color-hover, inherit);
}

/*!
 * @npm/sc-cookie
 * Cookie Opt-In-Out Plugin zur Unterstützung bei der Umsetzung der DSGVO bzw. ePrivacy-Verordnung.
 *
 * Released on January 29, 2020-2021
 *
 * @copyright 2020-2021 i-fabrik GmbH
 * @author Heiko Pfefferkorn <heiko,pfefferkorn@gmail.com>
 * @version 0.2.6
 */
.sc-cookie {
  --sccookie-duration-multiplier:1;
  --sccookie-color-black-h:0;
  --sccookie-color-black-s:0%;
  --sccookie-color-black-l:39%;
  --sccookie-color-black:hsl(var(--sccookie-color-black-h),var(--sccookie-color-black-s),var(--sccookie-color-black-l));
  --sccookie-color-error-h:0;
  --sccookie-color-error-s:52%;
  --sccookie-color-error-l:63%;
  --sccookie-color-error:hsl(var(--sccookie-color-error-h),var(--sccookie-color-error-s),var(--sccookie-color-error-l));
  --sccookie-color-primary-h:232;
  --sccookie-color-primary-s:100%;
  --sccookie-color-primary-l:69%;
  --sccookie-color-primary:hsl(var(--sccookie-color-primary-h),var(--sccookie-color-primary-s),var(--sccookie-color-primary-l));
  --sccookie-color-success-h:83;
  --sccookie-color-success-s:46%;
  --sccookie-color-success-l:60%;
  --sccookie-color-success:hsl(var(--sccookie-color-success-h),var(--sccookie-color-success-s),var(--sccookie-color-success-l));
  --sccookie-color-indeterminate-h:50;
  --sccookie-color-indeterminate-s:46%;
  --sccookie-color-indeterminate-l:60%;
  --sccookie-color-indeterminate:hsl(var(--sccookie-color-indeterminate-h),var(--sccookie-color-indeterminate-s),var(--sccookie-color-indeterminate-l));
  --sccookie-color-white-h:0;
  --sccookie-color-white-s:0%;
  --sccookie-color-white-l:100%;
  --sccookie-color-white:hsl(var(--sccookie-color-white-h),var(--sccookie-color-white-s),var(--sccookie-color-white-l));
  --sccookie-font-size:1rem;
  --sccookie-font-size-s:0.9375em;
  --sccookie-font-size-l:1.125em;
  --sccookie-focus-ring-h:var(--sccookie-color-primary-h);
  --sccookie-focus-ring-s:var(--sccookie-color-primary-s);
  --sccookie-focus-ring-l:var(--sccookie-color-primary-l);
  --sccookie-focus-ring-alpha:0.125;
  --sccookie-focus-ring-width:4px;
  --sccookie-focus-ring-box-shadow:0 0 0 var(--sccookie-focus-ring-width) hsla(var(--sccookie-focus-ring-h),var(--sccookie-focus-ring-s),var(--sccookie-focus-ring-l),var(--sccookie-focus-ring-alpha));
  --sccookie-transition-switch:calc(var(--sccookie-duration-multiplier)*0.1s);
  --sccookie-transition-collape:calc(var(--sccookie-duration-multiplier)*0.25s);
  --sccookie-transition-expand:calc(var(--sccookie-duration-multiplier)*0.3s);
  --sccookie-transition-layer:calc(var(--sccookie-duration-multiplier)*0.25s);
  --sccookie-transition-button:calc(var(--sccookie-duration-multiplier)*0.2s);
  --sccookie-spacing:1rem;
  --sccookie-spacing-xxs:calc(var(--sccookie-spacing)/8);
  --sccookie-spacing-xs:calc(var(--sccookie-spacing)/4);
  --sccookie-spacing-s:calc(var(--sccookie-spacing)/2);
  --sccookie-spacing-l:calc(var(--sccookie-spacing)*2);
  --sccookie-scrollbar-size:4px;
  --sccookie-scrollbar-thumb-color:var(--sccookie-color-black);
  --sccookie-scrollbar-track-color:transparent;
  --sccookie-background-color:var(--sccookie-color-white);
  --sccookie-border-color:hsl(var(--sccookie-color-black-h),var(--sccookie-color-black-s),90%);
  --sccookie-border-radius:4px;
  --sccookie-border-width:1px;
  --sccookie-box-shadow:0 0 var(--sccookie-spacing-s) 0 hsla(var(--sccookie-color-black-h),var(--sccookie-color-black-s),var(--sccookie-color-black-l),0.2);
  --sccookie-line-height:1.5;
  --sccookie-link-color:inherit;
  --sccookie-link-text-decoration:underline;
  --sccookie-position-offset:var(--sccookie-spacing);
  --sccookie-z-index:9990;
}

@media (update: slow), screen and (prefers-reduced-motion: reduce) {
  .sc-cookie {
    --sccookie-duration-multiplier:0;
  }
}
.sc-cookie {
  background-color: var(--sccookie-background-color);
  box-shadow: var(--sccookie-box-shadow);
  color: hsl(var(--sccookie-color-black-h), var(--sccookie-color-black-s), 55%);
  font-size: var(--sccookie-font-size);
  line-height: var(--sccookie-line-height);
  outline: 0;
  overflow: hidden;
  z-index: var(--sccookie-z-index);
}

.sc-cookie, .sc-cookie * {
  box-sizing: border-box;
}

.sc-cookie a, .sc-cookie button {
  position: relative;
  transition: var(--sccookie-transition-button) all;
}

.sc-cookie a:focus, .sc-cookie a:hover, .sc-cookie button:focus, .sc-cookie button:hover {
  cursor: pointer;
  outline: 0 none;
}

.sc-cookie a {
  color: var(--sccookie-link-color);
  -webkit-text-decoration: var(--sccookie-link-text-decoration);
  text-decoration: var(--sccookie-link-text-decoration);
}

.sc-cookie a:focus, .sc-cookie a:hover {
  color: var(--sccookie-link-color);
}

.sc-cookie p {
  padding: 0;
}

.sc-cookie-error {
  color: var(--sccookie-color-error);
}

.sc-cookie__text {
  color: currentColor;
  margin: 0;
}

.sc-cookie__text > * {
  margin: 0;
}

.sc-cookie__header {
  color: var(--sccookie-color-black);
  font-size: var(--sccookie-header-font-size, var(--sccookie-font-size-l));
  margin: 0;
  text-transform: none;
}

.sc-cookie__header + .sc-cookie__text {
  margin-top: var(--sccookie-spacing-s);
}

.sc-cookie__content {
  scrollbar-face-color: var(--sccookie-scrollbar-thumb-color);
  scrollbar-track-color: var(--sccookie-scrollbar-track-color);
  padding: var(--sccookie-spacing);
}

.sc-cookie__content::-webkit-scrollbar {
  height: var(--sccookie-scrollbar-size);
  width: var(--sccookie-scrollbar-size);
}

.sc-cookie__content::-webkit-scrollbar-thumb {
  background: var(--sccookie-scrollbar-thumb-color);
}

.sc-cookie__content::-webkit-scrollbar-track {
  background: var(--sccookie-scrollbar-track-color);
}

.sc-cookie__controls {
  display: grid;
  grid-template-columns: repeat(1, minmax(auto, 1fr));
  justify-items: center;
  padding: var(--sccookie-spacing);
}

@media (min-width: 480px) {
  .sc-cookie__controls {
    grid-template-columns: repeat(2, minmax(auto, 1fr));
  }
}
.sc-cookie__footer {
  background-color: var(--sccookie-footer-background-color, hsla(var(--sccookie-color-black-h), var(--sccookie-color-black-s), var(--sccookie-color-black-l), 10%));
  color: var(--sccookie-footer-color, hsl(var(--sccookie-color-black-h), var(--sccookie-color-black-s), 60%));
  font-size: var(--sccookie-footer-font-size, var(--sccookie-font-size-s));
  padding: var(--sccookie-spacing-s) var(--sccookie-spacing);
  text-align: center;
}

.sc-cookie-btn {
  align-items: stretch;
  background-color: var(--sccookie-button-background-color, var(--sccookie-color-white));
  border: var(--sccookie-button-border-width, var(--sccookie-border-width)) solid var(--sccookie-button-border-color, var(--sccookie-border-color));
  border-radius: var(--sccookie-button-border-radius, var(--sccookie-border-radius));
  color: var(--sccookie-button-color, var(--sccookie-color-black)) !important;
  display: inline-flex;
  font-size: var(--sccookie-button-font-size, var(--sccookie-font-size));
  height: var(--sccookie-button-height, 2.5rem);
  justify-content: center;
  line-height: calc(var(--sccookie-button-height, 2.5rem) - var(--sccookie-button-border-width, var(--sccookie-border-width)) * 2);
  margin: 0;
  max-width: 100%;
  min-width: 0;
  padding: 0 var(--sccookie-spacing);
  position: relative;
  text-transform: var(--sccookie-button-text-transform, none);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
}

.sc-cookie-btn i, .sc-cookie-btn svg {
  pointer-events: none;
  position: relative;
}

.sc-cookie-btn i {
  font-size: inherit;
}

.sc-cookie-btn svg {
  height: 1em;
  width: 1em;
}

.sc-cookie-btn__label {
  order: 2;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
}

.sc-cookie-btn__label i, .sc-cookie-btn__label svg {
  top: -1px;
}

.sc-cookie-btn__label svg path {
  fill: currentColor;
}

.sc-cookie-btn__prefix, .sc-cookie-btn__suffix {
  display: flex;
  flex: 0 0 auto;
}

.sc-cookie-btn__prefix {
  order: 1;
}

.sc-cookie-btn__prefix:first-child {
  margin-left: calc(var(--sccookie-spacing-xs) * -1);
  margin-right: var(--sccookie-spacing-xs);
}

.sc-cookie-btn__suffix {
  order: 3;
}

.sc-cookie-btn__suffix:last-child {
  margin-left: var(--sccookie-spacing-xs);
  margin-right: calc(var(--sccookie-spacing-xs) * -1);
}

.sc-cookie-btn:focus:not([disabled]), .sc-cookie-btn:hover:not([disabled]) {
  --sccookie-button-background-color:var(--sccookie-button-background-color-hover,hsl(var(--sccookie-color-primary-h),var(--sccookie-color-primary-s),98%));
  --sccookie-button-border-color:var(--sccookie-button-border-color-hover,var(--sccookie-color-primary));
  --sccookie-button-color:var(--sccookie-button-color-hover,var(--sccookie-color-primary));
  z-index: 1;
}

.sc-cookie-btn:focus:not([disabled]) {
  box-shadow: var(--sccookie-focus-ring-box-shadow);
}

.sc-cookie-btn[disabled] {
  pointer-events: none;
}

.sc-cookie-btn.-accept {
  --sccookie-button-background-color:var(--sccookie-button-accept-background-color,var(--sccookie-color-primary));
  --sccookie-button-border-color:var(--sccookie-button-accept-border-color,var(--sccookie-color-primary));
  --sccookie-button-color:var(--sccookie-button-accept-color,var(--sccookie-color-white));
  min-width: 50%;
}

.sc-cookie-btn.-accept:focus:not([disabled]), .sc-cookie-btn.-accept:hover:not([disabled]) {
  --sccookie-button-background-color:var(--sccookie-button-accept-background-color-hover,var(--sccookie-color-primary));
  --sccookie-button-border-color:var(--sccookie-button-accept-border-color-hover,var(--sccookie-color-primary));
  --sccookie-button-color:var(--sccookie-button-accept-color-hover,var(--sccookie-color-white));
}

.sc-cookie-btn.-save {
  min-width: 50%;
}

.sc-cookie-btn.-edit, .sc-cookie-btn.-essential {
  --sccookie-button-border-color:transparent;
  --sccookie-button-font-size:var(--sccookie-font-size-s);
  padding-left: var(--sccookie-spacing-s);
  padding-right: var(--sccookie-spacing-s);
}

.sc-cookie-btn.-essential {
  --sccookie-button-color:var(--sccookie-color-primary);
}

.sc-cookie-btn.-close {
  --sccookie-button-background-color:var(--sccookie-button-close-background-color,transparent);
  --sccookie-button-border-color:var(--sccookie-button-close-border-color,transparent);
  padding: 0;
  width: var(--sccookie-button-height, 2.5rem);
}

.sc-cookie-switch {
  line-height: 1em;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
}

.sc-cookie-switch__text {
  color: var(--sccookie-switch-label-color, var(--sccookie-color-black));
  cursor: pointer;
  display: block;
  line-height: calc(var(--sccookie-switch-size, 1rem) + var(--sccookie-switch-border-width, var(--sccookie-border-width)) * 2);
  overflow: hidden;
  padding: 0 0 0 calc(var(--sccookie-switch-size, 1rem) * 2 + var(--sccookie-spacing));
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sc-cookie-switch__text > span[data-is="0"] {
  display: block;
}

.sc-cookie-switch__text > span[data-is="1"] {
  display: none;
}

.sc-cookie-switch__text:after, .sc-cookie-switch__text:before {
  border-radius: var(--sccookie-switch-size, 1rem);
  border: var(--sccookie-switch-border-width, var(--sccookie-border-width)) solid var(--sccookie-switch-border-color, hsl(var(--sccookie-color-black-h), var(--sccookie-color-black-s), 80%));
  content: "";
  display: inline-block;
  font-size: var(--sccookie-switch-size, 1rem);
  left: 0;
  margin: 0;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition-property: all;
  transition-duration: var(--sccookie-transition-switch);
}

.sc-cookie-switch__text:before {
  background-color: var(--sccookie-switch-background-color, var(--sccookie-color-white));
  height: var(--sccookie-switch-size, 1rem);
  width: calc(var(--sccookie-switch-size, 1rem) * 2);
  z-index: 1;
}

.sc-cookie-switch__text:after {
  background-color: var(--sccookie-switch-color, var(--sccookie-color-white));
  height: calc(var(--sccookie-switch-size, 1rem) + var(--sccookie-switch-border-width, var(--sccookie-border-width)) * 2);
  width: calc(var(--sccookie-switch-size, 1rem) + var(--sccookie-switch-border-width, var(--sccookie-border-width)) * 2);
  z-index: 2;
}

.sc-cookie-switch__input {
  opacity: 0;
  outline: none;
  position: absolute;
  z-index: -1;
}

.sc-cookie-switch__input:focus:not([disabled]) ~ .sc-cookie-switch__text:after, .sc-cookie-switch__input:hover:not([disabled]) ~ .sc-cookie-switch__text:after {
  --sccookie-switch-border-color:var(--sccookie-switch-color-on,var(--sccookie-color-primary));
}

.sc-cookie-switch__input:focus:not([disabled]) ~ .sc-cookie-switch__text:after {
  box-shadow: var(--sccookie-focus-ring-box-shadow);
}

.sc-cookie-switch__input:checked ~ .sc-cookie-switch__text > span[data-is="0"] {
  display: none;
}

.sc-cookie-switch__input:checked ~ .sc-cookie-switch__text > span[data-is="1"] {
  display: block;
}

.sc-cookie-switch__input:checked ~ .sc-cookie-switch__text:after {
  --sccookie-switch-color:var(--sccookie-switch-color-on,var(--sccookie-color-primary));
  left: var(--sccookie-switch-size, 1rem);
}

.sc-cookie-switch__input:indeterminate ~ .sc-cookie-switch__text:after {
  --sccookie-switch-color:var(--sccookie-switch-color-indeterminate,var(--sccookie-color-indeterminate));
  left: calc(var(--sccookie-switch-size, 1rem) - var(--sccookie-switch-border-width, var(--sccookie-border-width)) - var(--sccookie-switch-size, 1rem) / 2);
}

.sc-cookie-switch__input.disabled ~ .sc-cookie-switch__text, .sc-cookie-switch__input[disabled] ~ .sc-cookie-switch__text {
  cursor: not-allowed;
}

.sc-cookie-switch__input.disabled ~ .sc-cookie-switch__text:after, .sc-cookie-switch__input[disabled] ~ .sc-cookie-switch__text:after {
  --sccookie-switch-color:var(--sccookie-switch-color-disabled,hsl(var(--sccookie-color-primary-h),var(--sccookie-color-primary-s),85%));
}

.sc-cookie-table {
  padding: 0;
  width: 100%;
}

.sc-cookie-table > caption {
  border-top: 1px solid hsl(var(--sccookie-color-black-h), var(--sccookie-color-black-s), 90%);
  caption-side: top;
  color: var(--sccookie-color-black);
  padding: var(--sccookie-spacing-xs) 0;
}

.sc-cookie-table tr > * {
  font-weight: 400;
  padding: var(--sccookie-spacing-xs) 0;
  vertical-align: top;
}

.sc-cookie-table tr > :first-child {
  padding-left: 0;
}

.sc-cookie-table tr > :last-child {
  padding-right: 0;
}

.sc-cookie-table tr > th {
  color: var(--sccookie-color-black);
  white-space: nowrap;
  width: 8rem;
}

.sc-cookie-table tr > td {
  color: currentColor;
}

.sc-cookie-category {
  align-items: center;
  background-color: transparent;
  display: grid;
  grid-template-areas: "switch trigger" "content content";
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 1fr auto;
  transition: background-color var(--sccookie-transition-collape);
}

.sc-cookie-category + .sc-cookie-category {
  margin-top: var(--sccookie-spacing-xxs);
}

.sc-cookie-category:first-child {
  border-top-left-radius: var(--sccookie-category-border-radius, var(--sccookie-border-radius));
  border-top-right-radius: var(--sccookie-category-border-radius, var(--sccookie-border-radius));
}

.sc-cookie-category:last-child {
  border-bottom-left-radius: var(--sccookie-category-border-radius, var(--sccookie-border-radius));
  border-bottom-right-radius: var(--sccookie-category-border-radius, var(--sccookie-border-radius));
}

.sc-cookie-category__switch {
  grid-area: switch;
  margin: var(--sccookie-spacing);
}

.sc-cookie-category__trigger {
  background-color: transparent;
  border: 0;
  color: currentColor;
  grid-area: trigger;
  margin-right: var(--sccookie-spacing-s);
  padding: 0 var(--sccookie-spacing-s);
}

.sc-cookie-category__trigger i, .sc-cookie-category__trigger svg {
  transition: transform var(--sccookie-transition-button), border-top-color var(--sccookie-transition-button);
  transform: scaleX(1);
  transform-origin: center;
}

.sc-cookie-category__trigger[aria-expanded=true] {
  color: currentColor;
}

.sc-cookie-category__trigger[aria-expanded=true] i, .sc-cookie-category__trigger[aria-expanded=true] svg {
  transform: scaleY(-1);
}

.sc-cookie-category__content {
  grid-area: content;
  display: none;
  flex-basis: 100%;
  font-size: var(--sccookie-font-size-s);
  max-height: 0;
  overflow: hidden;
  padding: 0 var(--sccookie-spacing) var(--sccookie-spacing);
}

.sc-cookie-category__content > * {
  margin: 0;
}

.sc-cookie-category__content > * + * {
  margin-top: var(--sccookie-spacing);
}

.sc-cookie-category:focus-within, .sc-cookie-category:hover {
  background-color: var(--sccookie-category-background-color-hover, hsla(var(--sccookie-color-black-h), var(--sccookie-color-black-s), var(--sccookie-color-black-l), 3%));
}

.sc-cookie-category.-shown {
  background-color: var(--sccookie-category-background-color-shown, hsla(var(--sccookie-color-black-h), var(--sccookie-color-black-s), var(--sccookie-color-black-l), 3%));
  position: relative;
  z-index: 1;
}

.sc-cookie-category.-shown .sc-cookie-category__content {
  display: block;
  max-height: 999vh;
}

.sc-cookie-banner {
  bottom: 0;
  left: 0;
  margin: 0 auto;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  transition: bottom var(--sccookie-transition-layer), opacity var(--sccookie-transition-layer);
  width: 100%;
  will-change: transform, opacity;
  z-index: -1;
}

.sc-cookie-banner .sc-cookie__content {
  padding-bottom: var(--sccookie-spacing-s);
}

.sc-cookie-banner .sc-cookie-btn.-accept {
  margin-bottom: var(--sccookie-spacing-s);
}

.sc-cookie-banner .sc-cookie-btn:not(.-accept) {
  width: 100%;
}

.sc-cookie-banner.-shown {
  opacity: 1;
  pointer-events: all;
  z-index: var(--sccookie-z-index);
}

@media (min-width: 480px) {
  .sc-cookie-banner {
    border-radius: var(--sccookie-layer-border-radius, var(--sccookie-border-radius));
    bottom: var(--sccookie-position-offset);
    max-width: var(--sccookie-banner-max-width, 32rem);
  }
  .sc-cookie-banner .sc-cookie__controls {
    grid-template-columns: repeat(2, 1fr);
  }
  .sc-cookie-banner .sc-cookie-btn {
    max-width: 100%;
  }
  .sc-cookie-banner .sc-cookie-btn.-accept {
    grid-column-end: 3;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-row-start: 1;
    justify-self: center;
    min-width: 10rem;
  }
}
.sc-cookie-preferences {
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  max-height: var(--sccookie-preferences-max-height, 100vh);
  margin: 0 auto;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  transition: bottom var(--sccookie-transition-layer), opacity var(--sccookie-transition-layer), top var(--sccookie-transition-layer), transform var(--sccookie-transition-layer);
  width: 100%;
  will-change: transform, opacity;
  z-index: -1;
}

@supports (touch-action: manipulation) {
  .sc-cookie-preferences {
    touch-action: manipulation;
  }
}
.sc-cookie-preferences .sc-cookie__content {
  flex: 0 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 0;
}

.sc-cookie-preferences .sc-cookie__controls {
  grid-template-columns: repeat(2, 1fr);
}

.sc-cookie-preferences .sc-cookie-btn.-accept {
  justify-self: start;
  width: calc(100% - var(--sccookie-spacing-s));
}

.sc-cookie-preferences .sc-cookie-btn.-save {
  justify-self: end;
  width: calc(100% - var(--sccookie-spacing-s));
}

.sc-cookie-preferences .sc-cookie-btn.-close {
  background-color: hsla(var(--sccookie-color-white-h), var(--sccookie-color-white-s), var(--sccookie-color-white-l), 90%);
  position: absolute;
  right: var(--sccookie-scrollbar-size);
  top: var(--sccookie-scrollbar-size);
  z-index: 10;
}

.sc-cookie-preferences .sc-cookie-btn.-close[disabled] {
  display: none;
}

@media (min-width: 600px) {
  .sc-cookie-preferences .sc-cookie-btn.-accept {
    justify-self: end;
    margin-right: var(--sccookie-spacing-s);
    width: auto;
  }
  .sc-cookie-preferences .sc-cookie-btn.-save {
    justify-self: start;
    margin-left: var(--sccookie-spacing-s);
    width: auto;
  }
}
.sc-cookie-preferences .sc-cookie-categories {
  margin: var(--sccookie-spacing) calc(var(--sccookie-spacing) * -1) 0;
}

@media (min-width: 600px) {
  .sc-cookie-preferences {
    border-radius: var(--sccookie-layer-border-radius, var(--sccookie-border-radius));
    bottom: auto;
    max-height: calc(var(--sccookie-preferences-max-height, 100vh) - var(--sccookie-position-offset) * 2);
    max-width: var(--sccookie-preferences-max-width, 40rem);
    top: 50%;
    transform: translateY(-50%);
  }
}
.sc-cookie-preferences.-shown {
  opacity: 1;
  pointer-events: all;
  z-index: calc(var(--sccookie-z-index) + 2);
}

button.sc-cookie-trigger {
  background-color: transparent;
  border: 0;
  border-radius: var(--sccookie-trigger-border-radius, 100%);
  bottom: var(--sccookie-position-offset);
  box-shadow: none;
  color: var(--sccookie-trigger-color, var(--sccookie-color-primary));
  height: var(--sccookie-trigger-size, 2rem);
  left: var(--sccookie-position-offset);
  line-height: var(--sccookie-trigger-size, 2rem);
  margin: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: fixed;
  transition: all var(--sccookie-transition-layer);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  width: var(--sccookie-trigger-size, 2rem);
  z-index: -1;
}

button.sc-cookie-trigger svg {
  display: block;
  height: 100%;
  width: 100%;
}

button.sc-cookie-trigger svg path {
  fill: currentColor;
}

button.sc-cookie-trigger:focus:not([disabled]), button.sc-cookie-trigger:hover:not([disabled]) {
  --sccookie-trigger-color:var(--sccookie-trigger-color-hover,var(--sccookie-color-primary));
  cursor: pointer;
  outline: 0 none;
}

button.sc-cookie-trigger:focus:not([disabled]) {
  box-shadow: var(--sccookie-focus-ring-box-shadow);
}

button.sc-cookie-trigger.-shown {
  opacity: 1;
  pointer-events: all;
  z-index: calc(var(--sccookie-z-index) + 1);
}

.sc-cookie {
  --sccookie-color-primary-h: var(--wp-color-primary-h);
  --sccookie-color-primary-s: var(--wp-color-primary-s);
  --sccookie-color-primary-l: var(--wp-color-primary-l);
  --sccookie-font-size: 0.875rem;
  --sccookie-z-index: 10000;
  --sccookie-footer-background-color: var(--wp-color-primary);
  --sccookie-footer-color: var(--wp-color-white);
  --sccookie-trigger-size: 2.25rem;
}
.sc-cookie__footer {
  --sccookie-link-color: var(--wp-color-white);
}
.sc-cookie-table tr > th {
  background-color: transparent;
  font-size: inherit;
}

button.sc-cookie-trigger {
  background-color: var(--wp-color-white);
}
button.sc-cookie-trigger svg {
  height: 90%;
  margin: 0 auto;
  width: 90%;
}

button[data-trigger=consent-settings] {
  background-color: transparent;
  border: 0;
  color: inherit;
  font-weight: inherit;
  padding: 0;
}

.standalone-page .sc-cookie-trigger, .standalone-page .sc-cookie-banner {
  display: none !important;
}

.step-navigation {
  display: flex;
  flex-wrap: wrap;
  gap: var(--wp-spacing);
  justify-content: space-between;
}

.account-nav {
  --ifabsh-nav-font-size: var(--wp-font-size-l);
  --ifabsh-nav-link-padding-x: 0;
}
.account-nav .nav-list {
  flex-direction: column;
}

.login .form .step {
  display: flex;
  flex-wrap: wrap;
  gap: var(--wp-spacing);
  justify-content: space-between;
  margin-bottom: var(--wp-spacing);
}
.login .form .step .form-group {
  flex: 100%;
}
.login .form .step .-pwforget {
  margin-right: auto;
}

#page-login .tab-group {
  --ifabsh-tabgroup-tab-background-color: var(--wp-color-primary-lighten);
  --ifabsh-tabgroup-tab-background-color-active: var(--wp-color-primary);
  --ifabsh-tabgroup-tab-color: var(--wp-color-text);
  --ifabsh-tabgroup-tab-color-active: var(--wp-color-white);
  --ifabsh-tabgroup-tab-font-size: var(--wp-font-size-l);
  --ifabsh-tabgroup-background-color: var(--wp-color-primary-lighten);
  --ifabsh-tabgroup-border-color: var(--ifabsh-tabgroup-background-color);
  --ifabsh-tabgroup-border-width: 0;
}
#page-login .tab-group__tabs {
  gap: 0;
}
#page-login .tab-group__tabs .tab {
  flex: 1;
}

[data-ajax-content-custom][data-is-rendering],
[data-ajax-content=form][data-is-rendering] {
  position: relative;
}
[data-ajax-content-custom][data-is-rendering]::before, [data-ajax-content-custom][data-is-rendering]::after,
[data-ajax-content=form][data-is-rendering]::before,
[data-ajax-content=form][data-is-rendering]::after {
  display: block;
  opacity: 1;
  position: absolute;
}
[data-ajax-content-custom][data-is-rendering]::before,
[data-ajax-content=form][data-is-rendering]::before {
  font-family: "Font Awesome 6 Pro";
  font-size: 40px;
  content: "\f110";
  left: 50%;
  top: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
[data-ajax-content-custom][data-is-rendering] > *,
[data-ajax-content=form][data-is-rendering] > * {
  opacity: 0.5;
}

[data-lse][data-is-loading] {
  pointer-events: none;
  position: relative;
}
[data-lse][data-is-loading]::before, [data-lse][data-is-loading]::after {
  display: block;
  opacity: 1;
  position: absolute;
}
[data-lse][data-is-loading]::before {
  font-family: "Font Awesome 6 Pro";
  font-size: 20px;
  content: "\f110";
  left: 50%;
  top: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
  animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  -webkit-animation: spin 2s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
[data-lse][data-is-loading] > * {
  opacity: 0.1;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.page:focus-visible {
  outline: none;
}
.page-header {
  padding-left: var(--wp-page-offset);
  padding-right: var(--wp-page-offset);
}
.page-header:focus-visible {
  outline: none;
}
.page-main {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  scroll-margin-top: var(--global-scroll-top-offset);
}
.page-main:focus-visible {
  outline: none;
}
.page-main > .section {
  scroll-margin-top: var(--global-scroll-top-offset);
}
.page-main > .section:first-of-type {
  border-top: 0 none;
}
.page-main > .section:last-of-type {
  margin-bottom: 0;
}
.page-main > .section:last-of-type:not(.section-colored) {
  margin-bottom: calc(var(--wp-spacing-l) * 3);
}
.page-main > .section:first-of-type:last-of-type {
  margin-bottom: calc(var(--wp-spacing-l) * 3);
  margin-top: calc(var(--wp-spacing-l) * 3);
}
.page-footer {
  padding-left: var(--wp-page-offset);
  padding-right: var(--wp-page-offset);
}
.page-footer:focus-visible {
  outline: none;
}
.page-interactive-alerts, .page-system-alerts {
  display: flex;
  flex-direction: column;
  gap: var(--wp-spacing-s);
  position: fixed;
  z-index: 9999;
}
.page-interactive-alerts {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.page-system-alerts {
  position: fixed;
  right: var(--wp-spacing-s);
  top: calc(var(--wp-spacing-s) + 130px);
}
.page-overlay {
  background: var(--wp-color-black);
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out;
  width: 100vw;
  z-index: -1;
}

.page-main.-overlay-shown .page-overlay {
  opacity: 0.4;
  z-index: 5;
}

.section-hero {
  --wp-section-background-color: var(--wp-color-primary);
  --wp-section-margin: 0 0 var(--wp-spacing-xl) 0;
  --wp-section-padding: var(--wp-spacing-xl) 0;
  border-radius: var(--wp-section-border-radius);
  overflow: hidden;
  padding: 0 !important;
}
@media (min-width: 1400px) {
  .section-hero {
    --wp-section-margin: 0 0 calc(var(--wp-spacing) * 5) 0;
    --wp-section-padding: calc(var(--wp-spacing) * 7) 0;
  }
}
.section-hero .section-body {
  padding: var(--wp-section-padding);
}
.section-hero .section-image + .section-body {
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
}

.section-breadcrumb {
  --wp-section-padding: 0;
  margin-top: 0 !important;
  position: absolute !important;
  width: 100%;
  z-index: 1;
}
.section-breadcrumb .section-body__inner {
  display: flex;
  justify-content: flex-end;
}

.section.section-colored {
  --wp-section-padding: calc(var(--wp-section-gap) * 1.5) 0;
  background: transparent !important;
  color: var(--wp-section-color);
  position: relative;
}
@media (min-width: 1200px) {
  .section.section-colored {
    --wp-section-padding: calc(var(--wp-section-gap) * 2) 0;
  }
}
@media (min-width: 1400px) {
  .section.section-colored {
    --wp-section-padding: calc(var(--wp-section-gap) * 2.5) 0;
  }
}
.section.section-colored h1, .section.section-colored .h1, .section.section-colored h2, .section.section-colored .h2, .section.section-colored h3, .section.section-colored .h3, .section.section-colored h4, .section.section-colored .h4, .section.section-colored h5, .section.section-colored .h5, .section.section-colored h6, .section.section-colored .h6 {
  --wp-heading-color: var(--wp-section-heading-color, var(--wp-section-color));
}
.section.section-colored::before {
  background: var(--wp-section-background-color);
  border-radius: var(--wp-section-border-radius);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.section.section-colored.-grey {
  --wp-section-background-color: var(--wp-color-grey-lighten);
  --wp-section-heading-color: var(--wp-color-black);
}
.section.section-colored.-primary {
  --wp-section-background-color: var(--wp-color-primary-lighten);
  --wp-section-color: var(--wp-color-black);
}
.section.section-colored.-secondary {
  --wp-section-background-color: var(--wp-color-secondary-lighten);
  --wp-section-color: var(--wp-color-black);
}

.section-contentbelow {
  --wp-section-contentbelow-media-width: 100%;
  --wp-section-contentbelow-text-padding-x: calc(var(--wp-spacing) + var(--wp-spacing-xs));
  --wp-section-contentbelow-text-padding-y: var(--wp-spacing-xl);
  --wp-section-contentbelow-text-width: 100%;
  --wp-section-contentbelow-text-inner-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 992px) {
  .section-contentbelow {
    --wp-section-contentbelow-text-padding-x: calc(var(--wp-spacing-l) + var(--wp-spacing-s));
    --wp-section-contentbelow-text-padding-y: var(--wp-spacing-xl);
  }
}
@media (min-width: 1600px) {
  .section-contentbelow {
    --wp-section-contentbelow-media-width: 60%;
    --wp-section-contentbelow-text-padding-x: calc(var(--wp-spacing) * 5);
    --wp-section-contentbelow-text-padding-y: calc(var(--wp-spacing-xl) * 2);
    --wp-section-contentbelow-text-width: 48%;
    --wp-section-contentbelow-text-inner-width: 654px;
  }
}
.section-contentbelow .text-block {
  --ifabsh-text-block-gap: 0;
}
.section-contentbelow .text-block__inner {
  padding-bottom: calc(var(--wp-spacing) * 3);
}
.section-contentbelow .text-block-media {
  max-width: var(--wp-section-contentbelow-media-width);
  order: 1;
  padding: var(--wp-section-padding, var(--wp-section-gap));
}
@media (min-width: 1600px) {
  .section-contentbelow .text-block-media {
    order: 2;
    padding: 0;
  }
}
.section-contentbelow .text-block-text {
  background: var(--wp-color-primary);
  border-radius: 79px 0 0 0;
  color: var(--wp-color-secondary);
  margin-top: calc(var(--wp-spacing-l) * -1);
  order: 2;
  padding: var(--wp-section-contentbelow-text-padding-y) var(--wp-section-contentbelow-text-padding-x);
  width: var(--wp-section-contentbelow-text-width);
  z-index: 1;
}
@media (min-width: 992px) {
  .section-contentbelow .text-block-text {
    margin-top: calc(var(--wp-spacing) * -5);
  }
}
@media (min-width: 1600px) {
  .section-contentbelow .text-block-text {
    border-radius: var(--wp-section-border-radius);
    bottom: 0;
    margin-top: 0;
    order: 1;
    position: absolute;
    right: 0;
  }
}
.section-contentbelow .text-block-text > * {
  max-width: var(--wp-section-contentbelow-text-inner-width);
}

[data-scroll-page-top=global] { /* stylelint-disable-line string-quotes */
  --ifabsh-icon-button-background-color: var(--wp-color-primary);
  --ifabsh-icon-button-color: var(--wp-color-white);
  bottom: var(--wp-spacing-s);
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: var(--wp-spacing-s);
  transition-duration: calc(var(--global-duration-multiplier, 1) * var(--wp-transition-close));
  transition-timing-function: ease-in;
  z-index: var(--wp-zindex-scroll-top);
}
[data-scroll-page-top=global].-is-shown {
  opacity: 1;
  pointer-events: all;
  transition-duration: calc(var(--global-duration-multiplier, 1) * var(--wp-transition-open));
  transition-timing-function: ease-out;
}
.page-footer-in-view [data-scroll-page-top=global] {
  --ifabsh-icon-button-color: var(--wp-color-primary);
  --ifabsh-icon-button-background-color: var(--wp-color-white);
}

.main-nav .nav-list {
  flex-direction: column;
}
.main-nav .nav-list-item.-back {
  display: none;
}

.page-menu.drawer {
  --ifabsh-drawer-padding: var(--wp-spacing-l);
  --ifabsh-drawer-z-index: var(--wp-zindex-page-menu);
}
.page-menu .nav.main-nav {
  --ifabsh-nav-font-size: var(--wp-nav-firstlevel-font-size);
  --ifabsh-nav-font-weight: var(--wp-font-weight-semibold);
  --ifabsh-nav-gap: var(--wp-nav-firstlevel-gap);
  --ifabsh-nav-link-color: var(--wp-color-secondary);
  --ifabsh-nav-link-color-active: var(--wp-color-secondary);
  --ifabsh-nav-link-color-hover: var(--wp-color-secondary);
  --ifabsh-nav-link-padding-x: 0;
  --ifabsh-nav-link-padding-y: 0;
  --ifabsh-nav-list-background-color: var(--wp-drawer-body-background-color);
  --wp-nav-firstlevel-font-size: 22px;
  --wp-nav-firstlevel-gap: var(--wp-spacing);
  --wp-nav-sublevel-font-size: 17px;
  --wp-nav-sublevel-gap: var(--wp-spacing);
}
@media (min-width: 768px) {
  .page-menu .nav.main-nav {
    --wp-nav-firstlevel-font-size: 28px;
    --wp-nav-firstlevel-gap: var(--wp-spacing-l);
    --wp-nav-sublevel-font-size: 22px;
    --wp-nav-sublevel-gap: calc(var(--wp-spacing) + var(--wp-spacing-xs));
  }
}
.page-menu .nav.main-nav > .nav-list {
  --wp-nav-link-color: var(--wp-color-secondary);
  --wp-nav-link-font-size: var(--wp-nav-firstlevel-font-size);
  position: relative;
  z-index: 1 !important;
}
.page-menu .nav.main-nav > .nav-list .nav-list {
  --ifabsh-nav-list-gap-y: var(--wp-nav-sublevel-gap);
  --ifabsh-nav-font-weight: var(--wp-body-font-weight);
  --wp-nav-link-color: var(--wp-color-text);
  --wp-nav-link-font-size: var(--wp-nav-sublevel-font-size);
}
.page-menu .nav.main-nav .nav-list {
  flex-direction: column;
  transition-duration: 0.5s;
  width: 100%;
}
.page-menu .nav.main-nav .nav-list .nav-item {
  line-height: 1.2;
  position: unset;
}
.page-menu .nav.main-nav .nav-list .nav-item.-has-nav > .nav-list {
  transform: translate(150%, 0);
}
.page-menu .nav.main-nav .nav-list .nav-item.-has-nav > .nav-link {
  display: flex;
}
.page-menu .nav.main-nav .nav-list .nav-item.-has-nav > .nav-link > i {
  margin-left: auto;
  margin-right: 0;
}
.page-menu .nav.main-nav .nav-list .nav-item._opened > .nav-list {
  opacity: 1 !important;
  pointer-events: auto !important;
  transform: translate(0, 0);
  visibility: visible !important;
  z-index: 1 !important;
}
.page-menu .nav.main-nav .nav-list .nav-link {
  --wp-link-font-weight: var(--wp-font-weight-normal);
  display: inline-flex;
  justify-content: space-between;
  letter-spacing: 1px;
  transition: opacity 0.25s ease-in-out;
  opacity: 1;
  width: 100%;
}
.page-menu .nav.main-nav .nav-list .nav-list {
  --wp-nav-text-transform: uppercase;
  --wp-nav-font-family: var(--wp-font-family-sans);
  display: flex;
  position: absolute;
  top: 0;
  z-index: 1;
}
@media (min-width: 992px) {
  .page-menu .nav.main-nav .nav-list .nav-list {
    --ifabsh-nav-gap: calc(var(--wp-spacing) + var(--wp-spacing-s));
  }
}
.page-menu .nav.main-nav .nav-list .nav-list .nav-item {
  line-height: normal;
}
.page-menu .nav.main-nav .nav-list .nav-list .nav-item.-back {
  display: block;
  font-size: var(--wp-nav-firstlevel-font-size);
  font-weight: var(--wp-font-weight-semibold);
  text-transform: none;
}
.page-menu .nav.main-nav .nav-list .nav-list .nav-item.-back > .nav-link {
  --wp-nav-link-back-margin: 0 0 0 0;
  --wp-nav-link-back-padding: 0 0 0 0;
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: start;
  margin: var(--wp-nav-link-back-margin);
  padding: var(--wp-nav-link-back-padding);
  width: auto;
}
.page-menu .nav.main-nav .nav-list._invis {
  z-index: -1;
}
.page-menu .nav.main-nav .nav-list._invis > .nav-item > .nav-link {
  opacity: 0 !important;
  transition: all 0.5s ease-in-out;
  z-index: -1;
}
.page-menu .nav.main-nav .nav-item:not(.-back) {
  padding-left: 34px;
}
@media (min-width: 768px) {
  .page-menu .nav.main-nav .nav-item:not(.-back) {
    padding-left: 40px;
  }
}
.page-menu .nav.main-nav .nav-item:not(.-back) > .nav-link {
  color: var(--wp-nav-link-color) !important;
  font-size: var(--wp-nav-link-font-size);
}

.page-header button[aria-controls=page-menu-drawer] { /* stylelint-disable-line string-quotes */
  --ifabsh-icon-button-color: var(--wp-color-secondary);
  --ifabsh-icon-button-color-hover: currentColor;
  --dnm-pmd-calc-duration: calc(var(--global-duration-multiplier, 1) * var(--wp-transition));
  font-size: var(--wp-font-size-l);
}
.page-header button[aria-controls=page-menu-drawer] .icon-link__icon {
  position: relative;
}
.page-header button[aria-controls=page-menu-drawer] [data-off],
.page-header button[aria-controls=page-menu-drawer] [data-on] {
  left: 50%;
  position: absolute;
  top: 50%;
  transform-origin: center;
  transition: all var(--dnm-pmd-calc-duration);
}
.page-header button[aria-controls=page-menu-drawer] [data-off] {
  opacity: 1;
  transform: translate3d(-50%, -50%, 0) scale(1);
  transition: all var(--dnm-pmd-calc-duration) ease-in-out calc(var(--dnm-pmd-calc-duration) / 2);
}
.page-header button[aria-controls=page-menu-drawer] [data-on] {
  opacity: 0;
  transform: translate3d(-50%, -50%, 0) scale(0.5);
  transition: all var(--dnm-pmd-calc-duration);
}
.page-header button[aria-controls=page-menu-drawer].-active [data-on] {
  opacity: 1;
  transform: translate3d(-50%, -50%, 0) scale(1);
  transition: all var(--dnm-pmd-calc-duration) ease-in-out calc(var(--dnm-pmd-calc-duration) / 2);
}
.page-header button[aria-controls=page-menu-drawer].-active [data-off] {
  opacity: 0;
  transform: translate3d(-50%, -50%, 0) scale(0.5);
  transition: all var(--dnm-pmd-calc-duration);
}

.page-header {
  --wp-page-footer-background-color: var(--wp-color-primary-lighten);
  --wp-page-footer-border-color: var(--wp-color-primary);
  --wp-page-footer-border-width: 3px;
  --wp-page-footer-padding-x: calc(var(--wp-spacing-s) * 5);
  --wp-page-footer-padding-y: calc(var(--wp-spacing-s) * 3);
  border-bottom: var(--wp-page-footer-border-width) solid var(--wp-page-footer-border-color);
  height: auto;
  margin: 0;
  padding-bottom: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0;
  position: sticky;
  top: 0;
  z-index: 501;
}

.page-header__inner {
  background: var(--wp-page-footer-background-color);
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--wp-page-footer-padding-y) var(--wp-page-footer-padding-x);
}
.page-header__inner .page-logo {
  --wp-logo-height: 80px;
}
.page-header__inner .controls {
  align-items: center;
  display: flex;
  gap: var(--wp-spacing-s);
}
@media (min-width: 768px) {
  .page-header__inner .controls {
    gap: var(--wp-spacing);
  }
}
.page-header__inner .controls > *:last-child {
  margin-left: var(--wp-spacing);
}
@media (min-width: 768px) {
  .page-header__inner .controls > *:last-child {
    margin-left: var(--wp-spacing-l);
  }
}
.page-header__inner .controls [data-nav=basket] .button__label > *:not([data-basket-amount]) {
  display: none;
}
@media (min-width: 768px) {
  .page-header__inner .controls [data-nav=basket] .button__label > *:not([data-basket-amount]) {
    display: inline-flex;
  }
}
.page-header__inner .controls [data-nav=basket] [data-basket-amount]::before, .page-header__inner .controls [data-nav=basket] [data-basket-amount]::after {
  display: inline-block;
}
.page-header__inner .controls [data-nav=basket] [data-basket-amount]::before {
  content: "(";
}
.page-header__inner .controls [data-nav=basket] [data-basket-amount]::after {
  content: ")";
}

html.page-header-is-sticky {
  --wp-page-header-height: 3rem;
  --wp-page-header-logo-height: 1.5rem;
}
@media (min-width: 992px) {
  html.page-header-is-sticky {
    --wp-page-header-height: 4rem;
    --wp-page-header-logo-height: 1.75rem;
  }
}
html.page-header-is-sticky .page-header {
  position: sticky;
  top: 0;
}

.page-footer {
  --wp-page-footer-background-color: hsla(var(--wp-color-primary-h), var(--wp-color-primary-s), var(--wp-color-primary-l), 0.1);
  --wp-page-footer-border-color: var(--wp-color-primary);
  --wp-page-footer-border-width: 3px;
  --wp-page-footer-color: var(--wp-color-text);
  --wp-page-footer-padding: calc(var(--wp-spacing) * 3) 0;
  background: var(--wp-page-footer-background-color);
  border-top: var(--wp-page-footer-border-width) solid var(--wp-page-footer-border-color);
  color: var(--wp-page-footer-color);
  margin: var(--wp-spacing-l) 0 0 0;
  padding: var(--wp-page-footer-padding);
}
@media (min-width: 992px) {
  .page-footer {
    margin: 0;
  }
}
.page-footer__inner {
  --wp-page-max-width-padding: var(--wp-spacing);
  --wp-page-max-width-absolute: calc(var(--wp-page-max-width) + (var(--wp-page-max-width-padding) * 2));
  margin-left: auto;
  margin-right: auto;
  max-width: var(--wp-page-max-width-absolute);
  padding-left: var(--wp-page-max-width-padding);
  padding-right: var(--wp-page-max-width-padding);
  width: 100%;
  display: flex;
  gap: var(--wp-spacing-l);
  justify-content: space-between;
}
@media (min-width: calc(1400px + 64px)) {
  .page-footer__inner {
    --wp-page-max-width-padding: var(--wp-spacing-l);
  }
}
.page-footer__inner {
  align-items: center;
  flex-direction: column;
}
@media (min-width: 992px) {
  .page-footer__inner {
    flex-direction: row;
  }
}
.page-footer__inner .nav {
  --ifabsh-nav-link-color: var(--wp-page-footer-top-color);
  --ifabsh-nav-link-color-hover: var(--wp-page-footer-top-color);
  --ifabsh-nav-font-size: var(--wp-font-size-s);
  --ifabsh-nav-font-weight: var(--wp-font-weight-bold);
}
.page-footer__inner .nav-list {
  flex-direction: column;
  text-align: center;
}
@media (min-width: 992px) {
  .page-footer__inner .nav-list {
    flex-direction: row;
    text-align: left;
  }
}
.page-footer__inner .nav-link {
  padding: 0;
}
.page-footer__inner .meta-nav {
  --ifabsh-nav-link-background-color: transparent;
  --ifabsh-nav-link-background-color-hover: transparent;
  --ifabsh-nav-link-background-color-active: transparent;
  --ifabsh-nav-link-color: inherit;
  --ifabsh-nav-link-color-hover: inherit;
  --ifabsh-nav-link-color-active: inherit;
  --ifabsh-nav-link-padding: 0;
  --ifabsh-nav-list-gap-x: var(--wp-spacing-l);
  --ifabsh-nav-gap: var(--wp-spacing);
  order: 1;
}
@media (min-width: 992px) {
  .page-footer__inner .meta-nav {
    order: 2;
  }
}
.page-footer__inner .meta-nav + .social-nav {
  margin: calc(var(--wp-spacing-s) * 3) 0 0 0;
}
@media (min-width: 992px) {
  .page-footer__inner .meta-nav + .social-nav {
    margin: 0 0 0 calc(var(--wp-spacing) * 3);
  }
}
.page-footer__inner .social-nav {
  --ifabsh-nav-list-gap-x: calc(var(--wp-spacing-s) * 3);
  order: 2;
}
.page-footer__inner .social-nav .nav-list {
  flex-direction: row !important;
}
@media (min-width: 992px) {
  .page-footer__inner .social-nav {
    order: 3;
  }
}

.section {
  --wp-section-gap: var(--wp-page-offset);
  --wp-section-margin-x: 0;
  --wp-section-margin-y: calc(var(--wp-spacing-l) * 1.5);
  --wp-section-margin: var(--wp-section-margin-y) var(--wp-section-margin-x);
  --wp-section-padding: 0 ;
  --_sect-duration: calc(var(--global-duration-multiplier, 1) * var(--wp-transition-complex));
  background-color: var(--wp-section-background-color, transparent);
  flex-wrap: wrap;
  margin: var(--wp-section-margin, 0);
  overflow-anchor: auto;
  padding: var(--wp-section-padding, var(--wp-section-gap));
  position: relative;
}
.section:not(.section-contentbelow) .section-header,
.section:not(.section-contentbelow) .section-body,
.section:not(.section-contentbelow) .section-footer > .section-footer__inner {
  --wp-page-max-width-padding: var(--wp-spacing);
  --wp-page-max-width-absolute: calc(var(--wp-page-max-width) + (var(--wp-page-max-width-padding) * 2));
  margin-left: auto;
  margin-right: auto;
  max-width: var(--wp-page-max-width-absolute);
  padding-left: var(--wp-page-max-width-padding);
  padding-right: var(--wp-page-max-width-padding);
  width: 100%;
}
@media (min-width: calc(1400px + 64px)) {
  .section:not(.section-contentbelow) .section-header,
  .section:not(.section-contentbelow) .section-body,
  .section:not(.section-contentbelow) .section-footer > .section-footer__inner {
    --wp-page-max-width-padding: var(--wp-spacing-l);
  }
}
.section-header {
  color: hsl(var(--wp-color-black-h), var(--wp-color-black-s), 55%);
  margin-bottom: var(--wp-section-gap);
}
.section-header > .title {
  --wp-heading-margin: var(--wp-spacing-xs);
  --wp-heading-color: var(--wp-color-primary);
}
.section-header > .subtext {
  font-style: italic;
}
.section-header > :last-child {
  margin-bottom: 0;
}
.section-body + .section-footer {
  margin-top: calc(var(--wp-section-gap) / 2);
  text-align: center;
}
.section-body__inner > * {
  margin-bottom: 0;
  margin-top: 0;
}
.section-body__inner > * + * {
  margin-top: var(--wp-content-element-margin);
}

.section-columns {
  display: flex;
  flex-wrap: wrap;
  gap: var(--wp-section-columns-gap, var(--wp-spacing));
}
.section-columns__column {
  flex-basis: var(--_sccl-width, 100%);
  width: var(--_sccl-width, 100%);
}
.section-columns__column > * {
  margin-bottom: 0;
  margin-top: 0;
}
.section-columns__column > * + * {
  margin-top: var(--wp-content-element-margin);
}
@media (min-width: 768px) {
  .section-columns {
    --wp-section-columns-gap: var(--wp-spacing-l);
    flex-wrap: nowrap;
  }
  .section-columns[data-columns="2"] .section-columns__column:not(:first-child:last-child) { /* stylelint-disable-line string-quotes */
    --_sccl-width: calc(50% - var(--wp-section-columns-gap) / 2);
  }
}
@media (min-width: 992px) {
  .section-columns {
    --wp-section-columns-gap: var(--wp-spacing-xl);
  }
}

.content > * {
  margin-bottom: 0;
  margin-top: 0;
}
.content > *:not(:first-child) {
  margin-top: var(--wp-block-element-margin);
}
.content > section:not(:first-child),
.content > .ce:not(:first-child) {
  margin-top: var(--wp-content-element-margin);
}

.sidebar {
  --wp-sidebar-gap: var(--wp-block-element-margin);
}
.sidebar:empty {
  display: none;
}
.sidebar-item {
  background-color: var(--wp-color-black-lighten);
  padding: var(--wp-sidebar-item-padding, var(--wp-spacing));
}
@media (min-width: 992px) {
  .sidebar-item {
    --wp-sidebar-item-padding: var(--wp-spacing-l);
  }
}
.sidebar-item > * {
  margin: 0;
}
.sidebar-item + .sidebar-item {
  margin-top: var(--wp-sidebar-gap);
}

.section-sidebar {
  --wp-section-sidebar-gap: var(--wp-spacing);
  --wp-section-sidebar-sidebar-width: 33%;
  display: flex;
  flex-wrap: wrap;
  gap: var(--wp-section-sidebar-gap);
}
.section-sidebar > .content {
  flex-basis: 100%;
  flex-grow: 666;
  min-width: 100%;
  order: 1;
}
.section-sidebar > .sidebar {
  flex-basis: 100%;
  flex-grow: 1;
  min-width: 0;
  order: 2;
  width: 100%;
}
@media (min-width: 768px) {
  .section-sidebar {
    --wp-section-sidebar-gap: var(--wp-spacing-l);
    --_section-sidebar-width: calc(var(--wp-section-sidebar-sidebar-width) + var(--wp-section-sidebar-gap));
    align-items: flex-start;
    flex-wrap: nowrap;
  }
  .section-sidebar > .content,
  .section-sidebar > .sidebar {
    position: sticky;
    top: var(--global-scroll-top-offset);
  }
  .section-sidebar > .content {
    flex-basis: calc(100% - var(--_section-sidebar-width));
    min-width: calc(100% - var(--_section-sidebar-width));
  }
  .section-sidebar > .sidebar {
    flex-basis: var(--_section-sidebar-width);
    width: var(--_section-sidebar-width);
  }
}
@media (min-width: 992px) {
  .section-sidebar {
    --wp-section-sidebar-gap: var(--wp-spacing-xl);
  }
}

.page-jumplist {
  --ifabsh-jumplist-background-color: hsl(var(--wp-color-black-h), var(--wp-color-black-s), 97%);
  --ifabsh-jumplist-padding-x: var(--wp-page-offset);
  --ifabsh-jumplist-link-background-color-hover: hsl(var(--wp-color-black-h), var(--wp-color-black-s), 95%);
  --ifabsh-jumplist-link-background-color-active: transparent;
  --ifabsh-jumplist-link-color-hover: var(--wp-body-color);
  --ifabsh-jumplist-link-color-active: var(--wp-color-primary);
  --ifabsh-jumplist-link-padding-x: var(--wp-spacing-s);
  --ifabsh-jumplist-link-padding-y: 0;
  border-bottom: 1px solid hsl(var(--wp-color-black-h), var(--wp-color-black-s), 90%);
  height: 2.5rem;
  overflow-x: auto;
  position: sticky;
  top: var(--global-scroll-top-offset, 0);
  z-index: calc(var(--wp-zindex-page-footer) - 1);
}
.page-jumplist .jumplist__container {
  height: 100%;
  text-align: center;
}
.page-jumplist .jumplist-menu {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
}
.page-jumplist .jumplist-menu__link {
  display: inline-flex;
}
.page-jumplist .jumplist-menu__link > * {
  align-self: center;
}
.page-jumplist:not(:empty) {
  --wp-scrollbar-track-color: transparent;
  --wp-scrollbar-size: 1px;
}
.page-jumplist:not(:empty)::-webkit-scrollbar {
  height: var(--wp-scrollbar-size, 4px);
  width: var(--wp-scrollbar-size, 4px);
}
.page-jumplist:not(:empty)::-webkit-scrollbar-thumb {
  background: var(--wp-scrollbar-color, #000);
}
.page-jumplist:not(:empty)::-webkit-scrollbar-track {
  background: var(--wp-scrollbar-track-color, transparent);
}
.page-jumplist:not(:empty) {
  scrollbar-face-color: var(--wp-scrollbar-color, #000);
  scrollbar-track-color: var(--wp-scrollbar-track-color, transparent);
}
.page-jumplist:not(:empty) + .section {
  border-top: 0 none;
}
@media (min-width: 992px) {
  .page-jumplist {
    --ifabsh-jumplist-menu-gap: var(--wp-spacing-s);
    height: 3.5rem;
  }
}

.page-main > .breadcrumb {
  --ifabsh-breadcrumb-color: hsl(var(--wp-color-black-h), var(--wp-color-black-s), 60%);
  --ifabsh-breadcrumb-color-hover: var(--wp-color-black);
  --ifabsh-breadcrumb-font-size: var(--wp-font-size-xxs);
  --ifabsh-breadcrumb-gap-x: 1em;
  --ifabsh-breadcrumb-padding-x: var(--wp-page-offset);
  --ifabsh-breadcrumb-padding-y: var(--wp-spacing-s);
}
@media (max-width: 1199px) {
  .page-main > .breadcrumb {
    display: none;
  }
}

.page-search {
  --_pase-duration: calc(var(--global-duration-multiplier, 1) * var(--wp-transition-expand));
  background-color: var(--wp-color-secondary);
  display: block;
  padding: 0;
  pointer-events: none;
  position: fixed;
  top: var(--global-scroll-top-offset);
  transform: translateY(-100%);
  transition-duration: var(--_pase-duration);
  transition-property: transform;
  transition-timing-function: ease-in;
  width: 100%;
  z-index: var(--wp-zindex-page-search);
}
.page-search .search-box {
  margin: 0 auto;
  width: 100%;
}
.page-search .search-box__form {
  --ifabsh-input-height: 3rem;
}
.page-search .search-box__input {
  --_accent-color: hsl(var(--wp-color-white-h), 85%, 0%);
  --ifabsh-form-control-background-color: transparent;
  --ifabsh-form-control-background-color-hover: var(--_accent-color);
  --ifabsh-form-control-background-color-focus: var(--_accent-color);
  --ifabsh-form-control-border-color: transparent;
  --ifabsh-form-control-border-color-hover: transparent;
  --ifabsh-form-control-border-color-focus: transparent;
  --ifabsh-form-control-color: var(--wp-color-white);
  --ifabsh-form-control-color-hover: var(--wp-color-white);
  --ifabsh-form-control-color-focus: var(--wp-color-white);
  --ifabsh-form-control-placeholder-color: hsl(var(--wp-color-white-h), var(--wp-color-white-s), 35%);
  --ifabsh-form-control-padding-color: var(--wp-page-offset);
  text-align: center;
}
.page-search .search-box__submit {
  display: none;
}
.page-search .search-box .form-control-clearable {
  --ifabsh-clearable-color: var(--wp-color-white);
}
.page-search .was-validated .search-box__input:invalid {
  --_foco-border-color: transparent;
}
.-page-search-open .page-search {
  pointer-events: all;
  transform: translateY(0%);
  transition-timing-function: ease-out;
}

.page-logo {
  display: inline-block;
}
.page-logo > * {
  height: var(--wp-logo-height, var(--page-logo-height), 1em);
  width: var(--wp-logo-width, var(--page-logo-width), auto);
}

.section[data-module-steps] {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  margin-bottom: 0 !important;
}
.section[data-module-steps] .section-body {
  margin-bottom: var(--wp-spacing-xl);
}
.section[data-module-steps] .section-footer {
  background: var(--wp-color-primary-lighten);
  bottom: 0;
  margin-top: auto;
  padding-bottom: var(--wp-spacing-l);
  padding-top: var(--wp-spacing-l);
  position: sticky;
}
.section[data-module-steps] .section-footer .module__footer {
  display: flex;
  justify-content: space-between;
}
.section[data-module-steps] .section-footer .module__footer .form {
  width: auto;
}

[data-cart=edit-address] .form-check-group.-inline {
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  [data-cart=edit-address] .form-check-group.-inline {
    grid-template-columns: 1fr 1fr;
  }
}
[data-cart=edit-address] .form-check-group > .form-check {
  align-items: baseline;
  background: var(--wp-color-primary-lighten);
  border-radius: var(--wp-spacing-s);
  padding: var(--wp-spacing-s);
}
[data-cart=edit-address] .form-check-group > .form-check > .form-check-input {
  position: relative;
  top: 5px;
}
[data-cart=edit-address] .form-check-group > .form-check > .icon-link {
  margin-left: auto;
}

.events-group {
  scroll-margin-top: var(--global-scroll-top-offset);
}
.events-group > * + * {
  margin-top: var(--wp-block-element-margin);
}
.events-group .event-stack[data-endless=end-reached] ~ [data-endless-trigger] {
  display: none !important;
}
.events-group .card {
  --ifabsh-card-media-aspect-ratio: 16 / 9;
  --focus-outline-color: var(--wp-color-black);
}
.events-group .card__header > *:last-child {
  margin-left: auto;
}
.events-group .card__footer > *:last-child {
  margin-left: auto;
}
.events-group .card .time__time {
  display: none;
}
.events-group .card:focus-within {
  outline: var(--wp-focus-outline-width) var(--wp-focus-outline-style) var(--focus-outline-color, var(--wp-focus-outline-color));
  outline-offset: var(--wp-focus-outline-offset);
}
.events-group .card:focus-within a {
  box-shadow: none;
  outline: none;
}

.news-group {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.news-group .deck[data-columns="1"] .card, .news-group .deck[data-columns="2"] .card {
  --ifabsh-card-padding: calc(var(--wp-spacing-s) * 3) calc(var(--wp-spacing) * 3);
}
.news-group .deck[data-columns="3"] .card {
  --ifabsh-card-padding: var(--wp-spacing-l) calc(var(--wp-spacing) + var(--wp-spacing-xs));
}
.news-group .pagination {
  flex: max-content;
}

.event {
  --wp-heading-color: var(--wp-color-text);
}
.event-control__item-link {
  display: inline-flex;
}
.event-control__item-link[data-control|=remove] {
  display: none;
}
.event-control__item-link[data-control=add-waitinglist] {
  display: none;
}
.event-control__item-link[data-control=add-basket] {
  display: inline-flex;
}
.event-control__item-link[data-control=remove-basket] {
  display: none;
}
.event-control.-is-loading .event-control__item {
  opacity: 0.1;
}
.event[data-is-basket] .event-control [data-control=add-basket] {
  display: none;
}
.event[data-is-basket] .event-control [data-control=remove-basket] {
  display: inline-flex;
}
.event[data-is-waiting] .event__badge {
  background-color: var(--wp-color-warning);
}
.event[data-is-waiting] .event-control [data-control$=basket] {
  display: none;
}
.event[data-is-waiting] .event-control [data-control=add-waitinglist] {
  display: inline-flex;
}
.event[data-is-waiting] .event-control [data-control=remove-waitinglist] {
  display: none;
}
.event[data-is-waiting][data-is-basket] .event-control [data-control=add-waitinglist] {
  display: none;
}
.event[data-is-waiting][data-is-basket] .event-control [data-control=remove-waitinglist] {
  display: inline-flex;
}
.event[data-is-fullybooked] .event__badge {
  background-color: var(--wp-color-danger);
}
.event[data-is-fullybooked] .event-control [data-control]:not([data-control=detail]) {
  display: none;
}
.event[data-is-started] [data-event-hint]::before, .event[data-is-started] [data-event-hint]::after, .event[data-is-inline-flexed] [data-event-hint]::before, .event[data-is-inline-flexed] [data-event-hint]::after, .event[data-is-canceled] [data-event-hint]::before, .event[data-is-canceled] [data-event-hint]::after {
  display: inline-flex;
}
.event[data-is-started] .event-control [data-control]:not([data-control=detail]), .event[data-is-inline-flexed] .event-control [data-control]:not([data-control=detail]), .event[data-is-canceled] .event-control [data-control]:not([data-control=detail]) {
  display: none;
}
.event[data-is-canceled] .card__header .badge.-free, .event[data-is-canceled] .card__header .badge.-waitlist {
  display: none;
}
.event[data-is-canceled] .card__footer {
  position: relative;
}
.event[data-is-canceled] .card__footer .button[data-control=add-basket],
.event[data-is-canceled] .card__footer .button[data-control=add-waitlist],
.event[data-is-canceled] .card__footer .button[data-drawer-login] {
  --ifabsh-button-background-color: var(--wp-color-grey-light);
  --ifabsh-button-border-color: var(--wp-color-grey-light);
  cursor: not-allowed;
  opacity: 1;
  pointer-events: none;
}
.event[data-is-canceled] .card__footer .button[data-control=add-basket] .button__prefix,
.event[data-is-canceled] .card__footer .button[data-control=add-basket] .button__suffix,
.event[data-is-canceled] .card__footer .button[data-control=add-waitlist] .button__prefix,
.event[data-is-canceled] .card__footer .button[data-control=add-waitlist] .button__suffix,
.event[data-is-canceled] .card__footer .button[data-drawer-login] .button__prefix,
.event[data-is-canceled] .card__footer .button[data-drawer-login] .button__suffix {
  display: none;
}
.event[data-is-canceled] .card__footer .button[data-control=add-basket] .button__label::before,
.event[data-is-canceled] .card__footer .button[data-control=add-waitlist] .button__label::before,
.event[data-is-canceled] .card__footer .button[data-drawer-login] .button__label::before {
  content: "abgesagt";
}
.event[data-is-canceled] .card__footer .button[data-control=add-basket] .button__label > span,
.event[data-is-canceled] .card__footer .button[data-control=add-waitlist] .button__label > span,
.event[data-is-canceled] .card__footer .button[data-drawer-login] .button__label > span {
  display: none;
}
.event[data-is-booked] .card__footer {
  position: relative;
}
.event[data-is-booked] .card__footer .button[data-control=add-basket],
.event[data-is-booked] .card__footer .button[data-control=add-waitlist] {
  --ifabsh-button-background-color: var(--wp-color-grey-light);
  --ifabsh-button-border-color: var(--wp-color-grey-light);
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
.event[data-is-booked] .card__footer .button[data-control=add-basket] .button__prefix,
.event[data-is-booked] .card__footer .button[data-control=add-basket] .button__suffix,
.event[data-is-booked] .card__footer .button[data-control=add-waitlist] .button__prefix,
.event[data-is-booked] .card__footer .button[data-control=add-waitlist] .button__suffix {
  display: none;
}
.event[data-is-booked] .card__footer .button[data-control=add-basket] .button__label::before,
.event[data-is-booked] .card__footer .button[data-control=add-waitlist] .button__label::before {
  content: "bereits gebucht";
}
.event[data-is-booked] .card__footer .button[data-control=add-basket] .button__label > span,
.event[data-is-booked] .card__footer .button[data-control=add-waitlist] .button__label > span {
  display: none;
}
.event .details {
  --ifabsh-details-border-width: 0;
  --ifabsh-details-background-color: hsla(var(--wp-color-primary-h), var(--wp-color-primary-s), var(--wp-color-primary-l), 0.1);
  --ifabsh-details-toggle-color-active: var(--wp-color-white);
  --wp-details-toggle-background-color: hsla(var(--wp-color-primary-h), var(--wp-color-primary-s), var(--wp-color-primary-l), 0.1);
  --wp-details-toggle-border-width: 0;
}
.event .details__header-summary {
  color: transparent;
}
.event .details__header-summary::before {
  content: "mehr anzeigen";
}
.event .details .details__header[aria-expanded=true] .details__header-summary::before {
  content: "weniger anzeigen";
}
.event .card__body {
  display: flex;
  flex-direction: column;
}
.event .card__body .costs {
  display: flex;
  flex-direction: column;
  gap: var(--wp-spacing-s);
  margin-top: auto;
  padding-top: var(--wp-spacing-l);
  text-align: right;
}
.event .card__body .costs > * {
  margin: 0 !important;
}
.event .card__body .actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: auto;
  padding-top: var(--wp-spacing-l);
}
.event .card__body .actions > *.cancellation:last-child {
  margin-left: auto;
}
.event .card__body dl.list > * {
  line-height: 1.25;
}
.event .card__body dl.list dt {
  font-weight: var(--wp-font-weight-regular);
}
.event .card__footer {
  min-height: 74px;
}
.event .card__footer .link-detail {
  margin-left: 0;
  margin-right: auto;
}

.event-documents {
  display: grid;
  gap: var(--wp-spacing-xl);
  grid-template-columns: 1fr;
}
@media (min-width: 576px) {
  .event-documents {
    grid-template-columns: 1fr 1fr;
    padding: var(--wp-spacing-xl);
  }
}
.event-documents > *:first-child:last-child {
  grid-column: 1/-1;
}
@media (min-width: 576px) {
  .event-documents > *:nth-child(2n) {
    border-left: 2px solid var(--wp-color-primary);
    padding-left: var(--wp-spacing-xl);
  }
}

.event-detail {
  padding: 0 var(--wp-spacing-l);
}
.event-detail .article-body {
  display: grid;
  gap: var(--wp-spacing-xl) var(--wp-spacing-xl);
  grid-auto-columns: 1fr 1fr;
  grid-template-areas: "content content" "informations informations";
}
@media (min-width: 1200px) {
  .event-detail .article-body {
    gap: var(--wp-spacing-xl) calc(var(--wp-spacing-xl) * 2);
    grid-auto-columns: 1fr 1fr;
    grid-template-areas: "content informations";
  }
}
.event-detail .article-body .detail-content {
  grid-area: content;
}
.event-detail .article-body .detail-content .card__header {
  border-bottom: 1px solid var(--wp-color-grey);
}
.event-detail .article-body .detail-content .card__body .list dt {
  font-weight: var(--wp-font-weight-bold);
}
.event-detail .article-body .detail-informations {
  display: flex;
  flex-direction: column;
  grid-area: informations;
}
.event-detail .article-body .detail-informations .infobox.-costs dl.list {
  grid-template-columns: 1fr max-content;
}
.event-detail .article-body .detail-informations .infobox + .infobox {
  margin-top: calc(var(--wp-spacing) * 3);
}
.event-detail .article-body .detail-informations .infobox .card.-primary .card__body {
  background: var(--wp-color-primary-lighten);
}
.event-detail .article-body .detail-informations > div dl.list {
  --wp-dl-gap-y: var(--wp-spacing-s);
}
.event-detail .article-body .detail-informations > div dl.list > * {
  line-height: 1.75;
}
.event-detail .article-body .detail-informations > div dl.list dt {
  font-weight: var(--wp-font-weight-regular);
}
.event-detail .article-body .detail-footer {
  grid-area: footer;
}

.main-modal-iframe-wrapper ~ .sc-cookie {
  display: none !important;
}

.event-search .d-flex {
  gap: var(--wp-spacing-l);
}
.event-search .d-flex > .form-group {
  flex: 1;
}